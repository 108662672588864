import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import {
  loader,
  setMetaInfo,
  moveRequestForm,
} from "../../lib/actions/requestQuote";
import FileUploader from "./../partials/FileUploader";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

class MetaFormTransportation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otherWindscreen: false,
      showPickupDeliveryDetails: false,
      pickupAddress: "",
      dropoffAdress: "",
    };
    this.saveForm = this.saveForm.bind(this);
  }

  saveForm(e) {
    e.preventDefault();
    if ($("#running_order").val() != "Yes") {
      alert("Unfortunately we do not transport non running vehicles");
      return;
    }

    let flatData = {};
    let elements = $("#form").serializeArray();
    for (let i in elements) {
      flatData[elements[i].name] = elements[i].value;
      if (elements[i].name == "pickup_person_email") {
        flatData["pickup_person_address"] = this.state.pickupAddress;
      } else if (elements[i].name == "dropoff_person_email") {
        flatData["dropoff_person_address"] = this.state.dropoffAddress;
      }
    }

    this.props.setMetaInfo(flatData);
    this.props.moveRequestForm();
    return false;
  }

  checkRunningOrder = (e) => {
    if ($("#running_order").val() != "Yes") {
      alert("Unfortunately we do not transport non running vehicles");
    } else {
      this.setState({ showPickupDeliveryDetails: true });
    }
  };

  upload(files) {
    console.log(files);
  }

  handlePickupChange = (address) => {
    this.setState({ pickupAddress: address });
  };

  handlePickupSelect = (address) => {
    geocodeByAddress(address).then((found) => {
      const address = found[0].formatted_address;
      this.setState({ pickupAddress: address });
    });
  };

  handleDropoffChange = (address) => {
    this.setState({ dropoffAdress: address });
  };

  handleDropOffSelect = (address) => {
    geocodeByAddress(address).then((found) => {
      const address = found[0].formatted_address;
      this.setState({ dropoffAdress: address });
    });
  };

  render() {
    const { showPickupDeliveryDetails } = this.state;
    return (
      <div>
        <span style={{ fontWeight: "500" }}> Step 3/4:</span>
        <legend
          style={{
            color: "#fff",
            fontWeight: 400,
            borderBottom: "1px solid #ccc",
            padding: "5px",
          }}
        >
          Vehicle Transportation Details
        </legend>
        <br />
        <form method="POST" id="form" onSubmit={this.saveForm}>
          <div className="form-group">
            <label>
              Is your vehicle in running order to drive onto a truck?
            </label>
            <select
              name="running_order"
              className="form-control"
              id="running_order"
              onChange={this.checkRunningOrder.bind(this)}
            >
              <option value="">--- click to select ---</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="form-group">
            <label>
              Please provide any additional information if applicable
            </label>
            <textarea
              id="description"
              name="description"
              className="form-input"
            ></textarea>
          </div>

          {showPickupDeliveryDetails && (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <h3>Pickup contact information</h3>
                  <div className="form-group">
                    <label>Full name</label>
                    <input
                      type="text"
                      id="pickup_person_full_name"
                      name="pickup_person_full_name"
                      className="form-input"
                    />
                  </div>
                  <div className="form-group">
                    <label>Contact Number</label>
                    <input
                      type="text"
                      id="pickup_contact_number"
                      name="pickup_contact_number"
                      className="form-input"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      id="pickup_person_email"
                      name="pickup_person_email"
                      className="form-input"
                    />
                  </div>
                  <PlacesAutocomplete
                    value={this.state.pickupAddress}
                    onChange={this.handlePickupChange}
                    onSelect={this.handlePickupSelect}
                    searchOptions={{
                      location: new window.google.maps.LatLng(
                        -28.4792625,
                        24.6727135
                      ),
                      radius: 2000,
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter physical address ...",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="col-md-6">
                  <h3>Drop off contact information</h3>
                  <div className="form-group">
                    <label>Full name</label>
                    <input
                      type="text"
                      id="dropoff_person_full_name"
                      name="dropoff_person_full_name"
                      className="form-input"
                    />
                  </div>
                  <div className="form-group">
                    <label>Contact Number</label>
                    <input
                      type="text"
                      id="dropoff_person_number"
                      name="dropoff_person_number"
                      className="form-input"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      id="dropoff_person_email"
                      name="dropoff_person_email"
                      className="form-input"
                    />
                  </div>
                  <PlacesAutocomplete
                    value={this.state.dropoffAdress}
                    onChange={this.handleDropOffSelect}
                    onSelect={this.handleDropOffSelect}
                    searchOptions={{
                      location: new window.google.maps.LatLng(
                        -28.4792625,
                        24.6727135
                      ),
                      radius: 2000,
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter physical address ...",
                            className: "location-search-input",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      If possible, load pictures or a video of the items you
                      need help with
                    </label>
                    <FileUploader />
                  </div>
                  <div className="form-group">
                    <label>
                      Please provide any additional information that will
                      assist?
                    </label>
                    <textarea
                      id="additional_items_or_information"
                      name="additional_items_or_information"
                      className="form-input"
                    ></textarea>
                  </div>

                  <div>
                          <strong>Please note the Terms & Conditions of the vehicle transport service is as follows: <br />
                          </strong>
                          <p>
                          1. All vehicles must be able to be driven on and off the trucks.<br /> 2. It is the duty of the client to ensure that the vehicle is ready to be fetched. Please ensure that vehicles are fully paid for and all documents completed before the driver gets there. <br /> 
                          3. Please do not transport any documentation with the vehicle as we do not take responsibility for them.<br /> 
                          4. Ensure that an inspection sheet is completed with each vehicle prior to loading onto the truck. Keep a copy for your records. <br /> 5. It is the vehicle owners responsibility to ensure that the vehicle is comprehensively insured whilst being transported. <br />6. Please inspect the vehicle fully when fetching it and inform us of any items of concern. We will not be held liable for any items that were not specified at the time of fetching the vehicle.<br /> 7. Once the transport is paid for, the next available spot on the truck will be booked. The lead time may be between 7-10 working days.
                          </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showPickupDeliveryDetails && (
            <div style={{ textAlign: "right" }}>
              <br />
              <button className="btn btn-primary">Next</button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    service: state.requestQuote.service,
    loading: state.requestQuote.loading,
    user: state.requestQuote.user,
    searchedForUser: state.requestQuote.searchedForUser,
    isLoggedIn: state.requestQuote.isLoggedIn,
  };
};
const mapDispatchTopProps = (dispatch) => {
  return {
    setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
    moveRequestForm: () => dispatch(moveRequestForm(3)),
    showLoader: () => dispatch(loader(true)),
    hideLoader: () => dispatch(loader(false)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchTopProps
)(MetaFormTransportation);
