import React from 'react';
import { postDataSupplier } from '../../../lib/api';
import ScaleLoader from "react-spinners/ScaleLoader";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
  } from 'react-places-autocomplete';
import Thumbnail from '../../partials/Thumbnail';
import ImageUploader from "react-images-upload";
  
export default class SupplierProfileDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            loading: false
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        postDataSupplier('userProfile', {})
            .then(res => {
                console.log(res)
                this.setState({ user: res })
                this.setState({ loading: false })
            })
    }

    setProperty(val, name) {
        console.log(val)
        if (val == 'on') {
            val = 1;
        }

        let { user } = this.state;
        user[name] = val;
        this.setState({user})
    }

    setRmiRegistered = e => {
        let user = this.state.user
        user.rmi_registered = e.target.value;
        this.setState({user})
    }

    setWorkManship = e => {
        let user = this.state.user
        user.workmanship_insurance = e.target.value;
        this.setState({user})
    }

    setCodeOfCoduct = e => {
        let user = this.state.user;
        user.code_of_conduct = e.target.value;
        this.setState({user})
    }

    toggleCheckbox(name) {
        let { user } = this.state;
        user[name] = user[name] == 1 || user[name] == "1" ? 0 : 1;
        this.setState({user})
    }

    handleChange = address => {
        let {user} = this.state
        user.location = address
        this.setState({ user });
      };
     
      handleSelect = address => {
        geocodeByAddress(address)
          .then(found => {
            let {user} = this.state
            user.location = found[0].formatted_address;
            this.setState({user})
            getLatLng(found[0]).then(LatLng => {
                user.lat = LatLng.lat;
                user.lng = LatLng.lng;
              this.setState({user} )
            })
          })
      };
    
      finish() {
        const {user} = this.state;
        if (user.code_of_conduct != 'Y') {
            alert('Unfortunately we don\'t accept Suppliers who do not agree to the Supplier code of Conduct');
            return;
        }
        this.setState({loading: true})
        postDataSupplier('updateProfile', {
            name: user.name,
            cellphone: user.cellphone,
            landline: user.landline,
            lat: user.lat,
            lng: user.lng,
            location: user.location,
            trading_name: user.trading_name,
            vat_number: user.vat_number,
            company_registration_number: user.company_registration_number,
            postal_address : user.postal_address,
		    rmi_registered : user.rmi_registered,
			workmanship_insurance : user.workmanship_insurance,
		    bio : user.bio
        })
        .then(res => {
            this.setState({loading: false})
            if (res.hasOwnProperty('error')) {
                window.showMessage(res.error);
                return;
            } else {
                window.showMessage('Thanks for the completed application form. Once verification is done, we will email you the "Approved Supplier Partner" Certificate.', '/dashboard/')
                return;
            }
        })

      }

      onDrop(pictureFiles, pictureDataURLs) {
        this.setState({ loading: true })
        postDataSupplier('userPhoto', {'photo': pictureFiles[0]})
        .then(res => {
            if (res.hasOwnProperty('photo')) {
                let {user} = this.state
                user.photo = res.photo
                this.setState({ loading: false, user })
            }
        })
      }

      onFileUpload = (e, doctype) => { 
     
        // Create an object of formData 
        const formData = new FormData(); 
        // Request made to the backend api 
        // Send formData object
        this.setState({loading: true}) 
        postDataSupplier('supplierDocs', {
            thefile: e.target.files[0],
            document_type: doctype
        })
        .then(res => {
            console.log(res)
            this.setState({loading: false})
        })
      }; 
    
    render() {
        const { user } = this.state;
        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
      `;

        return (

            <div className="bg-white" style={{ padding: "10px" }}>
                <div style={{ textAlign: "center" }}>
                    {<ScaleLoader
                        size={5}
                        override={override}
                        color={"red"}
                        loading={this.state.loading}
                    />}
                </div>

                {this.state.user && <div>
                    <div className="row">
                        <div className="col-md-7">
                    <div className="form-group">
                        <label>Contact Person Name</label>
                        <input className="form-input" type="text" name="name" value={user.name}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'name')
                            }} />
                    </div>

                    <div className="form-group">
                        <label>Contact Number</label>
                        <input className="form-input" type="text" name="landline" value={user.landline}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'landline')
                            }} />
                    </div>

                    <div className="form-group">
                        <label>Alternative Contact Number</label>
                        <input className="form-input" type="text" name="cellphone" value={user.cellphone}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'cellphone')
                            }} />
                    </div>

                    <div className="form-group">
                        <label>Contact Person Email</label>
                        <input style={{backgroundColor: "#ccc"}} className="form-input" type="text" name="email" defaultValue={user.email}
                           readOnly="readonly"/>
                    </div>
   

                    <div className="form-group">
                        <label>Company Name</label>
                        <input className="form-input" type="text" name="trading_name" value={user.trading_name}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'trading_name')
                            }} />
                    </div>
                    <div className="form-group">
                        <label>Business Registration Number</label>
                        <input className="form-input" type="text" name="company_registration_number" value={user.company_registration_number}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'company_registration_number')
                            }} />
                    </div>


                    <div className="form-group">
                        <label>VAT Number</label>
                        <input className="form-input" type="text" name="vat_number" value={user.vat_number}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'vat_number')
                            }} />
                    </div>

                    <div className="form-group">
                        <label>Physical address</label>
                        <PlacesAutocomplete
                            value={this.state.user.location}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                            searchOptions={{ location: new window.google.maps.LatLng(-28.4792625, 24.6727135), radius: 2000 }}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Enter suburb or address ...',
                                            className: 'location-search-input',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>

                    <div className="form-group">
                        <label>Postal Address (If different from Physical)</label>
                        <input className="form-input" type="text" name="postal_address" value={user.postal_address}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'postal_address')
                            }} />
                    </div>
                    <div className="form-group">
                    <label>Is you business registered with the RMI?</label>

                        <select className="form-control" name="rmi_registered" id="rmi_registered"
                        onChange={this.setRmiRegistered.bind(this)} value={user.rmi_registered}>
                            <option value="">--- click to select ---</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>
  
                    <div className="form-group">
                    <label>Do you have insurance to cover workmanship on client's vehicles ?</label>

                    <select className="form-control" name="rmi_registered" 
                    id="workmanship_insurance"
                    value={user.workmanship_insurance}
                    onChange={this.setWorkManship.bind(this)}>
                            <option value="">--- click to select ---</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </div>


                    <div className="form-group">
                    <label>I agree to the Supplier Partner Code of Conduct and will treat all Members with respect & integrity. View Code of Conduct  <a href="/content/supplier-code-off-content">Here</a></label>

                    <select className="form-control" name="code_of_conduct" 
                    id="code_of_conduct"
                    value={user.code_of_conduct}
                    onChange={this.setCodeOfCoduct.bind(this)}>
                            <option value="">--- click to select ---</option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Please provide a brief introduction to your business. Let our Members know why they should deal with you. Also if you specialise in any particular brand of vehicle (e.g. BMW's) or any particular type of repairs (e.g. Gearboxes)</label>
                        <textarea className="form-input" type="text" name="bio" value={user.bio}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'bio')
                            }}></textarea>
                    </div>

                    <legend>Documents</legend>
                    <div className="form-group">
                            <label>Attach business registration document</label><br />
                            {user.business_registration_document && user.business_registration_document.length > 3 && <a href={user.business_registration_document}> &nbsp;&nbsp; Download Current Document</a>}
                            <div> 
                                <input type="file" name="business_registration_document" 
                                onChange={(e) => {this.onFileUpload(e, 'business_registration_document')}} /> 
                            </div>
                    </div>
                    <div className="form-group">
                            <label>Attach proof of address</label><br />
                            {user.proof_of_address && user.proof_of_address.length > 3 && <a href={user.proof_of_address}>Download Current Document</a>}
                            <div> 
                                <input type="file" name="proof_of_address" 
                                onChange={(e) => {this.onFileUpload(e, 'proof_of_address')}} 
                                /> 
                            </div>
                    </div>
                    </div>
                    <div className="col-md-5">
                        {user.photo && <div style={{marginLeft:"70px", paddingTop: "10px"}}>
                        <Thumbnail photo={user.photo} maxWidth="200px" /></div>}
                        <ImageUploader
                                withIcon={true}
                                buttonText="Set / Replace profile photo"
                                onChange={this.onDrop.bind(this)}
                                withPreview={false}
                                singleImage={true}
                                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                                maxFileSize={5242880}
                            />
                    </div>
                    </div>
                    <div className="bg-white" style={{ padding: "10px" }}>
                <div style={{ textAlign: "center" }}>
                    {<ScaleLoader
                        size={5}
                        override={override}
                        color={"red"}
                        loading={this.state.loading}
                    />}
                </div>
                </div>
                    <button className="btn btn-primary" onClick={this.finish.bind(this)} disabled={this.state.loading}>UPDATE PROFILE</button>
                   
                </div>}
            </div>

        );
    }
}