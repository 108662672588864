import React from 'react';
import {BACKEND_ASSET_URL} from '../../lib/api';

export default class Thumbnail extends React.Component {
    render() {
        let media_url = '';
        if (!this.props.photo.match(/http/g)) {
            media_url = BACKEND_ASSET_URL;
        }
        
        return (
            <img src={media_url + this.props.photo} style={{maxWidth: this.props.maxWidth}} />
        )
    }
}