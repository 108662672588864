import React from 'react';
import {Icon} from 'react-icons-kit';
import {documentFill} from 'react-icons-kit/metrize/documentFill';
import {shield} from 'react-icons-kit/metrize/shield'
import {search} from 'react-icons-kit/metrize/search';
import {check} from 'react-icons-kit/metrize/check';
import {reportComment} from 'react-icons-kit/metrize/reportComment';
import {chartDown} from 'react-icons-kit/metrize/chartDown';

export default class OdEdge extends React.Component {
    render() {
        return (
            <section className="site-section border-bottom" id="services-section">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-md-8 text-center">
            <div style={{position: "relative"}}>
              <a id="edge"
                name="edge"
                style={{position:"absolute", top: "-130px"}}
                ></a>
            </div>
              <h2 className="text-black h1 site-section-heading text-center">The Our Deal Advantage</h2>
              <h3>How we add value to you</h3>
            </div>
          </div>
          <div className="row align-items-stretch">
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
              <div className="unit-4-icon mr-4"style={{color: "#32dbc6"}}>
                <Icon  icon={search} color="blue" size={40}/>
                 </div>
                <div>
                  <h3>Convenience & Choice</h3>
                  <p>One convenient search gets you multiple quotes. Then you choose your preferred supplier.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
              <div className="unit-4-icon mr-4"style={{color: "#32dbc6"}}>
                <Icon  icon={chartDown} color="blue" size={40}/>
                 </div>
                <div>
                  <h3>Competitive Prices</h3>
                  <p>Our competitive and transparent system ensures that Supplier Partners offer our Members great prices.</p>

                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
                <div className="unit-4-icon mr-4"style={{color: "#32dbc6"}}>
                <Icon  icon={check} color="blue" size={40}/>
                 </div>
                <div>
                  <h3>Peace of mind</h3>
                  <p>We only select Supplier Partners that meet our criteria and they get paid when you are happy with the job.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
              <div className="unit-4-icon mr-4"style={{color: "#32dbc6"}}>
                <Icon  icon={documentFill} color="blue" size={40}/>
                 </div>
                <div>
                  <h3>Your free, online service history</h3>
                  <p>Download the safely stored records of work done on all your cars anytime.</p>
             
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
              <div className="unit-4-icon mr-4"style={{color: "#32dbc6"}}>
                <Icon  icon={shield} color="blue" size={40}/>
                 </div>
                <div>
                  <h3>Your security is a priority</h3>
                  <p>Our Deal ensures your security with the latest encryption technology. Your payments are processed via an industry leading, secure payment gateway.</p>
     
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
              <div className="unit-4 d-flex">
              <div className="unit-4-icon mr-4"style={{color: "#32dbc6"}}>
                <Icon  icon={reportComment} color="blue" size={40}/>
                 </div>
                <div>
                  <h3>Advice & more</h3>
                  <p>Become a Member of the Our Deal community and get easy access to independent advice and regular special offers to help you manage your vehicles better.</p>
             
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      );
    }
}