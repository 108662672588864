import React from 'react';

import "../../css/search.css";
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

export default class Conditions extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      quoteNumber: '',
      message: '',
      captcha: false
    }
  }


  render() {
    const faqs = [
        {name: "Perfect", copy: <p>No chips or scratches on body panels. No previous paint work. Interior shows no signs of any wear. No damage to windscreen. Tyres above 80% tread. Mechanically perfect with a full documented service history. All features work perfectly. Spare keys & owners manuals available. </p>},
        {name: "Very Good", copy: <p>Few chips or scratches on body panels. Signs of high quality previous cosmetic paint work. Interior shows minor signs of wear. Minor chips on windscreen. Tyres above 70% tread. Mechanically sound with an extensive service history. Spare keys & owners manuals available.</p>},
        {name: "Good", copy: <p>
            Good - Several chips or scratches on body panels. Cosmetic previous paint work. Interior shows signs of normal wear in line with mileage. Chips or small cracks on windscreen. Tyres above 60% tread. Mechanically good with a full (or almost full) service history. All features work well. Spare keys & owners manuals may not be available. 
        </p>},
        {name: "Average", copy: <p>
            Several body panels have scratches and minor dents and will require attention. Interior shows signs of normal wear in line with mileage and seats may need repair. Windscreen may need replacement. Tyres above 50% tread. Mechanically runs well, but may require a service or minor repairs. Has a partial service history. Minor features may need attention. Spare keys & owners manuals may not be available. 
        </p>},
        {name: "Below Average", copy: <p>
            Most body panels will require attention and display signs of poor quality previous work. Possible minor accident damage. Interior shows signs of excessive wear and many items require attention. Windscreen will need replacement. Tyres below 50% tread and may need replacement. Mechanically, does not run well and will require repairs. Has little or no service history. No spare keys & owners manual available. 
        </p>},
        {name: "Poor", copy: <p>
            The body displays signs of a major previous accident and very poor repair work. Interior shows major signs of wear and damage to components. Windscreen will need replacement. Tyres will need replacement. Mechanically, does not run well and will require major repairs. Has no service history. No spare keys & owners manual available. 
        </p>}
    ]
 
    return (
        <div className="site-section">
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12" style={{color:"#000"}}>
                    <h3>Vehicle Condition Types</h3> <br />
                    <Accordion defaultActiveKey="0">
                    {faqs.map((q, i) => {
                           return  <Card>
                            <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={i} style={{color:"#000"}}>
                               {q.name}
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={i}>
                            <Card.Body>{q.copy}</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    })}
                    </Accordion>
                </div>
            </div>
        </div>
        </div>
    )
  }
}
