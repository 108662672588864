import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import {
  loader,
  setMetaInfo,
  moveRequestForm,
} from "../../lib/actions/requestQuote";
import FileUploader from "./../partials/FileUploader";
import Accordion from "react-bootstrap/Accordion";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Icon } from 'react-icons-kit';
import {close} from 'react-icons-kit/fa/close';

class MetaFormSell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFaq: false,
    };
    this.saveForm = this.saveForm.bind(this);
  }

  saveForm(e) {
    e.preventDefault();
    let flatData = {};
    let elements = $("#form").serializeArray();
    for (let i in elements) {
      flatData[elements[i].name] = elements[i].value;
    }
    this.props.setMetaInfo(flatData);
    this.props.moveRequestForm();
    return false;
  }

  upload(files) {
    console.log(files);
  }

  toggleAccordion() {
      if (this.state.showFaq == false) {
          this.setState({showFaq: true});
      } else {
        this.setState({showFaq: false});
      }
  }

  render() {
    const { showFaq } = this.state;
    const faqs = [
      {
        name: "Perfect",
        copy: (
          <p>
            No chips or scratches on body panels. No previous paint work.
            Interior shows no signs of any wear. No damage to windscreen. Tyres
            above 80% tread. Mechanically perfect with a full documented service
            history. All features work perfectly. Spare keys & owners manuals
            available.{" "}
          </p>
        ),
      },
      {
        name: "Very Good",
        copy: (
          <p>
            Few chips or scratches on body panels. Signs of high quality
            previous cosmetic paint work. Interior shows minor signs of wear.
            Minor chips on windscreen. Tyres above 70% tread. Mechanically sound
            with an extensive service history. Spare keys & owners manuals
            available.
          </p>
        ),
      },
      {
        name: "Good",
        copy: (
          <p>
            Good - Several chips or scratches on body panels. Cosmetic previous
            paint work. Interior shows signs of normal wear in line with
            mileage. Chips or small cracks on windscreen. Tyres above 60% tread.
            Mechanically good with a full (or almost full) service history. All
            features work well. Spare keys & owners manuals may not be
            available.
          </p>
        ),
      },
      {
        name: "Average",
        copy: (
          <p>
            Several body panels have scratches and minor dents and will require
            attention. Interior shows signs of normal wear in line with mileage
            and seats may need repair. Windscreen may need replacement. Tyres
            above 50% tread. Mechanically runs well, but may require a service
            or minor repairs. Has a partial service history. Minor features may
            need attention. Spare keys & owners manuals may not be available.
          </p>
        ),
      },
      {
        name: "Below Average",
        copy: (
          <p>
            Most body panels will require attention and display signs of poor
            quality previous work. Possible minor accident damage. Interior
            shows signs of excessive wear and many items require attention.
            Windscreen will need replacement. Tyres below 50% tread and may need
            replacement. Mechanically, does not run well and will require
            repairs. Has little or no service history. No spare keys & owners
            manual available.
          </p>
        ),
      },
      {
        name: "Poor",
        copy: (
          <p>
            The body displays signs of a major previous accident and very poor
            repair work. Interior shows major signs of wear and damage to
            components. Windscreen will need replacement. Tyres will need
            replacement. Mechanically, does not run well and will require major
            repairs. Has no service history. No spare keys & owners manual
            available.
          </p>
        ),
      },
    ];

    return (
      <div>
        <span style={{ fontWeight: "500" }}> Step 3/4:</span>
        <legend
          style={{
            color: "#fff",
            fontWeight: 400,
            borderBottom: "1px solid #ccc",
            padding: "5px",
          }}
        >
          Sell Your Vehicle Details
        </legend>
        <br />
        <form method="POST" id="form" onSubmit={this.saveForm}>
          <div className="form-group">

            
                <div>
                <label>
              Overall Condition <br />

            </label>

              <select
                name="overall_condition"
                id="overall_condition"
                className="metafield form-control"
              >
                <option value="Not sure" selected>
                  Click to select
                </option>
                <option value="Very Good">Click to select</option>
                <option value="Perfect">Perfect </option>
                <option value="Very Good">Very Good</option>
                <option value="Good">Good</option>
                <option value="Average">Average</option>
                <option value="Below Average">Below Average</option>
                <option value="Poor">Poor</option>
              </select>
              <small style={{ fontWeight: "600" }}>
                <a  style={{ color: "#000" }} onClick={this.toggleAccordion.bind(this)}>
                  {showFaq == false && "Click here to learn more about conditions"}

                </a>
              </small>
              </div>
            
            {showFaq && (
              <div> 
              <strong style={{color: "#fff"}}>Overall Conditions - Explanation
              <a onClick={this.toggleAccordion.bind(this)}>
              <Icon icon={close} style={{color:"#fff"}}/>
              </a></strong>
              <Accordion defaultActiveKey="0">
                {faqs.map((q, i) => {
                  return (
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={i}
                          style={{ color: "#000" }}
                        >
                          {q.name}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={i}>
                        <Card.Body style={{color:"#000"}}>{q.copy}</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion><br /></div>
            )}
            <br />
          </div>
          <div className="form-group">
            <label>Approximate price you require</label>
            <input
              type="number"
              className="metafield form-control"
              id="approximate_price"
              name="approximate_price"
            />
          </div>
          <div className="form-group">
            <label>How soon do you want to sell?</label>
            <select
              name="how_soon_do_you_want_to_sell"
              id="how_soon_do_you_want_to_sell"
              className="metafield form-control"
            >
              <option value="Quickly">Click to select</option>
              <option value="Urgent">Urgent </option>
              <option value="One week">One week</option>
              <option value="One month">One month</option>
              <option value="No hurry">No hurry</option>
            </select>
          </div>

          <div className="form-group">
            <label>Please load pictures of your vehicle</label>
            <FileUploader />
          </div>
          <div className="form-group">
            <label>Any additional information about your vehicle?</label>
            <textarea
              id="additional_items_or_information"
              name="additional_items_or_information"
              className="form-input"
            ></textarea>
          </div>
          <div style={{ textAlign: "right" }}>
            <br />
            <button className="btn btn-primary">Next</button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    service: state.requestQuote.service,
    loading: state.requestQuote.loading,
    user: state.requestQuote.user,
    searchedForUser: state.requestQuote.searchedForUser,
    isLoggedIn: state.requestQuote.isLoggedIn,
  };
};
const mapDispatchTopProps = (dispatch) => {
  return {
    setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
    moveRequestForm: () => dispatch(moveRequestForm(3)),
    showLoader: () => dispatch(loader(true)),
    hideLoader: () => dispatch(loader(false)),
  };
};

export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormSell);
