import React from 'react';
import { connect } from 'react-redux';
import {FUEL_TYPES} from '../../lib/func';
import {
     loader,
     getModelActionCreator,
     getVariantActionCreator,
     setCarMmcode,
     moveRequestForm,
     setFuelType,
     setTransmission,
     setColour,
     setYear,
     setMileage,
     setCarId,
     setMake,
     setModel
    } from '../../lib/actions/requestQuote'
import SelectSearch from 'react-select-search';

import '../../css/dropdown.css';

class SearchStepTwo extends React.Component {

    constructor() {
        super()
        this.state = {
            make: null,
            model: null,
            mmcode: null,
            addNewCar: false,
            car_id: null,
            year: 2020,
            mileage: 0
        }
    }

    changeMake(make) {
        this.setState({ make })
        this.props.setMake(make)
        this.props.getModelActionCreator(make)
    }

    changeModel(model) {
        this.setState({ model })
        this.props.setModel(model)
        this.props.getVariantActionCreator(this.state.make, model, this.state.year);
    }

    changeVariant(mmcode) {
        this.setState({ mmcode })
        this.props.setCarMmcode(mmcode)
    }

    setCar(car_id) {
        this.setState({car_id})
        this.props.setCarId(car_id)
    }
    
    setYear(e) {
        let year = e.target.value
        this.setState({year})
        this.props.setYear(year)
    }

    setMileage(e) {
        let mileage = e.target.value;
        this.setState({mileage})
        this.props.setMileage(mileage)
    }

    next() {
        const {make, model, mmcode} = this.state;

        if (this.state.car_id === null) {
            if (make === null) {
                alert("Please tell us the make of your car?");
                return;
            }
    
            if (model === null) {
                alert("Please tell us the model of your car");
                return;
            }
    
            if (mmcode === null && this.props.service != 'Buy a vehicle') {
                alert("Please tell us the variant of your car?");
                return;
            }
        }

       this.props.moveRequestForm()
    }
    componentDidMount() {
        if (this.props.service == 'Vehicle finance') {
            this.props.moveRequestForm(3)
        }
    }
    render() {
        const { user, selectedMmcode, makes, models, variants, loading, service } 
        = this.props;
        let buyOnly = false;
        if (service == 'Buy a vehicle') { 
            buyOnly = true
        }
        let cars = []
        if (user && user.hasOwnProperty('cars')) {
            cars = user.cars;
        }
        

        return <div>
                     <span style={{fontWeight:"500"}}> Step 2/4:</span>
          <legend style={{color:"#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding:"5px"}}>Car Details</legend>
          <br />

            {(buyOnly == false &&  cars.length != 0 && this.state.addNewCar === false) && <div className="ExistingCar">
            <label>Select Car</label>
                {makes && <SelectSearch
                    options={user.cars.map(car => { return { 
                        name: `${car.year} ${car.make} - ${car.model}`, value: car.id}})} defaultValue=""
                    name="car"
                    id="car"
                    placeholder="Choose car"
                    search={true}
                    autoComplete="on"
                    onChange={this.setCar.bind(this)}
                />}
        
                      <div style={{ textAlign: "right" }}>
                      <button className="btn btn-primary" onClick={() => { this.setState({addNewCar: true}) }} >OR - Add New Car</button>
                        &nbsp;&nbsp;
                    <button className="btn btn-primary" onClick={this.next.bind(this)} disabled={loading}>NEXT</button>
                </div>
            </div>}
            {(cars.length == 0 || this.state.addNewCar || buyOnly)  
            && <div className="newCar">

                <label>Year</label>
                <input 
                className="form-input"
                type="number"
                name="year"
                placeholder = "Please enter year of your car"
                onChange={this.setYear.bind(this)}
                />

                <label>Make</label>
                {makes && <SelectSearch
                    options={makes.map(m => { return { name: m.make, value: m.make, key: m.mmcode } })} defaultValue=""
                    name="make"
                    id="make"
                    placeholder="Choose car make"
                    search={true}
                    autoComplete="on"
                    onChange={this.changeMake.bind(this)}
                    key="makeid"
                />}
                <label>Model</label>

                {models && <SelectSearch
                    options={models.map(m => { return { name: m.model, value: m.model, key: m.model } })} defaultValue=""
                    name="model"
                    id="model"
                    placeholder="Choose car model"
                    search={true}
                    autoComplete="on"
                    onChange={this.changeModel.bind(this)}
                    key="modelid"
                />}

               { buyOnly == false && <label>Model Variant</label> &&

                variants && <SelectSearch
                    options={variants.map(v => { return { name: `${v.variant}`, value: v.mmcode, key: v.mmcode } })} defaultValue=""
                    name="variant"
                    id="variant"
                    placeholder="Choose car model variant"
                    search={true}
                    autoComplete="on"
                    onChange={this.changeVariant.bind(this)}
                    key="variantid"
                />
              }

                    <label>Fuel Type</label>
                    <SelectSearch
                    options={FUEL_TYPES} defaultValue=""
                    name="fuel_type"
                    id="fuel_type"
                    placeholder="Choose fuel type"
                    search={true}
                    autoComplete="on"
                    onChange={this.props.setFuelType}
                    key="fueltypeid"
                />


                <label>Transmission</label>
                    <SelectSearch
                    options={
                        [
                            {name: 'Manual', value: 'Manual', key: 'Manual'},
                            {name: 'Automatic', value: 'Automatic', key: 'Automatic'},

                        ]} defaultValue=""
                    name="transmission"
                    id="transmission"
                    placeholder="Choose transmission"
                    search={true}
                    autoComplete="on"
                    onChange={this.props.setTransmission}
                    key="transmissionid"
                />

                {buyOnly == false && <label>Colour</label>}
                {buyOnly == true && <label>Prefered Colours</label>}
                <input 
                className="form-input"
                type="text"
                name="colour"
                placeholder = "Please enter the colour"
                onChange={(e) => {this.props.setColour(e.target.value)}}
                key="colourid"
                />

                {buyOnly == false && 
                <div> <label>Mileage</label>
                <input 
                className="form-input"
                type="number"
                name="mileage"
                placeholder = "Please enter your cars approximate mileage"
                onChange={this.setMileage.bind(this)}
                key="mileageid"
                /></div>}
                <div style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" onClick={this.next.bind(this)} disabled={loading}>NEXT</button>
                </div>

            </div>}

        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        makes: state.requestQuote.makes,
        models: state.requestQuote.models,
        variants: state.requestQuote.variants,
        selectedMmcode: state.requestQuote.selectedMmcode,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        service: state.requestQuote.service
    }
}

const mapDispatchTopProps = (dispatch) => {
    return {
        getModelActionCreator: (make) => getModelActionCreator(make, dispatch),
        getVariantActionCreator: (make, model, year) => getVariantActionCreator(
            make, model, year, dispatch),
        setCarMmcode: (mmcode) => dispatch(setCarMmcode(mmcode)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
        moveRequestForm: () => dispatch(moveRequestForm(4)),
        setFuelType : (fuel) => dispatch(setFuelType(fuel)),
        setTransmission: (transmission) => dispatch(setTransmission(transmission)),
        setColour: (colour) => dispatch(setColour(colour)),
        setYear: (year) => dispatch(setYear(year)),
        setCarId: (car_id) => dispatch(setCarId(car_id)),
        setMake: (make) => dispatch(setMake(make)),
        setModel: (model) => dispatch(setModel(model)),
        setMileage: (mileage) => dispatch(setMileage(mileage))
    }
}

export default connect(mapStateToProps, mapDispatchTopProps)(SearchStepTwo);