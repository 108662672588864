import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import { loader, setMetaInfo, moveRequestForm } from '../../lib/actions/requestQuote';
import FileUploader from './../partials/FileUploader';

class MetaFormSpares extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
        showbrand: false
    }
    this.saveForm = this.saveForm.bind(this)
  }

  selectBrand(e) {
      e.preventDefault()
      if (e.target.value == 'I prefer this brand') {
          this.setState({showbrand: true})
      }
   }

   saveForm(e) {
       e.preventDefault();
    let flatData = {};
    let elements = $('#form').serializeArray()
    for(let i in elements) {
        flatData[elements[i].name] = elements[i].value;
    }
    this.props.setMetaInfo(flatData)
    this.props.moveRequestForm()
    return false;
   }

   upload(files) {
       console.log(files)
   }
  
  render() {
      const {showbrand} = this.state;
      const accessories = [
          "New",
          "Used"
      ]
      let parts = [];
      for(let i=1; i<= 5; i++) {
         parts.push(
              <div className="row">
                    <div className="col-md-3">
                            <label>Part Name: </label>&nbsp;
                            <input type="text" class="form-input" name={"part_" + i} id={"part_" + i} />
                    </div>
                    <div className="col-md-3">
                    <label>Part Quantity: </label> &nbsp;
                    <input type="number"  class="form-input" name={"part_qty_" + i} id={"part__qty_" + i} />
                    </div>
                    <div className="col-md-6">
                        <label>Part Number (Optional): </label> <br />
                    <input type="text"  class="form-input" name={"part_number_" + i} id={"part_number_" + i} />

                    </div>
    
          </div>)
      }
      return (
        <div>
          <span style={{fontWeight:"500"}}> Step 3/4:</span>
          <legend style={{color:"#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding:"5px"}}>Spare Part Details</legend>
          <br />
        <form method="POST" id="form" onSubmit={this.saveForm}>
        <div className="form-group">
        <label>Do you want a new or Used part?</label>
             <select name="new_or_used" className="form-control metafield" id="new_or_used">
                    {accessories && accessories.map(a => {
                        return <option key={a} value={a}>{a}</option>
                    })}
                </select>
         </div>

         {parts} <br />
  
            <div className="form-group">
            <label>
                If possible, load pictures or a video of the items you need help with
            </label>
            <FileUploader />
            </div>
            <div className="form-group">
            <label>
             Please provide any additional information that will assist?
            </label>
            <textarea id="additional_items_or_information" name="additional_items_or_information" className="form-input"></textarea>
            </div>
            <div style={{ textAlign: "right" }}>
                  <br />
                    <button className="btn btn-primary">Next</button>
            </div>
            </form>
            </div>
      );
  }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
}

  
  export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormSpares);