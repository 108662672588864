import React from 'react';
import { getAcceptedUserRequests } from '../../../lib/actions/userDashboard';
import { postDataAdmin } from '../../../lib/api';
import { connect } from 'react-redux';
import SelectSearch from 'react-select-search';
import { FUEL_TYPES, getUrlParts } from '../../../lib/func';
import ImageUploader from "react-images-upload";
import $ from 'jquery';
import Thumbnail from '../../partials/Thumbnail';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CarsDashboard from './CarsDashboard';
import RequestQuotes from '../../partials/RequestQuotes';
import Messages from '../../partials/Messages';
import LandingDashboard from './LandingDashboard';
import {getQuotePrice} from '../../../lib/func';
import StarRatings from 'react-star-ratings';

import {
    loader
} from '../../../lib/actions/requestQuote';

class JobsDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            requestIndex: 0,
            updatingRequest: false,
            messageCount: 0,
            paymentId: 0,
            checksum: 0,
            supplier_rating_stars: 0,
            supplier_rating: ""
        }

        this.saveChanges = this.saveChanges.bind(this);
        this.getField = this.getField.bind(this);
        this.setMessageCount = this.setMessageCount.bind(this);
    }

    setStars(rating) {
        this.setState({supplier_rating_stars: rating})
    }
    setFeedback(e) {
        this.setState({supplier_rating: e.target.value})
    }
    componentDidMount() {
        this.props.getAcceptedUserRequests().then(resp => {
            const paths = getUrlParts()

            if (paths.length == 2) {
                for(let i in resp) {
                    for (let q in resp[i].quotes) {
                        if (resp[i].quotes[q].quote_number == paths[0]) {
                            this.setState({requestIndex: q})
                            return;
                        }
                    }
                }
            }
        })
    }

    nextRequest() {
        const { requestIndex } = this.state;
        let nextIndex = this.state.requestIndex + 1;
        if (this.props.requests[nextIndex] === undefined) {
            alert('Sorry! - you have no more requests.')
            return;
        }

        this.setState({
            requestIndex: nextIndex
        })

    }

    prevRequest() {
        let prevIndex = this.state.requestIndex - 1;
        if (this.props.requests[prevIndex] === undefined) {
            return;
        }

        this.setState({ requestIndex: prevIndex })
    }

    onDrop(pictureFiles, pictureDataURLs) {
        const { requestIndex } = this.state;
        this.props.saveRequestPhoto({
            'photo': pictureFiles[0],
            'request_id': this.props.requests[requestIndex].id
        }, requestIndex)
    }

    saveChanges() {

    }

    getField(field) {
        return this.props.requests[this.state.requestIndex][field];
    }

    setMessageCount(messageCount) {
        this.setState({messageCount})
    }

    submitPayment() {
        const { requestIndex } = this.state;
        const request = this.props.requests[requestIndex];
        const collected = parseFloat(request.quotes[0].payment_collected);
        const totalPrice = parseFloat(getQuotePrice(request.quotes[0], false));
        const outstanding = totalPrice - collected;

        this.props.showLoader();
        postDataAdmin('makePayment', {
            quoteNumber: request.quotes[0].quote_number,
            requestId: request.id,
            price: outstanding
        })
        .then(res => {
            this.props.hideLoader()
            this.setState({
                checksum: res.args.CHECKSUM,
                paymentId: res.args.PAY_REQUEST_ID
            }, () => {$("#paymentform").submit()})
        })
    }
    saveRating() {
        const { requestIndex } = this.state;
        const request = this.props.requests[requestIndex];
        const {supplier_rating, supplier_rating_stars} = this.state;

        if (supplier_rating_stars == 0) {
            window.showMessage("Sorry, please rate the supplier by clicking on at least 1 star.");
            return;
        }
        
        this.props.showLoader()
        postDataAdmin('rateSupplier', {
            quoteId: request.quotes[0].id,
            supplier_rating,
            supplier_rating_stars
        })
        .then(res => {
            this.props.hideLoader()
            window.showMessage('Thank you for your feedback.', '/dashboard/quotes');
        })

    }
    render() {
        const { requestIndex } = this.state;
        const request = this.props.requests[requestIndex];
        const cardHeadingStyle = { color: "red", fontWeight: "900" }
        const numQuotes = request && request.quotes.length || 0;

        let collected = 0;
        let paymentStatus = 'Pending';
        let totalPrice = 0;
        let outstanding = 0;

        if (numQuotes > 0) {
            collected = parseFloat(request.quotes[0].payment_collected);
            totalPrice = parseFloat(getQuotePrice(request.quotes[0], false));

            if (collected > 0 && collected == totalPrice) {
                paymentStatus = "Paid";
            } else if (collected > 0) {
                paymentStatus = "Paid - pending payment on additional items."
            }

            outstanding = totalPrice - collected;
        }

        const navigation = <div>
            <div style={{ textAlign: "right", paddingRight: "20px", color: "#32dbc6" }}><i><b>Request {parseFloat(requestIndex) + 1} of {this.props.requests.length} accepted quotes</b></i></div>
            <div style={{ textAlign: "right", padding: "20px" }}>
                <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={this.prevRequest.bind(this)}>Previous Accepted Quote</button>

                <button className="btn btn-primary" onClick={this.nextRequest.bind(this)}>Next Accepted Quote</button>
            </div></div>

        return (
            <div>
                {(request && request.quotes.length > 0 ) && 
                    <div>
                        {navigation}
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        <span style={cardHeadingStyle}>
                                            Quote Details - {request.quotes[0].quote_number}
                                        </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                    <strong>Category: </strong> {request.service}<br />
                                        {(request.service.toLowerCase() == 'buy a vehicle' && request.car != undefined) &&
                                            <div>
                                                <strong>Year: </strong> {request.car.year} <br />
                                                <strong>Make: </strong> {request.car.car_make} <br />
                                                <strong>Model: </strong> {request.car.car_model} <br />
                                                <strong>Transmission: </strong> {request.car.transmission} <br />
                                                <strong>Fuel Type: </strong> {request.car.fuel_type} <br />
                                                <strong>Prefered Colours: </strong> {request.car.colour} <br />
                                            </div>
                                        }
                                        {request.additional && request.additional.map(a => {
                                            return <div key={a.name}>
                                                <strong>{a.name.replace('_', ' ')} :</strong> <p>
                                                    {a.info}
                                                </p>
                                            </div>
                                        })}

                <strong>Payment Status: </strong> <span>{paymentStatus}</span>
                {(request.quotes[0].paid ==1) && <div>
                    <strong>Amount Paid: </strong> <span>R{collected}</span>

                    {request.quotes[0].closed == 1 && request.quotes[0].supplier_rating_stars == 0 && <div>
                        <br />
                        <hr />
                            <p style={{fontWeight: "900"}}>The Supplier Partner has marked this job as complete - please can you provide feedback on the level of service received: <br /></p>
                            <StarRatings
                                rating={this.state.supplier_rating_stars}
                                starDimension="30px"
                                starSpacing="10px"
                                starRatedColor="rgb(230, 67, 47)"
                                changeRating={this.setStars.bind(this)}
                            /> <br /><br />

                            <textarea  style={{minWidth:"300px"}} onChange={this.setFeedback.bind(this)}>
                                {this.state.supplier_rating}
                            </textarea>
                            <br />

                            <button className="btn btn-primary" onClick={this.saveRating.bind(this)}>Save Rating</button>


                        <br />
                    </div>}

                     {outstanding > 0 && <div>
                            <strong style={{color: "red"}}>Amount Due for additional items: </strong>
                            <span>R{outstanding.toFixed(2)}</span>

                            <span>&nbsp;...&nbsp;<button className="btn btn-danger" onClick={this.submitPayment.bind(this)}>Make Payment</button></span>

                            <form id="paymentform" method="POST" action="https://secure.paygate.co.za/payweb3/process.trans">
                                <input type="hidden" name="PAY_REQUEST_ID" value={this.state.paymentId} />
                                <input type="hidden" name="CHECKSUM" value={this.state.checksum} />
                            </form>
                         </div>}
                    </div>}


                  
<hr />
                                        <RequestQuotes forceSingle={true} requestIndex={this.state.requestIndex} />

                                        {request.quotes[0].payments && request.quotes[0].payments.length > 0 && <div>
                        <br />
                        <hr />
                        <h4>Payments</h4>
    <table className="table table-striped table-bordered">
        <thead><tr><th>Reference</th><th>Amount</th><th>Date and Time</th></tr></thead>
        <tbody>
   
       {
           request.quotes[0].payments.map(function(p) {
               return <tr>
                   <td>{p.paygate_reference}</td>
                   <td>R{parseFloat(p.amount).toFixed(2)}</td>
                   <td>{p.date_and_time}</td>
               </tr>
           })
       }
</tbody>
       </table>
    <br />
    </div>}
                      </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            {request.service.toLowerCase() != 'buy a vehicle' && <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        <span style={cardHeadingStyle}>
                                            Vehicle details
                            </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <CarsDashboard navigation={false} carId={request.car_id} key={request.car_id} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>}

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                        <span style={cardHeadingStyle}>
                                            Messages ({this.state.messageCount})
                            </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <Messages updateCounter={this.setMessageCount} request_id={request.id} key={request.id} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion>
                    </div>
                }

{(!request || (request && request.quotes.length === 0) ) && <div className="alert alert-danger">Sorry you don't have any accepted quotes yet. In the meantime consider updating your profile &nbsp;
                   <a href="/dashboard/profile">here</a> OR make a new request <a href="/">here</a></div>}

                   {(!request || (request && request.quotes.length === 0) ) && <LandingDashboard />}

            </div>



        );
    }
}

const mapStateToProps = (state) => {
    return {
        requests: state.userDashboard.requests
    }
}

const mapDispatchTopProps = (dispatch) => {
    return {
        getAcceptedUserRequests: () => getAcceptedUserRequests(dispatch),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
    
}


export default connect(mapStateToProps, mapDispatchTopProps)(JobsDashboard);