import React from 'react';
import {connect} from 'react-redux';
import { getMakesActionCreator, loader } from '../lib/actions/requestQuote';
import ScaleLoader from "react-spinners/ScaleLoader";
import "../css/search.css";

import SearchStepOne from './forms/SearchStepOne';
import SearchStepTwo from './forms/SearchStepTwo';
import SearchStepThree from './forms/SearchStepThree';
import MetaFormTyres from './forms/MetaFormTyres';
import MetaFormSell from './forms/MetaFormSell';
import MetaFormSpares from './forms/MetaFormSpares';
import MetaFormService from './forms/MetaFormService';
import MetaFormRepair from './forms/MetaFormRepair';
import MetaFormBattery from './forms/MetaFormBattery';
import MetaFormExhausts from './forms/MetaFormExhausts';
import MetaFormWindscreens from './forms/MetaFormWindscreens';
import MetaFormShockAbsorbers from './forms/MetaFormShockAbsorbers';
import MetaFormAircon from './forms/MetaFormAircon';
import MetaFormFinance from './forms/MetaFormFinance';
import MetaFormAccessory from './forms/MetaFormAccessory';
import MetaFormAccident from './forms/MetaFormAccident';
import MetaFormTrimming from './forms/MetaFormTrimming';
import MetaFormSignage from './forms/MetaFormSignage';
import MetaFormBuy from './forms/MetaFormBuy';
import MetaFormTransportation from './forms/MetaFormTransportation';
import MetaFormLicense from './forms/MetaFormLicense';
import MetaFormPrePurchase from './forms/MetaFormPrePurchase';
import MetaFormEvaluations from './forms/MetaFormEvaluations';
import MetaFormOther from './forms/MetaFormOther';

class Search extends React.Component
{
  getMetaForm() {
    switch(this.props.service) {
      case 'Vehicle Service':
        return <MetaFormService />
      case 'Vehicle Repairs':
        return <MetaFormRepair />
      case 'Tyres':
        return <MetaFormTyres />
      case 'Batteries':
        return <MetaFormBattery />
      case 'Exhausts':
        return <MetaFormExhausts />
      case 'Windscreens':
        return <MetaFormWindscreens />
      case 'Shock absorbers':
        return <MetaFormShockAbsorbers />
      case 'Air conditioner repairs':
        return <MetaFormAircon />
      case 'Sell your vehicle':
        return <MetaFormSell />
      case 'Buy a vehicle':
          return <MetaFormBuy />
      case 'Vehicle finance':
        return <MetaFormFinance />
      case 'Accessories':
        return <MetaFormAccessory />
      case 'Spare Parts':
        return <MetaFormSpares />
      case 'Accident Repairs':
        return <MetaFormAccident />
      case 'Auto Trimming':
        return <MetaFormTrimming />
      case 'Signage & Branding':
        return <MetaFormSignage />
      case 'Vehicle Transportation':
        return <MetaFormTransportation />
      case 'License & fines':
        return <MetaFormLicense />
      case 'Pre purchase check over':
        return <MetaFormPrePurchase />
      case 'Vehicle Evaluations':
        return <MetaFormEvaluations />
      case 'Other Requirement':
        return <MetaFormOther />
      default:
        return <SearchStepThree />
    }
  }
  componentDidMount()
  {
   this.props.getMakesActionCreator()
  }

  render() {
    let form = null;
    const {step, loading} = this.props;
    const override = `
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    console.log(step, this.props.service)

    switch (step) {
      case 2:
        form = <SearchStepTwo />;
        break;
      case 3:
        form = <SearchStepThree />
        break
      case 4:
          form = this.getMetaForm()
        break;
      default:
        form = <SearchStepOne />
    }

    if (this.props.user) {
      if (this.props.user.account_type == 'supplier') {
        return "";
      }
    }
    return (
      <div className="site-section">
      <div className="container">
      <div style={{textAlign:"center"}}>
      <hr style={{width:"20%"}}/>
        <h3>Quick & easy quote finder for
anything car related.</h3>
<hr style={{width:"20%"}}/>
</div>
        <div className="row mt-5"> 
          <div className={step == 1 ? "col-md-4" : "col-md-12"}>
            <div className="SearchForm">
              {this.props.step == 1 && 
              <div style={{padding:"5px"}}><h5>One search. Get multiple quotes. </h5></div>}
              <a id="findquotes"></a>

              {form && form}
           
              <div style={{textAlign:"center"}}>
                <ScaleLoader
                  size={5}
                  override={override}
                  color={"#fff"}
                  loading={loading}
                />
              </div>
            </div>

          </div>
          {step == 1 && <div className="col-md-8">
              <div id="searchFormLogo">

                  <img src="/atrader/images/odservices.png" style={{maxWidth:"100%"}}/>
              </div>
            </div>}
        </div>
      </div>
    </div> 
  )
  }
}

const mapStateToProps = (state) => {
  return {
    makes: state.requestQuote.makes,
    service: state.requestQuote.service,
    step: state.requestQuote.step,
    loading: state.requestQuote.loading,
    user: state.requestQuote.user
  }
}

const mapDispatchTopProps = (dispatch) => {
    return {
      getMakesActionCreator: () => getMakesActionCreator(dispatch),
      showLoader: () => dispatch(loader(true)),
      hideLoader: () => dispatch(loader(false))
    }
}

export default connect(mapStateToProps, mapDispatchTopProps)(Search);