import React from 'react';
import {Icon} from 'react-icons-kit';
import AppCaptcha from './partials/AppCaptcha';
import { loader } from '../lib/actions/requestQuote';
import {connect} from 'react-redux';

import {messageSquare} from 'react-icons-kit/feather/messageSquare';
import $ from 'jquery';
import { postData } from '../lib/api';

class ContactForm extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        captcha: false
      }
    }
    sendMessage(e) {
      e.preventDefault();
      const fname = $('#fname').val()
      const lname = $('#lname').val()
      const email = $('#customer-email').val()
      const message = $("#message").val()
      const cellphone = $("#customer-cellphone").val()

      if (fname.length < 3 || lname.length < 3 || email.length < 5) {
        window.showMessage('Please fill in all the required fields. first name, last name, and email');
        return;
      }

      if (message.length < 5) {
        window.showMessage('Sorry, the message you entered is too short. Please enter at least 5 characters.');
        return;
      }

      this.props.showLoader()
      postData('contact_us', {
        'email': email,
        'name': fname + ' ' + lname,
        'message' : message,
        'cellphone': cellphone
      }).then(e => {
        this.props.hideLoader();
        window.showMessage("Thank you. We will be in touch soon.", window.location.href);
      })

    }
    render() {
        const {loading} = this.props;
        const {captcha} = this.state;
        return (
            <section className="site-section bg-light" id="contact-section">
              <br />
            <div className="container">
              <div className="row mb-5">
                <div className="col-12 text-center"> <br />
                <div style={{color: "red"}}>
                  <Icon  icon={messageSquare} color="blue" size={72}/> <br /><br />
                  </div>
                  <a id="contact"></a>
                  <h2 className="text-black h1 site-section-heading">Contact Us</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-5">
                  <form action="#" className="p-5 bg-white">
                    <h2 className="h4 text-black mb-5">Contact Form</h2> 
                    <div className="row form-group">
                      <div className="col-md-6 mb-3 mb-md-0">
                        <label className="text-black" htmlFor="fname">First Name*</label>
                        <input type="text" id="fname" className="form-control" required />
                      </div>
                      <div className="col-md-6">
                        <label className="text-black" htmlFor="lname">Last Name*</label>
                        <input type="text" id="lname" className="form-control" required/>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-12">
                        <label className="text-black" htmlFor="email">Email *</label> 
                        <input type="email" id="customer-email" className="form-control" required/>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-12">
                        <label className="text-black" htmlFor="email">Cellphone</label> 
                        <input type="text" id="customer-cellphone" className="form-control" required/>
                      </div>
                    </div>
  
                    <div className="row form-group">
                      <div className="col-md-12">
                        <label className="text-black" htmlFor="message">Message*</label> 
                        <textarea name="message" id="message" cols={30} rows={7} className="form-control" placeholder="Write your notes or questions here..." defaultValue={""} />
                      </div>
                    </div>
                    <div className="row form-group">
                    <AppCaptcha verifyCallback={() => {
                  this.setState({captcha: true})
                }} />
                    </div>
                    <div className="row form-group">
                      <div className="col-md-12">
                      <button className="btn btn-primary" onClick={this.sendMessage.bind(this)} disabled={loading || captcha === false}>Send</button>
                      </div>
                    </div>
                  </form>
                </div>
   
              </div>
            </div>
          </section>
        );
    }
}

const mapStateToProps = (state) => {
  return {
    loading: state.requestQuote.loading
  }
}

const mapDispatchTopProps = (dispatch) => {
    return {
      showLoader: () => dispatch(loader(true)),
      hideLoader: () => dispatch(loader(false)),
    }
}

export default connect(mapStateToProps, mapDispatchTopProps)(ContactForm);