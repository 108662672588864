import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import { loader, setMetaInfo, moveRequestForm } from '../../lib/actions/requestQuote';
import FileUploader from './../partials/FileUploader';

class MetaFormTyres extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
        showbrand: false
    }
    this.saveForm = this.saveForm.bind(this)
  }

  selectBrand(e) {
      e.preventDefault()
      if (e.target.value == 'I prefer this brand') {
          this.setState({showbrand: true})
      }
   }

   saveForm(e) {
       e.preventDefault();
    let flatData = {};
    let elements = $('#form').serializeArray()
    for(let i in elements) {
        flatData[elements[i].name] = elements[i].value;
    }
    console.log(flatData)
    this.props.setMetaInfo(flatData)
    this.props.moveRequestForm()
    return false;
   }

   upload(files) {
       console.log(files)
   }
  
  render() {
      const {showbrand} = this.state;
      return (
        <div>
          <span style={{fontWeight:"500"}}> Step 3/4:</span>
          <legend style={{color:"#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding:"5px"}}>Tyre Details</legend>
          <br />
        <form method="POST" id="form" onSubmit={this.saveForm}>
        <div className="form-group">
                <label>Which tyres do you need?</label>
                <select name="which_tyres_do_you_need" id="which_tyres_do_you_need" className="metafield form-control">
                    <option value="Front">Front</option>
                    <option value="Rear">Rear</option>
                    <option value="All Four">All Four</option>
                </select>
            </div>
            <div className="form-group">
                <label>What size tyres do you need?  Check the side wall of the tyre. (e.g 195/50/15). 
                    Note, the size of the front may differ from the rear. </label>
                    <input type="text" name="tyre_size" id="tyre_size" className="metafield form-control" />
            </div>

            <div className="form-group">
                <label>Do you want any particular brand?</label>
                {showbrand === false && <select name="brand" className="form-control metafield" id="brand" onChange={this.selectBrand.bind(this)}>
                        <option value="Any suitable brand">Any suitable brand</option>
                        <option value="Lowest Price">Lowest Price</option>
                        <option value="I prefer this brand">I prefer this brand</option>
                </select>}
                <br />
                {showbrand && <br /> && <input type="text" id="brand" name="brand" className="form-control metafield" placeholder="Please type brand you prefer" />}
            </div>

            <div className="form-group">
                <label>Do you want a quote for wheel alignment also? (It's advisable when fitting new tyres)</label>
                <select name="do_you_need_wheel_alignment" id="do_you_need_wheel_alignment" className="metafield form-control">
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                </select>
            </div>

            <div className="form-group">
            <label>
                If possible, load pictures or a video of the items you need help with
            </label>
            <FileUploader />
            </div>
            <div className="form-group">
            <label>
             Please provide any additional information or anything else you want checked/repaired?
            </label>
            <textarea id="additional_items_or_information" name="additional_items_or_information" className="form-input"></textarea>
            </div>
            <div style={{ textAlign: "right" }}>
                  <br />
                    <button className="btn btn-primary">Next</button>
            </div>
            </form>
            </div>
      );
  }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
}

  
  export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormTyres);