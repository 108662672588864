import React from 'react';
import {connect} from 'react-redux';
import { UserAuthActionCreator, loader } from '../lib/actions/requestQuote';
import AppCaptcha from './partials/AppCaptcha';
import AutoCompleteText from './maps';
import ScaleLoader from "react-spinners/ScaleLoader";
import { ADMIN_URL } from '../lib/api';

import "../css/search.css";
import $ from 'jquery';
import { postData } from '../lib/api';

import SelectSearch from 'react-select-search';
import { CAR_SERVICES } from '../lib/func';
import handshake from '../assets/handshake.png';

class SupplierSignupForm extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
      isLogin: true,
      isRegister: false,
      captcha: false,
      address: '',
      services: []
    }
  }

  handleSelect(address) {
    this.setState({address})
  }

  finish()
  {
    const email = $('#email').val();
    const password = $('#password').val();
    const password2 = $('#password2').val();
    const name = $('#name').val();
    const companyName = $('#companyName').val();
    const telephone = $('#telephone').val();
    const services = this.state.services.join(',');
    const {address} = this.state;

    if (!($("#terms").is(":checked"))) {
      window.showMessage('Sorry - please tick the checkbox to accept our terms and conditions.');
      return;
    }
    if (!($("#code_of_conduct").is(":checked"))) {
      window.showMessage('Sorry - please tick the checkbox to accept our code of conduct.');
      return;
    }

    if (services.length == 0) {
      window.showMessage('Sorry - please select at least one service you can offer Our Deal members.');
      return;
    }

    if (name.length < 3 || companyName.length < 3) {
      window.showMessage('Sorry - please enter your name and company name (3 or more characters).');
      return;
    }

    if (email.length < 5) {
      window.showMessage('Sorry - the email entered is invalid.');
      return;
    }

    if (password.length < 5) {
      window.showMessage('Sorry - the password entered is too short. Please enter at least 5 characters.');
      return;
    }

    if (password != password2) {
      window.showMessage('Sorry - Passwords do not match, please double check the passwords entered.');
      return;
    }

    this.props.showLoader()
    postData('singupSupplier', {
      password,
      name,
      email,
      companyName,
      services,
      address,
      telephone
    }).then(res => res.json())
    .then(res => {
      this.props.hideLoader();
      if (res.hasOwnProperty('error')) {
        window.showMessage(res.error);
      } else {
        window.showMessage('Thank you for joining the Our Deal family as a valuable Supplier Partner. Please be aware we are not live yet and will notify you as soon as this website is open to the general public.', '/partner-register');
      }
    }).catch(err => {
      this.props.hideLoader();
      window.showMessage('Sorry an unknown error occurred. Please try again.');
    })

  }

  componentDidMount()
  {
    this.handleSelect = this.handleSelect.bind(this);
    this.setState({isLogin: this.props.isLogin, isRegister: this.props.isRegister})
  }

  render() {
    const services = CAR_SERVICES.map(s => {return {name: s, value: s, key: s}})
    const { loading, isLoggedIn} = this.props;
    const {captcha} = this.state;
    const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
 
    if (isLoggedIn) {
      window.location.href = ADMIN_URL;
    }

    return (
      <div>
      <div className="site-section" style={{background: 'rgb(218 79 20)',  minHeight: '500px'}}>
      <div className="container">
        <div className="row mt-5"> 
  
          <div className="col-md-6">
            <div className="SearchForm">
              <div style={{padding:"5px"}}><h5>Our Deal Supplier Registration </h5></div>
                <div>
                  <div className="row">
                    <div className="col-md-6">
                    <label>Your Name</label>
                       <input id="name" className="form-input" type="text" name="name" placeholder="Enter Your Name" />
                    </div>

                    <div className="col-md-6">

                <label>Company Name</label>
                <input id="companyName" className="form-input" type="text" name="company-name" placeholder="Enter Company Name" />
                <br />
                      </div>
                  </div>

<div className="row">
  <div className="col-md-6">
  <label>Email</label>
                <input id="email" className="form-input" type="email" name="email" placeholder="Enter Email" />

  </div>
  <div className="col-md-6">
  <label>Telephone Number</label>
                <input id="telephone" className="form-input" type="number" name="telephone" placeholder="Enter Telephone Number" />

  </div>
</div>

                <div className="row">
                    <div className="col-md-6">
                    <label>Password</label>
                <input id="password" className="form-input"  type="password" name="password" placeholder="Enter Password" />
                    </div>
                    <div className="col-md-6">

                <label>Password Again</label>
                <input id="password2" className="form-input"  type="password" name="password2" placeholder="Enter Password Again" />
  
                    </div>
                </div>
                <label>Company Address</label>
                <AutoCompleteText
                  placeholder="Enter your address here..."
                  onLocationChanged={this.handleSelect}
                />
        <br />
        <label>Services You offer</label>
        <SelectSearch
                    options={services}
                    name="service"
                    id="service"
                    placeholder="Click on as many services as applicable"
                    search={true}
                    multiple={true}
                    autoComplete="on"
                    onChange={services => {this.setState({services})} }
                />

          <div className="form-group">
            <input type="checkbox" id="code_of_conduct" name="code_of_conduct" /> &nbsp;I Agree to the <a href="https://f002.backblazeb2.com/file/ourdeal/Final+O.D.+Supplier+Code+of+conduct+29+Aug+2022.pdf" target="_blank" style={{"color":"blue"}}>supplier code of code</a><br />
            <input type="checkbox" id="terms" name="terms" style={{"color":"blue"}} /> &nbsp;I Agree to the <a href="https://f002.backblazeb2.com/file/ourdeal/Final+OD+Supplier+Partner+Terms+%26+Conditions+Aug+2022.pdf" target="_blank" style={{"color":"blue"}}>supplier terms and conditions</a>
          </div>
          <AppCaptcha verifyCallback={() => {
                  this.setState({captcha: true})
                }} />
                </div>
                <div className="form-group" style={{paddingLeft: "8px"}}>
            <div style={{display:"none"}}>        </div>
     
           </div>

      
              
                <div style={{ textAlign: "right" }}>
                  <br />
                    <button className="btn btn-primary" onClick={this.finish.bind(this)} disabled={loading || captcha === false}>Signup</button>
                </div>
           
              <div style={{textAlign:"center"}}>
                <ScaleLoader
                  size={5}
                  override={override}
                  color={"#fff"}
                  loading={loading}
                />
              </div>
              
            </div>

            

          </div>
          <div className="col-md-6" style={{color: "#fff"}}>

          <iframe width="560" height="315" src="https://www.youtube.com/embed/qPHGV1stgcw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
 <br /><br />


                      <p>Become an Our Deal Supplier Partner and earn great money for 
                        helping our community of vehicle owners to manage and maintain 
                        their vehicles. You deliver quality service at competitive prices 
                        and we send you lots of clients. It's that simple! </p>
                  

            <h5>Our Deal welcome applications from partners that can:</h5> <br />
            <ul>
                <li>Deliver high quality service to O.D members</li>
                <li>Offer good value for money to members</li>
                <li>Treat O.D members with the utmost integrity</li>
            </ul>
          </div>

        </div>
      </div>
    </div> 
    </div>
  )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.requestQuote.loading,
    isLoggedIn: state.requestQuote.isLoggedIn
  }
}

const mapDispatchTopProps = (dispatch) => {
    return {
      showLoader: () => dispatch(loader(true)),
      hideLoader: () => dispatch(loader(false)),
    }
}

export default connect(mapStateToProps, mapDispatchTopProps)(SupplierSignupForm);