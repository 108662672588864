import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import { loader, setMetaInfo, moveRequestForm } from '../../lib/actions/requestQuote';
import FileUploader from './../partials/FileUploader';

class MetaFormShockAbsorbers extends React.Component
{
  constructor(props) {
        super(props)
        this.state = {
            showbrand: false
        }
        this.saveForm = this.saveForm.bind(this)
   }
   selectBrand(e) {
        e.preventDefault()
        if (e.target.value == 'I prefer this brand') {
            this.setState({showbrand: true})
        }
   }
   saveForm(e) {
        e.preventDefault();
        let flatData = {};
        let elements = $('#form').serializeArray()
        for(let i in elements) {
            flatData[elements[i].name] = elements[i].value;
        }
        this.props.setMetaInfo(flatData)
        this.props.moveRequestForm()
        return false;
   }


  
  render() {
    const {showbrand} = this.state;
      return (
        <div>
          <span style={{fontWeight:"500"}}> Step 3/4:</span>
          <legend style={{color:"#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding:"5px"}}>Shock Absorber Details</legend>
          <br />
        <form method="POST" id="form" onSubmit={this.saveForm}>
            <div className="form-group">
                <label>
                    Describe the problem with your shocks. Please provide as much detail as possible 
                </label>
                <textarea id="description" name="description" className="form-input"></textarea>
            </div>
            <div className="form-group">
                <label>Do you want any particular brand?</label>
                {showbrand === false && <select name="brand" className="form-control metafield" id="brand" onChange={this.selectBrand.bind(this)}>
                        <option value="Any suitable brand">Any suitable brand</option>
                        <option value="Lowest Price">Lowest Price</option>
                        <option value="I prefer this brand">I prefer this brand</option>
                </select>}
                <br />
                {showbrand && <br /> && <input type="text" id="brand" name="brand" className="form-control metafield" placeholder="Please type brand you prefer" />}
            </div>

            <div className="form-group">
            <label>
                If possible, load pictures or a video of the items you need help with
            </label>
            <FileUploader />
            </div>
            <div className="form-group">
            <label>
             Please provide any additional information or anything else you want checked/repaired?
            </label>
            <textarea id="additional_items_or_information" name="additional_items_or_information" className="form-input"></textarea>
            </div>
            <div style={{ textAlign: "right" }}>
                  <br />
                    <button className="btn btn-primary">Next</button>
            </div>
            </form>
            </div>
      );
  }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
}

  
  export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormShockAbsorbers);