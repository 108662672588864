import {postDataSupplier} from '../api'
import {dashboardLoader} from './userDashboard';

// actions
export const SET_SUPPLIER_REQUESTS = 'SET_REQUESTS';

export function getSupplierRequests(dispatch) {
    dispatch(dashboardLoader(true))

    return postDataSupplier('requests').then((resp) => {
        dispatch(setRequests(resp))
        dispatch(dashboardLoader(false))
        return resp;
    }).catch(rej => {
        dispatch(dashboardLoader(false))

    })
}

export function getAcceptedSupplierRequests(dispatch) {
    dispatch(dashboardLoader(true))
    return postDataSupplier('acceptedRequests').then((resp) => {
        dispatch(setRequests(resp))
        dispatch(dashboardLoader(false))

        return resp;
    }).catch(rej => {
        dispatch(dashboardLoader(false))

    })
}

export function quoteMember(data, dispatch) {
    dispatch(dashboardLoader(true))
    return postDataSupplier('quoteMember', data).then((resp) => {
        dispatch(setRequests(resp))
        dispatch(dashboardLoader(false))
        return resp;
    }).catch(rej => {
        dispatch(dashboardLoader(false))

    })
}

export function updateCarVinCassisNumbers(data, dispatch) {
    dispatch(dashboardLoader(true))
    return postDataSupplier('updateCarVinCassisNumbers', data).then((resp) => {
        dispatch(setRequests(resp))
        dispatch(dashboardLoader(false))
        return resp;
    }).catch(rej => {
        dispatch(dashboardLoader(false))

    })
}

function setRequests(requests) {
    return {
        type: SET_SUPPLIER_REQUESTS,
        requests
    }
}