import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {postData} from '../../lib/api';
import { useSelector, useDispatch } from 'react-redux';
import { loader } from '../../lib/actions/requestQuote';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };
  
function FileUploader() {
  const requestNumber = useSelector(state => state.requestQuote.request_number)
  const dispatch = useDispatch()

  const onDrop = useCallback(acceptedFiles => {
    for(let i in acceptedFiles) {
      dispatch(loader(true))
      postData('saveRequestMediaFile', {
          photo: acceptedFiles[i],
          request_number: requestNumber
      }).then(r => r.json())
      .then(r => {
        dispatch(loader(false))
      })
      .catch(e => dispatch(loader(false)))
    }
    }, [])

  const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone({onDrop})
  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <div>
    <section className="container" style={{border: "1px solid #ccc;" , 
    padding: "2px" , backgroundColor: "#fff", 
    heigh:"200px" ,color:"#000", borderRadius: "5px"}}>
    <div {...getRootProps()}>
      <input {...getInputProps()} />

        <p style={{marginLeft: "10px"}}>
            <br />
            click <span style={{color:"red"}}>here</span> to load pictures / documents or a video.
        </p>

    </div>
    <ul>{files}</ul>
    </section>
    </div>
  )
}

export default FileUploader;