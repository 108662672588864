import React from 'react';
import { connect } from 'react-redux';
import {
  loader,
  setLatLng,
  setAddress,
  setCellphone,
  setName,
  finalizeRequest
} from '../../lib/actions/requestQuote'
import AutoCompleteText from "../maps";

import $ from 'jquery';

class SearchStepThree extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        address: '',
        lat:'-29.8371337' ,
        lng: '30.7762617'
      };
      this.handleSelect = this.handleSelect.bind(this);
    }
        componentDidMount()
  {
      if (this.props.isLoggedIn === true &&  this.props.user.location) {
       this.handleSelect(this.props.user.location)
      }
    }

    handleSelect(address) {
      this.setState({address})
    }

    finish(){
      const { 
        selectedMmcode,
        service,
        car_id,
        request_number,
        address,
        lat,
        lng,
        fuel_type,
        transmission,
        colour,
        cellphone,
        email_address,
        name,
        mileage,
        year,
        make,
        model,
        service_fields,
        isLoggedIn
      } = this.props;

      let payload = {
        selectedMmcode,
        service,
        car_id,
        request_number,
        address,
        lat,
        lng,
        fuel_type,
        transmission,
        colour,
        cellphone,
        email_address,
        name,
        mileage,
        year,
        make,
        model,
        service_fields: JSON.stringify(service_fields)
      }
      if (isLoggedIn == false) {
        let password = $("#password").val();
        let confirm_password = $("#confirm_password").val()

        if (password.length < 3) {
            window.showMessage("Sorry - please enter an account password.");
            return;
        }

        if (password != confirm_password) {
          window.showMessage("Sorry - password and confirm password do not match.");
          return;
        }

        payload.password = password;
      }
      
      this.props.showLoader()
      this.props.finalizeRequest(payload)
    }

   
    render() {
      const { loading, isLoggedIn, user} = this.props;
      let step_of = '4/4';
      if (this.props.service == 'Vehicle finance') {
        step_of = '3/3'
    }
      return (
        <div>
          <span style={{fontWeight:"500"}}> Step {step_of}:</span>
          <legend style={{color:"#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding:"5px"}}> Your Details</legend>
          <br />
        <label>Your suburb or address</label>
         <AutoCompleteText
            placeholder="Enter your address here..."
            onLocationChanged={this.handleSelect}
          />
        {isLoggedIn == false && <div>
          <label>Your Name</label>
          <input 
          type="text"
          className="form-input"
          id="name"
          placeholder="Your Name"
          onChange={(e) => this.props.setName(e.target.value)}
           />
      
        </div>}
        {isLoggedIn == false && <div>
          <label>Your Password</label>
          <input 
          type="password"
          className="form-input"
          id="password"
          placeholder="Your Password"
           />
           </div>}
          {isLoggedIn == false && <div>
          <label>Confirm Password</label>
          <input 
          type="password"
          className="form-input"
          id="confirm_password"
          placeholder="Confirm Password"
           />
      
        </div>}
<div style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" onClick={this.finish.bind(this)} disabled={loading}>FINISH</button>
                </div>
        </div>
      );
    }
  }

const mapStateToProps = (state) => {
    return {
      loading: state.requestQuote.loading,
      isLoggedIn: state.requestQuote.isLoggedIn,
      user: state.requestQuote.user,
      selectedMmcode: state.requestQuote.selectedMmcode,
      service: state.requestQuote.service,
      car_id: state.requestQuote.car_id,
      user: state.requestQuote.user,
      request_number: state.requestQuote.request_number,
      address: state.requestQuote.address,
      lat: state.requestQuote.lat,
      lng: state.requestQuote.lng,
      fuel_type: state.requestQuote.fuel_type,
      transmission: state.requestQuote.transmission,
      colour: state.requestQuote.colour,
      email_address: state.requestQuote.email_address,
      cellphone: state.requestQuote.cellphone,
      name: state.requestQuote.name,
      mileage: state.requestQuote.mileage,
      year: state.requestQuote.year,
      service_fields: state.requestQuote.service_fields,
      make: state.requestQuote.make,
      model: state.requestQuote.model
    }
}

const mapDispatchTopProps = (dispatch) => {
    return {
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
        setLatLng: (lat, lng) => dispatch(setLatLng(lat, lng)),
        setAddress: (address) => dispatch(setAddress(address)),
        setCellphone: (cellphone) => dispatch(setCellphone(cellphone)),
        setName: (name) => dispatch(setName(name)),
        finalizeRequest: (data) => finalizeRequest(data, dispatch),
        
    }
}

export default connect(mapStateToProps, mapDispatchTopProps)(SearchStepThree);