import React from 'react';
import { Link } from 'react-router-dom';
import CarsDashboard from './user/CarsDashboard';
import RequestsDashboard from './user/RequestsDashboard';
import SupplierProfileDashboard from './supplier/SupplierProfileDashboard';
import SupplierJobsDashboard from './supplier/SupplierJobsDashboard';

import { getUrlAction } from '../../lib/func';
import {getMakesActionCreator} from '../../lib/actions/requestQuote';
import ScaleLoader from "react-spinners/ScaleLoader";
import {logout} from '../../lib/actions/requestQuote';
import {connect} from 'react-redux';
import JobsDashboard from './user/JobsDashboard';
import ProfileDashboard from './user/ProfileDashboard';
import LandingDashboard from './user/LandingDashboard';
import AddCarDashboard from './user/AddCarDashboard';

class Dashboard extends React.Component {
  componentDidMount() {
    const view = getUrlAction();
    if (view === 'logout') {
      this.props.logout()
    }
    this.props.getMakes();

    if(!this.props.isLoggedIn) {
      console.log('redirecting user')

      sessionStorage.setItem('ref_url', window.location.href);
      window.location.href = '/login';
    }
  }

  componentDidUpdate() {
    const view = getUrlAction();
    if (view === 'logout') {
      this.props.logout()
    }
    
    if(!this.props.isLoggedIn) {
      console.log('redirecting user')
      sessionStorage.setItem('ref_url', window.location.href);
      window.location.href = '/login';
    }
    this.props.getMakes();

  }

  render() {
    const {user} = this.props;
    const params = getUrlAction(false);
    let viewid = null;
    let view = '';
    console.log(params)
    if (params[params.length-1].startsWith('id:')) {
      viewid = params.pop().replace('id:', '')
      view = params.pop()
    } else {
      view = params.pop()
    }

    console.log(view, viewid)
    let heading = '';
    let renderedView = '';

    console.log(user)
    let menus = []

    const navStype = {
      color: "red",
      "fontWeight": 900,
      fontSize: "16px"
    };

    if (user && user.account_type == 'member') {
      heading = user.name + ' - Our Deal Account'
      menus = [
        { title: 'Cars', link: '/dashboard/cars' },
        { title: 'Open Requests', link: '/dashboard/requests' },
        { title: 'Accepted Quotes', link: '/dashboard/quotes' },
        { title: 'Request Quote', link: '/' },
        { title: 'My Profile', link: '/dashboard/profile' },
        { title: 'Logout', link: '/dashboard/logout' },
      ]

      if (view == 'cars') {
        menus[0].title = 'Add Car'
        menus[0].link = '/dashboard/add-car'
      }
      if (view != 'dashboard' && view != '') {
        menus = [{title: '<<Back', link: '/dashboard'}, ...menus]
      }
  
      renderedView = <LandingDashboard hasNavigation={true} />
      switch (view) {
        case 'home':
          heading = "My Account Home"
          renderedView = renderedView
          break;
        case 'requests':
          heading = 'My Requests'
          renderedView = <RequestsDashboard requestNumber = {viewid}/>
          break;
        case 'cars':
          heading = 'My Cars'
          renderedView = <CarsDashboard  hasNavigation={true} />
          break;
        case 'add-car':
        case 'add-cars':
          heading = 'Add New Car'
          renderedView = <AddCarDashboard />
          break;
        case 'quotes':
          heading = 'My Accepted Quotes'
          renderedView = <JobsDashboard />
          break;
        case 'profile':
          heading = 'My Profile'
          renderedView = <ProfileDashboard />
          break;
      }
    } else if (user && user.account_type == 'supplier') {
      menus = [
        { title: 'Open Requests', link: '/supplier/dashboard' },
        { title: 'Accepted Quotes', link: '/supplier/dashboard/quotes' },
        { title: 'My Profile', link: '/supplier/dashboard/profile' },
        { title: 'Logout', link: '/dashboard/logout' },
      ]

      heading = 'Quote Requests'
      switch (view) {
        case 'quotes':
          heading = 'Accepted Quotes'
          renderedView = <SupplierJobsDashboard quoteNumber={viewid} />
          break;
        case 'profile':
          heading = 'My Profile'
          renderedView = <SupplierProfileDashboard />
          break;
      }
    } else {
      window.location.href = '/'
    }

    const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
      `;


    return (
      <section className="site-section bg-light" id="contact-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 text-center">
              <br /><br /><br />
              <h3 className="text-black h1 site-section-heading">{heading}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="p-4 mb-3 bg-white">
                <div className="categories">
                  {menus && menus.map(m => {
                    return <li key={m.title}><Link style={navStype} to={m.link}>{m.title}</Link></li>
                  })}

                </div>
              </div>
            </div>
            <div className="col-md-10 mb-5">
              <div className="bg-white">
                <div style={{ textAlign: "center" }}>
                  {<ScaleLoader
                    size={5}
                    override={override}
                    color={"red"}
                    loading={this.props.loading}
                  />}
                </div>
                {renderedView}

              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.userDashboard.loading,
    isLoggedIn: state.requestQuote.isLoggedIn,
    user: state.requestQuote.user
  }
}

const mapDispatchTopProps = (dispatch) => {
  return {
    logout: () => logout(dispatch),
    getMakes: () => getMakesActionCreator(dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchTopProps)(Dashboard);