import React from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { getQuotePrice } from '../../lib/func';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {postDataSupplier} from '../../lib/api';
import $ from 'jquery';

class SupplierRequestQuotes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMessageDialog: false,
            quoteNumber: null,
            message: '',
            showBookingForm: false,
            quote: null
        }
        this.showMessageDialog = this.showMessageDialog.bind(this);
        this.showBookingForm = this.showBookingForm.bind(this);
        this.closeBookingForm = this.closeBookingForm.bind(this);
    }


    closeBookingForm() {
        this.setState({showBookingForm: false, quote: null})
    }
    showBookingForm(quote) {
        this.setState({showBookingForm: true, quote})
    }

    showMessageDialog(quoteNumber) {
        this.setState({ showMessageDialog: true, quoteNumber })
    }

    sendMessage(e)
    {
        const {message, quoteNumber} = this.state;
        if (message.length < 5) {
            alert('Sorry the message entered is too short.');
            return;
        }

        this.setState({ showMessageDialog: false, quoteNumber: null, message: '' })
        postDataSupplier('sendMessage', {message, quoteNumber});
        alert('Thank you message. Sent successfully');
    }

    cancelMessage()
    {
        this.setState({ showMessageDialog: false, quoteNumber: null, message: '' })
    }

    setMessage(e) {
        this.setState({message: e.target.value})
    }

    render() {
        const quotes = this.props.requests[this.props.requestIndex].quotes;
        const messageSupplier = <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>Send member a message</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p><textarea className="form-input" placeholder="Please type message here" onChange={this.setMessage.bind(this)}></textarea></p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={this.sendMessage.bind(this)}>Send</Button>
                <Button variant="primary" onClick={this.cancelMessage.bind(this)}>Cancel</Button>
            </Modal.Footer>
        </Modal.Dialog>

        return (

            <div>{(quotes && this.props.forceSingle === false) && <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            {quotes.map(q => {
                                return <Nav.Item>
                                    <Nav.Link eventKey={q.quote_number}>{q.quote_number} - {getQuotePrice(q)}</Nav.Link>
                              <hr />
                                </Nav.Item>

                            })}


                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>

                        {this.state.showMessageDialog && messageSupplier}
                
                            {quotes.map(q => {
                                return <Tab.Pane eventKey={q.quote_number}>
                                    <strong>Description:</strong>
                                    <p>
                                        {q.description}
                                    </p>
                                    <strong>Price incl VAT:</strong> {getQuotePrice(q)} <br />
                                    <hr />
                                    <strong>Notes:</strong>
                                    <p>
                                        {q.additional_notes}
                                    </p>

                                    <hr />
                    
                              <button className="btn-primary" onClick={() => {this.showBookingForm(q)}}>Accept Quote</button>
        
                             
                             &nbsp;&nbsp;
                                <button className="btn-primary" onClick={() => {this.showMessageDialog(q.quote_number)}}>Send Message</button>
                                </Tab.Pane>
                            })}

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>}

            {(quotes && this.props.forceSingle === true) && <div>
                
                <strong>Quoted Item Description:</strong>
                <p>
                    {quotes[0].description}
                </p>
                <strong>Price incl VAT:</strong> {getQuotePrice(quotes[0])} <br />
                <hr />
                <strong>Notes:</strong>
                <p>
                    {quotes[0].additional_notes}
                </p>

                <hr />
                {this.state.showMessageDialog && messageSupplier}

                <button className="btn-primary" onClick={() => {this.showMessageDialog(quotes[0].quote_number)}}>Send Member Message</button>

            </div>}

            
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        requests: state.supplierDashboard.requests
    }
}


export default connect(mapStateToProps)(SupplierRequestQuotes);