import {postDataAdmin, postData} from '../api'

// actions
export const SET_CARS = 'SET_CARS';
export const SET_REQUESTS = 'SET_REQUESTS';
export const UPDATE_CAR_AT_INDEX = 'UPDATE_CAR_AT_INDEX';
export const SHOW_DASHBOARD_LOADING = 'SHOW_DASHBOARD_LOADING';
export const HIDE_DASHBOARD_LOADING = 'HIDE_DASHBOARD_LOADING';
export const SET_CAR_PHOTO = 'SET_CAR_PHOTO';
export const SET_QUOTE_NUMBERS = 'SET_QUOTE_NUMBERS';


export function getUserCars(dispatch) {
    return postDataAdmin('cars').then((resp) => {
        dispatch(setCars(resp))
        return resp;
    })
}

export function getUserRequests(dispatch) {
    dispatch(dashboardLoader(true))

    return postDataAdmin('requests').then((resp) => {
        dispatch(setRequests(resp))
        dispatch(dashboardLoader(false))
        return resp;
    }).catch(rej => {
        dispatch(dashboardLoader(false))

    })
}

export function getUserQuoteNumbers(dispatch) {
    dispatch(dashboardLoader(true))

    return postDataAdmin('myQuoteNumbers').then((resp) => {
        dispatch(setQuoteNumbers(resp))
        dispatch(dashboardLoader(false))
        return resp;
    }).catch(rej => {
        dispatch(dashboardLoader(false))

    })
}

export function setQuoteNumbers(quoteNumbers)
{
    return {
        type: SET_QUOTE_NUMBERS,
        quoteNumbers
    }
}

export function getAcceptedUserRequests(dispatch) {
    dispatch(dashboardLoader(true))
    return postDataAdmin('acceptedRequests').then((resp) => {
        dispatch(setRequests(resp))
        dispatch(dashboardLoader(false))

        return resp;
    }).catch(rej => {
        dispatch(dashboardLoader(false))

    })
}

export function dashboardLoader(show = true)
{
    if (show) {
        return {
            type: SHOW_DASHBOARD_LOADING
        }
    } else {
        return {
            type:  HIDE_DASHBOARD_LOADING
        }
    }
}

function setCars(cars) {
    return {
        type: SET_CARS,
        cars
    }
}

function setRequests(requests) {
    return {
        type: SET_REQUESTS,
        requests
    }
}


function setCarPhoto(photo, index)
{
    return {
        type: SET_CAR_PHOTO,
        photo,
        index
    }
}

export function saveCarPhoto(data, index, dispatch) {
    postDataAdmin('carPhoto', data).then(res => {
        if (res.hasOwnProperty('photo')) {
            dispatch(setCarPhoto(res.photo, index))
        }
    })
}

export function saveRequestPhoto(data, dispatch) {
    dispatch(dashboardLoader(true))
    postDataAdmin('saveRequestPhoto', data).then(res => {
        if (res) {
            dispatch(setRequests(res))
        }
        dispatch(dashboardLoader(false))

    }).catch(rej => {
        console.log(rej)
        alert('Sorry failed to upload image - please try again. If the problem persists - try a different image.');
        dispatch(dashboardLoader(false))

    })
}


export function updateCar(id, carIndex, updates, sendToApi, dispatch) {
    dispatch(updateCarsAtIndex(carIndex, updates))
    if (sendToApi) {
        dispatch(dashboardLoader(true))
        postDataAdmin('updateCar/' + id, updates).then(res => {
            dispatch(dashboardLoader(false))
        }).catch(res => {
            dispatch(dashboardLoader(false))
        })
    }

}


function updateCarsAtIndex(carIndex, updates)
{
    return {
        type: UPDATE_CAR_AT_INDEX,
        updates,
        carIndex
    }
}