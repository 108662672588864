import React from 'react';
import { connect } from 'react-redux';

class BurgerMenu extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      quoteNumber: '',
      message: '',
      captcha: false
    }
  }


  render() {
    let menus = [
        {link: '/#howourdealworks', name: "How our deal works"},
        {link: '/#edge', name: "Why use ourdeal"},
        {link: '/', name: "Get free Quotes"},
        {link:'/register', name: "Join Now! For Free"},
        {link: '/partner-register', name: "Become a Supplier Partner"},
        {link: '/login', name: "Member & Supplier Login"},
        {link: '/easy-chat', name: "Easy Chat"},
        {link: '/faqs', name: "FAQ"}
    ]

    if (this.props.isLoggedIn) {
        menus[3] = {link: '/dashboard/landing', name: 'My Account'}
        menus[4] = {link: '//dashboard/logout', name: "Logout"}
    }
    return (

        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12" style={{color:"#000"}}>
                    <h3>Menu</h3> 
                    <ul className="list-group">
                    {menus.map((m, i) => {
                        return <li className="list-group-item">
                            <a href={m.link}>{m.name}</a>
                        </li>
                    })
                    }
                    </ul>
                </div>
            </div>
        </div>

    )
  }
}

const mapStateToProps = (state) => {
    return {
      isLoggedIn: state.requestQuote.isLoggedIn,
    }
  }
  export default connect(mapStateToProps)(BurgerMenu);