import {getSessionUser} from '../func';
import {SET_SUPPLIER_REQUESTS} from '../actions/supplierDashboard';

const user = getSessionUser()

const intialState = {
    requests:[],
    user: user
}

export function supplierDashboard(state = intialState, action) {
    switch(action.type) {
        case SET_SUPPLIER_REQUESTS:
            return {...state, requests: action.requests}
        default:
            return state
    }
}