import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import { loader, setMetaInfo, moveRequestForm } from '../../lib/actions/requestQuote';
import FileUploader from './../partials/FileUploader';

class MetaFormOther extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
        otherWindscreen: false
    }
    this.saveForm = this.saveForm.bind(this)
    this.selectProblem = this.selectProblem.bind(this)
  }

  selectBrand(e) {
      e.preventDefault()
      if (e.target.value == 'I prefer this brand') {
          this.setState({showbrand: true})
      }
   }

   selectProblem(e) {
       e.preventDefault();
       if (e.target.value == 'Other') {
           this.setState({otherWindscreen: true})
       }
   }

   saveForm(e) {
       e.preventDefault();
    let flatData = {};
    let elements = $('#form').serializeArray()
    for(let i in elements) {
        flatData[elements[i].name] = elements[i].value;
    }
    this.props.setMetaInfo(flatData)
    this.props.moveRequestForm()
    return false;
   }

   upload(files) {
       console.log(files)
   }
  
  render() {
      const {otherWindscreen} = this.state;
      const trimmings = [
          'Complete vehicle wrap',
          'Magnetic door signs',
          'Window signage',
          'Decoractive graphics',
          'License disc holders',
          'Other'
      ]
      return (
        <div>
          <span style={{fontWeight:"500"}}> Step 3/4:</span>
          <legend style={{color:"#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding:"5px"}}>Other Requirement Details</legend>
          <br />
        <form method="POST" id="form" onSubmit={this.saveForm}>

            <div className="form-group">
            <label>
            Please provide us with details of what other assistance you require with your vehicle. 
            </label>
            <textarea id="description" name="description" className="form-input"></textarea>
            </div>
            <div className="form-group">
            <label>
                If possible, load pictures or a video of the items you need help with
            </label>
            <FileUploader />
            </div>

            <div style={{ textAlign: "right" }}>
                  <br />
                    <button className="btn btn-primary">Next</button>
            </div>
            
            </form>
            </div>
      );
  }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
}

  
  export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormOther);