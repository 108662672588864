import React from 'react';
import {updateCarVinCassisNumbers} from '../../lib/actions/supplierDashboard';
import ScaleLoader from "react-spinners/ScaleLoader";
import { connect } from 'react-redux';
import Thumbnail from './Thumbnail';

class SupplierCarDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showCarUpdateButton: false,
            vin_number: '',
            chassis_number: ''
        }

        this.setField = this.setField.bind(this)

    }

    setField(field, e) {
        let state = this.state;
        state[field] = e.target.value;
        state.showCarUpdateButton = true;
        this.setState(state)
    }

    updateCarInfo()
    {
        const {vin_number, chassis_number} = this.state;
        if (vin_number.length < 3 || chassis_number.length < 3) {
            alert('Sorry! - cannot update car - incorrect vin and chassis number entered.');
            return;
        }

        this.props.updateCarVinCassisNumbers({
            car_id: this.props.car.id,
            vin_number,
            chassis_number
        }).then(resp => {
            alert('Thank you! - successfully updated car.');
            this.setState({showCarUpdateButton: false})
        }).catch(rej => {
            alert('Sorry - something went wrong. Please try again');
        })
    }

    render() {
        const { car } = this.props
        const {showCarUpdateButton} = this.state;
        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
      `;

        return (
            <div>
                {
                    car &&
                    <div className="row">
                        <div className="col-md-4">
                            {car.photo && <Thumbnail photo={car.photo} maxWidth="150px" />}
                        </div>
                        <div className="col-md-8">
                            <dl className="row">
                                <dt className="col-sm-3">Make & Model</dt>
                                <dd className="col-sm-9">{car.make} {car.model}</dd>

                                <dt className="col-sm-3">Variant</dt>
                                <dd className="col-sm-9">
                                    {car.variant}
                                </dd>

                                <dt className="col-sm-3">Year</dt>
                                <dd className="col-sm-9">{car.year}</dd>
                                
                                <dt className="col-sm-3"></dt>
                                <dd className="col-sm-9">
                                    <strong style={{color:"red", fontSize: "10px"}}>To help make quoting easier in future - Our Deal will greatly appreciate assistance with verifying the following two numbers. Please update accordingly if incorrect or not set.</strong></dd>

                                <dt className="col-sm-3 text-truncate">VIN</dt>
                                <dd className="col-sm-9">
                                    <input type="text" className="form-input" 
                                    defaultValue={car.vin_number} id="vin_number"
                                    onChange = {(e) => {this.setField('vin_number', e)}}
                                     />
</dd>
                                <dt className="col-sm-3">Chassis Number</dt>
                                <dd className="col-sm-9">

                                    <input type="text" className="form-input"
                                     defaultValue={car.chassis_number} id="chassis_number"
                                     onChange = {(e) => {this.setField('chassis_number', e)}}

                                      />
                                </dd>
                                <dt className="col-sm-3 text-truncate">Registration</dt>
                                <dd className="col-sm-9">{car.registration}</dd>
                                <dt className="col-sm-3">Mileage</dt>
                                <dd className="col-sm-9">{car.mileage}</dd>
                                <dt className="col-sm-3">Colour</dt>
                                <dd className="col-sm-9">{car.colour}</dd>

                                <dt className="col-sm-3 text-truncate">Fuel Type</dt>
                                <dd className="col-sm-9">{car.fuel_type}</dd>
                                <dt className="col-sm-3 text-truncate">Transmission</dt>
                                <dd className="col-sm-9">{car.transmission}</dd>


                            </dl>
                            {<ScaleLoader
                                size={5}
                                override={override}
                                color={"red"}
                                loading={this.props.loading}
                            />}

                            {showCarUpdateButton && <button className="btn btn-primary" onClick={this.updateCarInfo.bind(this)}>SAVE CHANGES</button>}
                        </div>

                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        requests: state.supplierDashboard.requests,
        loading: state.userDashboard.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCarVinCassisNumbers: (data) => updateCarVinCassisNumbers(data, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SupplierCarDetails);
