
import {getSessionUser} from './func';

let API_URL = document.getElementById('setting_api_url').value + 'api';
export const ADMIN_URL = document.getElementById('setting_admin_url').value;
export const API_ENDPOINT = API_URL;

export const ASSET_URL = '';
export const BACKEND_ASSET_URL = document.getElementById('setting_api_url').value;

function buildFormData(data){
  let formData = new FormData();
  const user = getSessionUser();

  if (user && user.hasOwnProperty('token')) {
    data = {...data, token: user.token, email_address: user.email}
  }

  for (let name in data) {
      let val = data[name];

      if (val === null || val == "null" || val == 'undefined' || val === undefined) {
        val = "";
      }

      formData.append(name, val)
  }

  return formData
}

/**
* Make API post
**/
export const postData = (endpoint, data, headers={}) => {
  const formData = buildFormData(data)
  return fetch(
    API_ENDPOINT + '/' + endpoint,
    {
      method: 'POST',
      body: formData,
      headers
    }
  );
}

export const postDataAdmin = (endpoint, data, headers={}) => {
  const formData = buildFormData(data)
  return fetch(
    API_ENDPOINT.replace(/\/api/g, '/admin') + '/' + endpoint,
    {
      method: 'POST',
      body: formData,
      headers
    }
  ).then(res => res.json())
  .then((res) => {
    if (typeof(res.errorCode) != 'undefined' && res.errorCode == 'authfailure') {
      localStorage.clear()
      window.showMessage(
        'Sorry - seems like your login session has expired. Please login again and try again',
        '/login'
      )
    }
    return res;
  })
}

export const postDataSupplier = (endpoint, data, headers={}) => {
  const formData = buildFormData(data)
  return fetch(
    API_ENDPOINT.replace(/\/api/g, '/supplier') + '/' + endpoint,
    {
      method: 'POST',
      body: formData,
      headers
    }
  ).then((res) => {

    res = res.json()
    if (res.hasOwnProperty('errorCode') && res.errorCode == 'authfailure') {
      localStorage.clear()
      alert(
        'Sorry - seems like your login session has expired. Please login again and try again'
      )
      window.location.href ='/login'
    }
    return res;
  })
}


