import {getSessionUser} from '../func';
import { SET_CARS, UPDATE_CAR_AT_INDEX, HIDE_DASHBOARD_LOADING, SHOW_DASHBOARD_LOADING,
     SET_CAR_PHOTO, SET_REQUESTS, SET_QUOTE_NUMBERS} from '../actions/userDashboard';
import _ from 'lodash';

const user = getSessionUser()

const intialState = {
    cars: [],
    requests:[],
    messages:[],
    quoteNumbers: [],
    user: user,
    loading: false
}

export function userDashboard(state = intialState, action) {
    switch(action.type) {
        case SET_CARS:
            return {...state, cars: action.cars}
        case SET_CAR_PHOTO:
            let carsList =  _.cloneDeep(state.cars)
            carsList[action.index].photo = action.photo
            return {...state, cars: carsList}
        case SHOW_DASHBOARD_LOADING:
            return {...state, loading: true}
        case HIDE_DASHBOARD_LOADING:
            return {...state, loading: false}
        case UPDATE_CAR_AT_INDEX:
            let cars = _.cloneDeep(state.cars)
            cars[action.carIndex] = { ...cars[action.carIndex], ...action.updates}
            return {...state, cars}
        case SET_REQUESTS:
            return {...state, requests: action.requests}
        case SET_QUOTE_NUMBERS:
            return {...state, quoteNumbers: action.quoteNumbers}
        default:
            return state
    }
}