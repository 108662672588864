import React from 'react';
import ContactForm from './components/contact';
import OdEdge from './components/odedge';
import Footer from './components/footer';
import Header from './components/header';
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import SiteRouter from './components/siteRouter';
import { getUrlAction } from './lib/func';

function render()
{
  const action = getUrlAction()

  return (
    <div className="site-wrap App">
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle" />
          </div>
        </div>
        <div className="site-mobile-menu-body" />
      </div>
      <div className="border-bottom top-bar py-2" id="home-section" style={{backgroundColor: '#E84C0A'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="mb-0" style={{color: '#fff', textAlign: 'center'}}>
                Search anything car related - Service | Repairs | Tyres | Batteries | Shock Absorbers | Windscreens | Exhausts and more...
              </p>
            </div>
          </div> 
        </div>
      </div>

      <Header />
      <Router history={createBrowserHistory()}>
      <div className="main-container">
        <Switch>
          <Route path="*" component={SiteRouter} />
        </Switch>
      </div>
    </Router>


      {action != 'contact' && <ContactForm />}

      <a href="#" className="bg-primary py-5 d-block">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md10"><h2 className="text-white">Find quotes easy, one search and we do the rest.

</h2></div>
          </div>
        </div>  
      </a>
      <Footer />
    </div>
  );
}

function App()
{
    return render()
  };

  export default App;