import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { loader, setMetaInfo, moveRequestForm } from '../../lib/actions/requestQuote';
import FileUploader from './../partials/FileUploader';

class MetaFormSell extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            otherWindscreen: false,
            showBadCreditQuestion: false
        }
        this.saveForm = this.saveForm.bind(this)
    }

    saveForm(e) {
        e.preventDefault();
        let flatData = {};
        let elements = $('#form').serializeArray()
        for (let i in elements) {
            flatData[elements[i].name] = elements[i].value;
        }
        this.props.setMetaInfo(flatData)
        this.props.moveRequestForm()
        return false;
    }

    upload(files) {
        console.log(files)
    }

    handleChange(e) {
        if(e.target.value == 'Yes') {
            this.setState({showBadCreditQuestion: true})
        }
    }
    handleDebtChange(e) {
        if (e.target.value == 'No') {
            window.showMessage('Thanks. Your details will not be shared. Please contact us if you have any other vehicle requirements.')
        }
    }
    render() {
        const {showBadCreditQuestion } = this.state;
        return (
            <div>
                <span style={{ fontWeight: "500" }}> Step 2/3:</span>
                <legend style={{ color: "#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding: "5px" }}>Finance Details</legend>
                <br />
                <form method="POST" id="form" onSubmit={this.saveForm}>
                    <div className="form-group">
                    <p> All finance is arranged via major banks. Do any of these 3 apply to you (1) "Blacklisted" (2) Poor payment profile or low credit score (3) On debt review (Then have a Yes/No option.)
                    </p>
                    <select name="blacklisted_or_poor_payment_profile_or_low_credit_score_or_under_debit_review" id="blacklisted_or_poor_payment_profile_or_low_credit_score_or_under_debit_review" className="metafield form-control" onChange={this.handleChange.bind(this)}>
                        <option value="No">Click to select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
        
                        </select>
                    </div>
                    {showBadCreditQuestion && <div className="form-group">
                    <p>Unfortunately we can't assist with normal bank finance. Would you like us to send your details to a Debt Counselling company that may be able to advise you?</p>
                    <select name="please_put_me_in_contact_with_a_debt_counseller" id="please_put_me_in_contact_with_a_debt_counseller" className="metafield form-control" onChange={this.handleDebtChange.bind(this)}>
                        <option value="No">Click to select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
        
                        </select>
                    </div>}
                    <div className="form-group">
                        <label>Type of finance needed</label>
                        <select name="type_of_finance" id="type_of_finance" className="metafield form-control">
                        <option value="Quickly">Click to select</option>
                        <option value="Dealer selling to private person">Dealer selling to private person</option>
                        <option value="Private to private sale">Private to private sale</option>
                        <option value="Refinance your own car">Refinance your own car</option>
        
                        </select>
                    </div>

                    <div className="form-group">
            <label>
             Please provide any additional information if applicable
            </label>
            <textarea id="additional_items_or_information" name="additional_items_or_information" className="form-input"></textarea>
            </div>

                    <div style={{ textAlign: "right" }}>
                        <br />
                        <button className="btn btn-primary">Next</button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
}


export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormSell);