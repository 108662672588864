import React, { Component } from "react";
import PropTypes from "prop-types";

import "../maps/style.css";

export default class AutoCompleteText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      text: "",
      locationId: ""
    };
  }

  static propTypes = {
    placeholder: PropTypes.string,
    address: PropTypes.string,
    textColor: PropTypes.string,
    onLocationChanged: PropTypes.func.isRequired
  };

  onTextChanged = e => {
    const value = e.target.value;
    this.setState({ locationId: "", text: value });
    const apiKey = "AojPe5IVhWIraZA8L2lm-OwMI_PsKe_lgbLmx_UI2twWU67DHWyA8PcaeLhADWAD";

    if (value.length > 0) {
      this.setState({ text: value });
      fetch(
        `https://dev.virtualearth.net/REST/v1/Autosuggest?maxResults=10&includeEntityTypes=Address&countryFilter=ZA&key=${apiKey}&query=${value}`
      )
        .then(response => response.json())
        .then(data => {
          console.log(data.resourceSets[0].resources[0])
          var op = data.resourceSets[0].resources[0].value.map(o => ({
            label: o.address.formattedAddress + ' ' + o.address.countryRegion,
            locationId: o.address.countryRegion
          }));

          this.setState({ suggestions: op });
        })
        .catch(err => {
          console.log(err);
          this.setState({ suggestions: [] });
        });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  componentDidMount() {
    if(this.props.address) {
      let {address} = this.props;
      this.setState({text: address, locationId: address})
    }
  }

  renderSuggestions = () => {
    const { suggestions } = this.state;
    let textColor = "#fff";
    if (this.props.textColor) {
      textColor = this.props.textColor;
    }

    if (suggestions.length === 0) {
      return null;
    } else {
      return (
        <ul style={{maxHeight: "150px", overflow: "auto", backgroundColour: "#fff"}}>
          {suggestions.map(item => {
            return (
              <li
              style={{backgroundColour: "#fff"}}
                key={item.label}
                locationid={item.label}
                onClick={() => this.suggestionSelected(item)}
              >
                <span style={{color: textColor}}>{item.label}</span>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  suggestionSelected = item => {
    this.setState({
      text: item.label,
      locationId: item.locationId,
      suggestions: []
    });

    this.props.onLocationChanged(item.label)
  };

  render() {
    const { text } = this.state;
    return (
      <div className="AutoCompleteText" {...this.props}>
        <input
          type="text"
          value={text}
          onChange={this.onTextChanged}
          placeholder={this.props.placeholder}
        />
        {this.renderSuggestions()}
      </div>
    );
  }
}