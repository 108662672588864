
import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import DatePicker from 'react-datepicker';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {postDataAdmin} from '../../lib/api';
import $ from 'jquery';
import ScaleLoader from "react-spinners/ScaleLoader";

import "react-datepicker/dist/react-datepicker.css";
import { getQuotePrice } from '../../lib/func';

export default class Booking extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            date1: new Date(),
            date2: '',
            date3: '',
            loading: false,
            paymentId: '',
            checksum: ''
        }

        this.setDate1 = this.setDate1.bind(this);
        this.setDate2 = this.setDate2.bind(this);
        this.setDate3 = this.setDate3.bind(this);
        this.book = this.book.bind(this);
    }

    setDate1(d) {

        this.setState({date1: d})
    }

    setDate2(d) {

        this.setState({date2: d})
    }

    setDate3(d) {

        this.setState({date3: d})
    }

    book() {
        this.setState({loading:true})
        const payload = {
            requestId: this.props.quote.request_id,
            id: this.props.quote.id,
            quoteNumber: this.props.quote.quote_number
        }
        postDataAdmin('acceptQuote', payload)
        .then(res => {
            this.setState({
                loading: false,
                checksum: res.args.CHECKSUM,
                paymentId: res.args.PAY_REQUEST_ID
            }, () => {$("#paymentform").submit()})
        })
        .catch(rej => {
            console.log(rej)
            alert('Sorry - something went wrong, please try again.')
            this.setState({loading: false})
        });
    }

    render() {
        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
      `;

        return (
            <Modal.Dialog>
            <Modal.Header>
            <Modal.Title>Accept Supplier Partner Quote</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <form id="paymentform" method="POST" action="https://secure.paygate.co.za/payweb3/process.trans">
            <input type="hidden" name="PAY_REQUEST_ID" value={this.state.paymentId} />
             <input type="hidden" name="CHECKSUM" value={this.state.checksum} />
            </form>
            
            <strong>Quote Number: </strong> {this.props.quote.quote_number} <br />
            <strong>Total Quoted Price incl VAT: </strong> {getQuotePrice(this.props.quote)} <br />
            <h5>Itemized Pricing</h5>
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Qty</th>
                                                <th>Price Incl VAT</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.quote.pricing.map(p => {
                                                let fullAmount = parseFloat(p.price) + parseFloat(p.admin_fee);

                                                return <tr>
                                                    <td>{p.description}</td>
                                                    <td>{p.qty}</td>
                                                    <td>R{parseFloat(fullAmount).toFixed(2)}</td>
                                                </tr>
                                            })}

                                            <tr>
                                                <td colspan="2">
                                                    <strong>Total Price Incl VAT</strong>
                                                </td>
                                                <td>
                                                    {getQuotePrice(this.props.quote)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

        </Modal.Body>

        <Modal.Footer>
            
            <Button variant="primary" onClick={this.book}>Confirm</Button>
            <Button variant="primary" onClick={this.props.closeForm}>Cancel</Button>
        </Modal.Footer>
    </Modal.Dialog>
        )
    }
}