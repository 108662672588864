import React from 'react';
import {connect} from 'react-redux';
import SelectSearch from 'react-select-search';
import $ from 'jquery';

import {
    loader,
    setCarServiceNeeded,
    moveRequestForm,
    findUserActionCreator,
    UserAuthActionCreator,
    createRequest,
    setCellphone,
    updateUserDetails
} from '../../lib/actions/requestQuote';
import '../../css/dropdown.css';

import {CAR_SERVICES} from './../../lib/func';

class SearchStepOne extends React.Component
{
    constructor(){
        super();
        this.state = {
            searchedForUser: false,
            email: null
        }
    }
    componentDidMount() {
        if (this.props.isLoggedIn && this.props.user) {
            this.setState({email: this.props.user.email})
            this.props.updateUserDetails(this.props.user.id)
        }
    }

    setService(service) {
       console.log(service) 
    }

    findUser(e){
        const re = /\S+@\S+\.\S+/;
        console.log(e.target.value)
        if (re.test(e.target.value)) {
            this.setState({searchedForUser: true, email: e.target.value})
            this.props.findUserActionCreator(e.target.value)
        }
    }

    createRequestAndgoToNextForm(email, description)
    {
        this.props.createRequest(email, description, this.props.service)
        this.props.hideLoader()
        let step = 2;
        console.log(this.props.service)
        if (this.props.service == 'Vehicle finance') {
            step = 3;
        }
        this.props.moveRequestForm(step)
    }

    next(){
        if (this.props.isLoggedIn && this.props.user) {
            this.setState({email: this.props.user.email})
        }

        let description = ""
        const {email} = this.state
        const {isLoggedIn}  = this.props;
        const {cellphone} = this.props;
 
        if (!isLoggedIn || !this.props.user) {
            if (!cellphone || cellphone.length < 10) {
                this.props.hideLoader();
                alert('Please enter a valid cellphone number');
                return;
            }
            if (email && email.length < 5 && !this.props.user) {
                this.props.hideLoader();
                alert(`Please enter an email address.`)
                return;
            } else if(!email) {
                this.props.hideLoader();
                alert(`Please enter an email address.`)
                return;
            }
        } else {
            this.props.setCellphone(this.props.user.cellphone)
        }
 
        if (!this.props.service){
            alert(`Please tell us what service you need help with?`);
            this.props.hideLoader();
            return;
        }
 
        this.props.showLoader();
        if (this.props.user && !isLoggedIn){
            if($('#password').val().length < 3) {
                 alert(`Please enter your account password or the OTP code sent to you via email.`);
                 this.props.hideLoader();
                 return;
            }
            this.props.UserAuthActionCreator(email, $('#password').val())
            .then(user => {
                if (user && user.hasOwnProperty('id')) {
                    this.createRequestAndgoToNextForm(email, description)
                } else {
                    alert('Sorry could not log you in. Please check your credentials and try again.');
                }
            })
        } else {
            this.createRequestAndgoToNextForm(email, description)
        }
 
        // Finally let the server store the quote request
    }

    render() {
        const {step, user, isLoggedIn} = this.props;
        if (window.location.host.match(/ourdeal.co.za/g)) {
            return <div>
                <h3>Coming soon...</h3>
            </div>
        }

        const services = CAR_SERVICES.map(s => {return {name: s, value: s, key: s}})
        return  <div>
                    {!isLoggedIn && user == null && <input type="email" id="email" className="form-input" placeholder="Your Email"
         onChange={this.findUser.bind(this)} />}
    
    {!isLoggedIn && <div>
                    <input 
                    type="number"
                    id="cellphone"
                    className="form-input"
                    placeholder="Your Cellphone"
                    value={this.props.cellphone}
                    onChange={(e) => this.props.setCellphone(e.target.value)}
                    /></div>
                }
                          {services && <SelectSearch 
                options={services} defaultValue="" 
                name="services"
                id="services"
                placeholder="Please select what you need help with?" 
                search={true}
                autoComplete="on"
                onChange={(service) => this.props.setCarServiceNeeded(service)}
                />}


                {!isLoggedIn  && user && <label>
                    Welcome back! <b>{user.name}</b>, please enter your account password
                    or click <a href="/reset-password" target="_blank">here</a> to reset your password.
                </label> }
                {!isLoggedIn && user && <input className="form-input" type="password" id="password" name="password" 
                placeholder="Please enter your password" />}
            <div style={{textAlign:"right"}}>
                <button className="btn btn-primary" onClick={this.next.bind(this)} disabled={this.props.loading}>NEXT</button>
            </div>
    </div>
    }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn,
        cellphone: state.requestQuote.cellphone
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setCarServiceNeeded: (service) => dispatch(setCarServiceNeeded(service)),
        moveRequestForm: () => dispatch(moveRequestForm(2)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
        updateUserDetails: (id) => updateUserDetails(id, dispatch),
        setCellphone: (cellphone) => dispatch(setCellphone(cellphone)),
        findUserActionCreator: (email) => findUserActionCreator(email, dispatch),
        UserAuthActionCreator: (email, password) => UserAuthActionCreator(email, password, dispatch),
        createRequest: (email, description, service) => createRequest(email, description, service, dispatch)
    }
}

  
  export default connect(mapStateToProps, mapDispatchTopProps)(SearchStepOne);