import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import { loader, setMetaInfo, moveRequestForm } from '../../lib/actions/requestQuote';
import FileUploader from './../partials/FileUploader';

class MetaFormLicense extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
        otherWindscreen: false
    }
    this.saveForm = this.saveForm.bind(this)
  }



   saveForm(e) {
       e.preventDefault();
    let flatData = {};
    let elements = $('#form').serializeArray()
    for(let i in elements) {
        flatData[elements[i].name] = elements[i].value;
    }
    this.props.setMetaInfo(flatData)
    this.props.moveRequestForm()
    return false;
   }

   upload(files) {
       console.log(files)
   }
  
  render() {
      const {otherWindscreen} = this.state;
      return (
        <div>
          <span style={{fontWeight:"500"}}> Step 3/4:</span>
          <legend style={{color:"#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding:"5px"}}>License and Fine Details</legend>
          <br />
        <form method="POST" id="form" onSubmit={this.saveForm}>
        <div className="form-group">
                <label>Select the item you need assistance with?</label>
               <select name="what_do_you_need_assistance_with" id="what_do_you_need_assistance_with"
                 className="metafield form-control" onClick={this.selectProblem}>
                <option value="Not sure" selected>Click to select</option>
                        <option value="Renew annual license">Renew annual license</option>
                        <option value="Transfer vehicle ownership">Transfer vehicle ownership</option>
                        <option value="Lost registration documents">Lost registration documents</option>
                        <option value="Data dot services">Data dot services</option>
                        <option value="Police clearance">Police clearance</option>
                        <option value="Import or export of vehicle">Import or export of vehicle</option>
                        <option value="Number plates">Number plates</option>
                        <option value="Other assistance">Other assistance</option>

                </select>

            </div>
            <div className="form-group">
            <label>
            Please provide the address your vehicle is registered at? 
            </label>
            <textarea id="vehicle_registration_address" name="vehicle_registration_address" className="form-input"></textarea>
            </div>
            <div className="form-group">
            <label>
            If possible, please send the registration or license documents to assist us
            </label>
            <FileUploader />
            </div>

            <div className="form-group">
            <label>
                If possible, load pictures or a video of the items you need help with
            </label>
            <FileUploader />
            </div>

            <div className="form-group">
            <label>
             Please provide any additional information or anything else you want checked/repaired?
            </label>
            <textarea id="additional_items_or_information" name="additional_items_or_information" className="form-input"></textarea>
            </div>
            <div style={{ textAlign: "right" }}>
                  <br />
                    <button className="btn btn-primary">Next</button>
            </div>
            </form>
            </div>
      );
  }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
}

  
  export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormLicense);