import {setSessionUser, getSessionUser} from '../func';
import _ from 'lodash';

import {
    GET_MAKES,
    GET_MODELS,
    GET_VARIANTS,
    SET_CAR_MMCODE,
    SET_CAR_SERVICE_NEEDED,
    MOVE_REQUEST_FORM,
    SHOW_LOADING,
    HIDE_LOADING,
    SET_USER,
    AUTH_USER,
    SET_REQUEST_NUMBER,
    SET_LAT_LNG,
    SET_ADDRESS,
    SET_FUEL_TYPE,
    SET_TRANSMISSION_TYPE,
    SET_COLOUR,
    SET_YEAR,
    SET_MILEAGE,
    SET_CAR_ID,
    SET_EMAIL_ADDRESS,
    SET_CELLPHONE,
    SET_NAME,
    CLOSE_REQUEST,
    CLEAR_AUTH_USER,
    SET_SERVICE_FIELDS,
    SET_MAKE,
    SET_MODEL,
    SET_USER_DETAILS,
} from '../actions/requestQuote';

const user = getSessionUser()

const intialState = {
    makes: [],
    models: [],
    variants: [],
    selectedMmcode: null,
    service: null,
    car_id: null,
    step: 1,
    loading: false,
    user: user,
    searchedForUser: null,
    isLoggedIn: user && user.hasOwnProperty('token') ? true : false,
    request_number: 'R2020102453142',
    address: null,
    lat: null,
    lng: null,
    fuel_type: "Petrol",
    transmission: "Manual",
    colour: "",
    year: 2020,
    mileage: 0,
    email_address: null,
    cellphone: null,
    name: null,
    service_fields: {},
    make: '',
    model : ''
}

export function requestQuote(state = intialState, action) {
    switch(action.type) {
        case GET_MAKES:
            return {...state, makes: action.makes}
        case GET_MODELS:
            return {...state, models: action.models}
        case GET_VARIANTS:
            return {...state, variants: action.variants}
        case SET_CAR_MMCODE:
            return {...state, selectedMmcode: action.mmcode}
        case SET_CAR_SERVICE_NEEDED:
            return {...state, service: action.service}
        case MOVE_REQUEST_FORM:
            return {...state, step: action.step}
        case SHOW_LOADING:
            return {...state, loading: true}
        case HIDE_LOADING:
            return {...state, loading: false}
        case SET_USER:
            return {...state, user: action.user}
        case AUTH_USER:
            setSessionUser(action.user)
            return {...state, user: action.user, isLoggedIn: true}
        case SET_REQUEST_NUMBER:
            return {...state, request_number: action.request_number}
        case SET_LAT_LNG:
            return {...state, lat: action.lat , lng: action.lng}
        case SET_ADDRESS:
            return {...state, address: action.address}
        case SET_FUEL_TYPE:
            return {...state, fuel_type: action.fuel_type}
        case SET_TRANSMISSION_TYPE:
            return {...state, transmission: action.transmission}
        case SET_COLOUR:
            return {...state, colour: action.colour}
        case SET_YEAR:
            return {...state, year: action.year}
        case SET_MILEAGE:
            return {...state, mileage: action.mileage}
        case SET_CAR_ID:
            return {...state, car_id: action.car_id}
        case SET_EMAIL_ADDRESS:
            return {...state, email_address: action.email_address}
        case SET_CELLPHONE:
            return {...state, cellphone: action.cellphone}
        case SET_NAME:
            return {...state, name: action.name}
        case SET_MAKE:
            return {...state, make: action.make}
        case SET_MODEL:
            return {...state, model: action.model}
        case SET_USER_DETAILS:
            let oldUser = _.cloneDeep(state)
            oldUser.user = {...oldUser.user , ...action.details}
            return oldUser
        case CLOSE_REQUEST:
            let oldstate = _.cloneDeep(state)
            return {
                ...oldstate,
                selectedMmcode: null,
                service: null,
                car_id: null,
                step: 1,
                loading: false,
                searchedForUser: null,
                request_number: null,
                address: null,
                lat: null,
                lng: null,
                fuel_type: "Petrol",
                transmission: "Manual",
                colour: "",
                email_address: null,
                cellphone: null
            }
        case CLEAR_AUTH_USER:
            return {...state, user: null, isLoggedIn: false}
        case SET_SERVICE_FIELDS:
            return {...state, service_fields: action.service_fields}
        default:
            return state

    }
}