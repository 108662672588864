import React from 'react';
import { connect } from 'react-redux';
import {FUEL_TYPES} from '../../../lib/func';
import {
     loader,
     getModelActionCreator,
     getVariantActionCreator,
     setCarMmcode,
     moveRequestForm,
     setFuelType,
     setTransmission,
     setColour,
     setYear,
     setMileage,
     setCarId,
     saveCar
    } from '../../../lib/actions/requestQuote'
import SelectSearch from 'react-select-search';

import '../../../css/dropdown.css';
import '../../../css/addcaradmin.css';

class AddCarDashboard extends React.Component {

    constructor() {
        super()
        this.state = {
            make: null,
            model: null,
            mmcode: null,
            addNewCar: false,
            car_id: null,
            year: 2020,
            mileage: 0
        }
    }

    changeMake(make) {
        this.setState({ make })
        this.props.getModelActionCreator(make)
    }

    changeModel(model) {
        this.setState({ model })
        this.props.getVariantActionCreator(this.state.make, model, this.state.year);
    }

    changeVariant(mmcode) {
        this.setState({ mmcode })
        this.props.setCarMmcode(mmcode)
    }

    setCar(car_id) {
        this.setState({car_id})
        this.props.setCarId(car_id)
    }
    
    setYear(e) {
        let year = e.target.value
        this.setState({year})
        this.props.setYear(year)
    }

    setMileage(e) {
        let mileage = e.target.value;
        this.setState({mileage})
        this.props.setMileage(mileage)
    }

    next() {
        const {make, model, mmcode } = this.state;

        if (this.state.car_id === null) {
            if (make === null) {
                alert("Please tell us the make of your car?");
                return;
            }
    
            if (model === null) {
                alert("Please tell us the model of your car");
                return;
            }
    
            if (mmcode === null) {
                alert("Please tell us the variant of your car?");
                return;
            }
        }

        this.props.saveCar({
            mmcode: this.props.selectedMmcode,
            selectedMmcode: this.props.selectedMmcode,
            fuel_type: this.props.fuel_type,
            transmission: this.props.transmission,
            colour: this.props.colour,
            year:this.props.year,
            mileage: this.props.mileage,
        }).then(resp => {
            console.log(resp)
            if (resp.status != undefined) {
                this.setState({
                    make: null,
                    model: null,
                    mmcode: null,
                    addNewCar: false,
                    car_id: null,
                    year: 2020,
                    mileage: 0
                })
                window.showMessage('Successfully added car. You can capture another car here or click on "Cars" in the sidebar to manage existing cars.', '/dashboard/cars');
        
                return;
            } else {
                window.showMessage('Failed to add this car. Please try again.');
                return;
            }
        })

    }
    render() {
        const { user, selectedMmcode, makes, models, variants, loading } = this.props;
        let cars = []
        if (user && user.hasOwnProperty('cars')) {
            cars = user.cars;
        }

        return <div className="row">
            <div className="col-lg-12 px-5">
                <div className="newCar">
                            <label>Year</label>
                            <input 
                            className="form-input"
                            type="number"
                            name="year"
                            placeholder = "Please enter year of your car"
                            onChange={this.setYear.bind(this)}
                            />

                            <label>Make</label>
                            {makes && <SelectSearch
                                options={makes.map(m => { return { name: m.make, value: m.make, key: m.mmcode } })} defaultValue=""
                                name="make"
                                id="make"
                                placeholder="Choose car make"
                                search={true}
                                autoComplete="on"
                                onChange={this.changeMake.bind(this)}
                            />}
                            <label>Model</label>

                            {models && <SelectSearch
                                options={models.map(m => { return { name: m.model, value: m.model, key: m.model } })} defaultValue=""
                                name="model"
                                id="model"
                                placeholder="Choose car model"
                                search={true}
                                autoComplete="on"
                                onChange={this.changeModel.bind(this)}
                            />}

                            <label>Model Variant</label>

                            {variants && <SelectSearch
                                options={variants.map(v => { return { name: `${v.variant}`, value: v.mmcode, key: v.mmcode } })} defaultValue=""
                                name="variant"
                                id="variant"
                                placeholder="Choose car model variant"
                                search={true}
                                autoComplete="on"
                                onChange={this.changeVariant.bind(this)}
                            />}

                                <label>Fuel Type</label>
                                <SelectSearch
                                options={FUEL_TYPES} defaultValue=""
                                name="fuel_type"
                                id="fuel_type"
                                placeholder="Choose fuel type"
                                search={true}
                                autoComplete="on"
                                onChange={this.props.setFuelType}
                            />


                            <label>Transmission</label>
                                <SelectSearch
                                options={
                                    [
                                        {name: 'Manual', value: 'Manual', key: 'Manual'},
                                        {name: 'Automatic', value: 'Automatic', key: 'Automatic'},

                                    ]} defaultValue=""
                                name="transmission"
                                id="transmission"
                                placeholder="Choose transmission"
                                search={true}
                                autoComplete="on"
                                onChange={this.props.setTransmission}
                            />

                            <label>Colour</label>
                            <input 
                            className="form-input"
                            type="text"
                            name="colour"
                            placeholder = "Please enter the colour of your car"
                            onChange={(e) => {this.props.setColour(e.target.value)}}
                            />

                            <label>Mileage</label>
                            <input 
                            className="form-input"
                            type="number"
                            name="mileage"
                            placeholder = "Please enter your cars approximate mileage"
                            onChange={this.setMileage.bind(this)}
                            />

                            <div style={{ textAlign: "right" }}>
                                <button className="btn btn-primary" onClick={this.next.bind(this)} disabled={loading}>SAVE</button>
                            </div>

                        </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        makes: state.requestQuote.makes,
        models: state.requestQuote.models,
        variants: state.requestQuote.variants,
        selectedMmcode: state.requestQuote.selectedMmcode,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        fuel_type: state.requestQuote.fuel_type,
        transmission: state.requestQuote.transmission,
        colour: state.requestQuote.colour,
        year: state.requestQuote.year,
        mileage: state.requestQuote.mileage,
    }
}

const mapDispatchTopProps = (dispatch) => {
    return {
        getModelActionCreator: (make) => getModelActionCreator(make, dispatch),
        getVariantActionCreator: (make, model, year) => getVariantActionCreator(
            make, model, year, dispatch),
        setCarMmcode: (mmcode) => dispatch(setCarMmcode(mmcode)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        setFuelType : (fuel) => dispatch(setFuelType(fuel)),
        setTransmission: (transmission) => dispatch(setTransmission(transmission)),
        setColour: (colour) => dispatch(setColour(colour)),
        setYear: (year) => dispatch(setYear(year)),
        setCarId: (car_id) => dispatch(setCarId(car_id)),
        setMileage: (mileage) => dispatch(setMileage(mileage)),
        saveCar: (car) => saveCar(car, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchTopProps)(AddCarDashboard);