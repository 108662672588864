import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { loader, setMetaInfo, moveRequestForm } from '../../lib/actions/requestQuote';
import FileUploader from './../partials/FileUploader';

class MetaFormAccident extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            otherWindscreen: false
        }
        this.saveForm = this.saveForm.bind(this)
    }

    saveForm(e) {
        e.preventDefault();
        let flatData = {};
        let elements = $('#form').serializeArray()
        for (let i in elements) {
            flatData[elements[i].name] = elements[i].value;
        }
        this.props.setMetaInfo(flatData)
        this.props.moveRequestForm()
        return false;
    }

    upload(files) {
        console.log(files)
    }

    insured(e) {
        if ($("#vehicle_insured").val() == "Yes") {
            alert("Please note that you may require authorization from your insurance company or vehicle manufacturer in order to comply with their requirements");
          }
    }

    render() {
        const { otherWindscreen } = this.state;
        return (
            <div>
                <span style={{ fontWeight: "500" }}> Step 3/4:</span>
                <legend style={{ color: "#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding: "5px" }}>Acident Details</legend>
                <br />
                <form method="POST" id="form" onSubmit={this.saveForm}>
                <div className="form-group">
            <label>
            Is your vehicle insured?
            </label>
            <select
              name="vehicle_insured"
              className="form-control"
              id="vehicle_insured"
              onChange={this.insured.bind(this)}
            >
              <option value="">--- click to select ---</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select></div>

            <div className="form-group">
                        <label>
                            Please describe the damage you want repaired.
                        </label>
                        <textarea id="description" name="description" className="form-input"></textarea>
                    </div>
                    <div className="form-group">
                        <label>
                            If possible, load pictures or a video of the items you need help with
                        </label>
                        <FileUploader />
                    </div>
                    <div className="form-group">
                        <label>
                            Please provide any additional information or anything else you want checked/repaired?
                        </label>
                        <textarea id="additional_items_or_information" name="additional_items_or_information" className="form-input"></textarea>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <br />
                        <button className="btn btn-primary">Next</button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
}


export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormAccident);