
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

export default class AppCaptcha extends React.Component {
    render() {
        return (
            <div style={{padding: "10px"}}>
                <ReCAPTCHA
                    sitekey="6LcdMfgUAAAAAOnHzxdkT7z9LK_rp7MWHOZ4ag-A"
                    render="explicit"
                    onChange={this.props.verifyCallback}
                    />

            </div>
        )
    }
}