import React from 'react';

import "../../css/search.css";
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

export default class Faqs extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      quoteNumber: '',
      message: '',
      captcha: false
    }
  }


  render() {
    const faqs = [
        {
            name: "How many of my cars can I have on one membership account", 
            copy:  <p><b>Our Deal</b>, want to add as much value as possible to our <b>Members & Supplier Partners</b>. With this in mind we allow you to have an unlimited number of vehicles on one membership account. </p>  
        },
        
        {
            name: "Can I have my business and private vehicles on the same membership account?",
            copy: <p>We encourage <b>Members</b> to register a separate account for business and personal vehicles. This helps us market and serve you accordingly. </p>
        },
        
        {
            name: "Are there any additional costs?",
            copy: <p>No hidden costs. Whatever price you were quoted, on the system is the final price you pay for the job. All prices include VAT. If your vehicle needs additional work, then a separate request must be loaded on the system. </p>
        },

        {name:"How do I pay for work done?", copy: <p>In order to ensure an easy and fully protected transaction, <b>Our Deal</b> will handle the payments, not the Supplier Partner doing the job. So you never have to carry cash or worry about providing proof of payment to the supplier. There are 2 quick and safe ways to pay for the job. (1) Use our credit card payment system or (2) Use the instant EFT system. Both operate via internationally recognised service providers. </p>},

        {name:"Can I cancel an order and request a refund?", copy: <p>You may cancel an order if its done early enough and if the supplier has not started the work or experienced any costs. An administration fee of 7.5% of the full price, plus banking charges will be levied. The balance will be returned to the account you used when making payment. </p>},

        {
            name: "How are Our Deal Supplier Partners chosen?",
            copy: <p>Our Deal supports the growth of high quality, ethical, <b>Supplier Partners</b>. Hence we encourage our Members to only use <strong>Our Deal</strong> approved suppliers. <strong>Our Deal</strong> Supplier Partners must meet our requirements that are designed to ensure a high quality of work and customer service. They also agree to abide by the <b><strong>Our Deal</strong></b> Supplier Partner Code of Conduct. <b><strong>Our Deal</strong></b> reserve the right to terminate the relationship with any <b>Supplier Partner</b> who does not comply with the code of conduct. </p>
        },

        {
            name: "How do I choose which supplier to use?",
            copy: <p>In order to be fair and transparent, <b>Our Deal</b> does not dictate to <b>Members</b> which <b>Supplier Partner</b> to use. We simply provide all the relevant information and allow the members free choice. </p>
        },

        {
            name: "How do I send a compliment or complaint",
            copy: <p>We understand that sometimes things don't go according to plan. We appreciate feedback from both <b>Members & Supplier Partners</b> because it allows us to continuously improve our service levels.  We also would like to know when everything runs well. So please send us your compliments or complaints to <a href="mailto:admin@ourdeal.co.za">admin@ourdeal.co.za</a>. </p>
        },
        {
            name: "What if I need additional work done whilst my car is at the supplier?",
            copy: <p>Additional work is often noticed whilst your car is at a supplier partner. Simply log onto the system and request an additional quote. Process payment and we will authorise the supplier partner to go ahead. Members are advised not to negotiate this additional work directly with the supplier partner as you will not get our great prices or the multiple quotes. You also won't enjoy the protection offered by <b>Our Deal</b>. </p>
        },
        {
            name: "Are my details secure and confidential?",
            copy: <p>Your information provided is protected by the latest encryption technology. <b>Our Deal</b> will never share your contact details with third parties unless its to assist you with your vehicle management. </p>
        },
        {
            name: "How are payments protected?",
            copy: <p>None of your banking information is stored on the website because <b>Our Deal</b> have partnered with reputable, internationally recognised suppliers to process all payments. For your protection please note: <b>(1)</b> Our Deal will never ask you for your bank PIN number <b>(2)</b> Our Deal will never ask you to pay a supplier directly <b>(3)</b> We do not accept cash payments. </p>
        },
        {
            name: "Can I ask Our Deal for general advice on my vehicles?",
            copy: <p>Members are welcome to send us questions relating to their vehicles and we will ask our vast network of suppliers to assist with advice. It's one of the awesome ways that <b>Our Deal</b> adds value to our members.  </p>
        },
        {
            name:"Can Our Deal advise me on purchasing a vehicle?",
            copy: <p>We understand that a vehicle is a major and often stressful purchase. Hence <b>Our Deal</b> will assist in any way possible to make it easier. Send us your vehicle purchase questions or quotations and we will ask our network of <b>Supplier Partners</b> to get you the best deal and advice. It's another awesome way that Our Deal adds value to our members. </p>
        },
        {
            name: "Do you have a suggestion?",
            copy: <p>We value your input. If you have an idea or a feature that will help us add more value to Members or Supplier Partners, then please send us your input <a href="/contact">Here</a>. </p>
        },
        {
            name: "Do you want to report a bug in the system?",
            copy: <p>We appreciate your sharp eye. If you want to report a bug you have spotted on our system then please send us your feedback <a href="/contact">Here</a>. </p>
        }
    ]
 
    return (
        <div className="site-section">
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12" style={{color:"#000"}}>
                    <h3>Frequently asked questions</h3> 
                    <p><i>
                    Do you have a question for us? Browse through our FAQ's, If you have other questions, then please ask <a href="/contact">Here</a>. 
                    </i>
                    </p>
                    <br />
                    <Accordion defaultActiveKey="0">
                    {faqs.map((q, i) => {
                           return  <Card>
                            <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={i} style={{color:"#000"}}>
                               {q.name}
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={i}>
                            <Card.Body>{q.copy}</Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    })}
                    </Accordion>
                </div>
            </div>
        </div>
        </div>
    )
  }
}
