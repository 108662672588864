import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom'
import Homepage from './homepage';
import Dashboard from './admin/Dashboard';
import ContactForm from './contact';

export default class SiteRouter extends Component {

  render() {

    return (
        <div>
            <Switch>
              <Route path="/supplier/dashboard" component={Dashboard} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/contact" component={ContactForm} />
              <Route path="*" component={Homepage} />
            </Switch>
        </div>
    )
  };
};