import React from 'react';
import { postDataAdmin } from '../../../lib/api';
import ScaleLoader from "react-spinners/ScaleLoader";
import AutoCompleteText from "../../maps";
import Thumbnail from '../../partials/Thumbnail';
import ImageUploader from "react-images-upload";
  
export default class ProfileDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            loading: false
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        postDataAdmin('userProfile', {})
            .then(res => {
                console.log(res)
                this.setState({ user: res })
                this.setState({ loading: false })
            })
    }

    setProperty(val, name) {
        let { user } = this.state;
        user[name] = val;
        this.setState({user})
    }
    handleChange = address => {
        let {user} = this.state
        user.location = address
        this.setState({ user });
      };
     
      finish() {
        const {user} = this.state;
        this.setState({loading: true})
        postDataAdmin('updateProfile', {
            name: user.name,
            cellphone: user.cellphone,
            landline: user.landline,
            lat: user.lat,
            lng: user.lng,
            location: user.location,
            alternative_contact_number: user.alternative_contact_number,
            postal_address: user.postal_address
        })
        .then(res => {
            this.setState({loading: false})
            if (res.hasOwnProperty('error')) {
                window.showMessage(res.error);
                return;
            } else {
                window.showMessage('Thank you, profile successfully updated.', '/dashboard');
                return;
            }
        })

      }

      onDrop(pictureFiles, pictureDataURLs) {
        this.setState({ loading: true })
        postDataAdmin('userPhoto', {'photo': pictureFiles[0]})
        .then(res => {
            if (res.hasOwnProperty('photo')) {
                let {user} = this.state
                user.photo = res.photo
                this.setState({ loading: false, user })
            }
        })
      }
    
    render() {
        const { user } = this.state;
        let location = '';
        if (user && user.location) {
            location = user.location;
        }
        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
      `;


        return (

            <div className="bg-white" style={{ padding: "10px" }}>
                <div style={{ textAlign: "center" }}>
                    {<ScaleLoader
                        size={5}
                        override={override}
                        color={"red"}
                        loading={this.state.loading}
                    />}
                </div>

                {this.state.user && <div>
                    <div className="row">
                        <div className="col-md-7">
                    <div className="form-group">
                        <label>Your Name</label>
                        <input className="form-input" type="text" name="name" value={user.name}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'name')
                            }} />
                    </div>

                    <div className="form-group">
                        <label>Your Cellphone Number</label>
                        <input className="form-input" type="text" name="cellphone" value={user.cellphone}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'cellphone')
                            }} />
                    </div>


                    <div className="form-group">
                        <label>Alternative Contact Number</label>
                        <input className="form-input" type="text" name="alternative_contact_number" value={user.alternative_contact_number}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'alternative_contact_number')
                            }} />
                    </div>


                    <div className="form-group">
                        <label>Landline Number</label>
                        <input className="form-input" type="text" name="landline" value={user.landline}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'landline')
                            }} />
                    </div>

                    <div className="form-group">
                        <label>Physical Address</label>
                          <AutoCompleteText  placeholder="Pysical Address" 
                          onLocationChanged={this.handleChange.bind(this)}
                          textColor="#000"
                          address={location}
                          />
                    </div>
                    <div className="form-group">
                        <label>Postal Address</label>
                        <input className="form-input" type="text" name="postal_address" value={user.postal_address}
                            onChange={(e) => {
                                this.setProperty(e.target.value, 'postal_address')
                            }} />
                    </div>

                    </div>
    
                    <div className="col-md-5">
                        {user.photo && <div style={{marginLeft:"70px", paddingTop: "10px"}}>
                        <Thumbnail photo={user.photo} maxWidth="200px" /></div>}
                        <ImageUploader
                                withIcon={true}
                                buttonText="Set / Replace profile photo"
                                onChange={this.onDrop.bind(this)}
                                withPreview={false}
                                singleImage={true}
                                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                                maxFileSize={5242880}
                            />
                    </div>
                    </div>
                    <button className="btn btn-primary" onClick={this.finish.bind(this)} disabled={this.state.loading}>UPDATE PROFILE</button>
                   
                </div>}
            </div>

        );
    }
}