import React from 'react';
import {connect} from 'react-redux';
import { UserAuthActionCreator, loader } from '../lib/actions/requestQuote';
import ScaleLoader from "react-spinners/ScaleLoader";
import "../css/search.css";
import $ from 'jquery';

class LoginForm extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
      isLogin: true,
      isRegister: false
    }
  }

  finish()
  {
    const email = $('#email').val()
    const password = $('#password').val()
    if (email.length > 3 && password.length >= 3) {
      this.props.showLoader()
      this.props.UserAuthActionCreator(email, password)
      .then(user => {
          if (!user) {
            alert('Login failed', 'Sorry the login details entered does not match any user in our system.');
            return;
          }

          if (sessionStorage.getItem('ref_url')) {
            let ref_url = sessionStorage.getItem('ref_url')
            sessionStorage.removeItem('ref_url')
            window.location.href = ref_url
          }
      }
      )
    } else {
      alert('Sorry! - please enter your email and password to login.')
    }
  }

  componentDidMount()
  {
    this.setState({isLogin: this.props.isLogin, isRegister: this.props.isRegister})
  }

  render() {
    const { loading, isLoggedIn} = this.props;
    const {isRegister, isLogin} = this.state;
    const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
 
    if (isLoggedIn) {
      window.location.href = '/dashboard';
    }

    return (
      <div className="site-section" style={{backgroundImage: 'url(/atrader/images/hero_1.jpg)', backgroundSize: 'cover', minHeight: '500px'}}>
      <div className="container">
        <div className="row mt-5"> 
          <div className="col-md-5">
            <div className="SearchForm">
              <div style={{padding:"5px"}}><h5>{isLogin && "Login"} {isRegister && "Register"} </h5></div>

              {
              isLogin &&<div>
                <label>Email</label>
                <input id="email" className="form-input" type="email" name="email" placeholder="Enter Email" />
                <br />
                <label>Password</label>
                <input id="password" className="form-input"  type="password" name="password" placeholder="Enter Password" />
          
                
                </div>
              }
<br />
                <div style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" onClick={this.finish.bind(this)} disabled={loading}>LOGIN</button>
                    <span style={{padding:"2px"}}></span>
                    <button className="btn btn-secondary" onClick={() => {window.location.href = '/reset-password'}}>FORGOT PASSWORD?</button>
                </div>
           

              <div style={{textAlign:"center"}}>
                <ScaleLoader
                  size={5}
                  override={override}
                  color={"#fff"}
                  loading={loading}
                />
              </div>
            </div>

          </div>
          <div className="col-md-7">
              <div style={{color: '#fff',Width: "150px", marginTop: "50px"}}>
                <h1>Quick &amp; easy quote finder for  <br />anything car related.</h1>
              </div>
            </div>
        </div>
      </div>
    </div> 
  )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.requestQuote.loading,
    isLoggedIn: state.requestQuote.isLoggedIn
  }
}

const mapDispatchTopProps = (dispatch) => {
    return {
      showLoader: () => dispatch(loader(true)),
      hideLoader: () => dispatch(loader(false)),
      UserAuthActionCreator: (email, password) => UserAuthActionCreator(email, password, dispatch),

    }
}

export default connect(mapStateToProps, mapDispatchTopProps)(LoginForm);