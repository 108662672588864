import {API_ENDPOINT, postData} from '../api'

// action types
export const GET_MAKES = 'GET_MAKES';
export const GET_MODELS = 'GET_MODELS';
export const GET_VARIANTS = 'GET_VARIANTS';
export const SET_CAR_MMCODE = 'SET_CAR_MMCODE';
export const SET_CAR_SERVICE_NEEDED = 'SET_CAR_SERVICE_NEEDED';
export const MOVE_REQUEST_FORM = 'MOVE_REQUEST_FORM';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SET_USER = 'SET_USER';
export const AUTH_USER = 'AUTH_USER';
export const CLEAR_AUTH_USER = 'AUTH_USER';
export const SET_REQUEST_NUMBER = 'SET_REQUEST_NUMBER';
export const SET_LAT_LNG = 'SET_LAT_LNG';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_FUEL_TYPE = 'SET_FUEL_TYPE';
export const SET_TRANSMISSION_TYPE = 'SET_TRANSMISSION_TYPE';
export const SET_COLOUR = 'SET_COLOUR';
export const SET_YEAR = 'SET_YEAR';
export const SET_MILEAGE = 'SET_MILEAGE';
export const SET_CAR_ID = 'SET_CAR_ID';
export const SET_EMAIL_ADDRESS = 'SET_EMAIL_ADDRESS';
export const SET_CELLPHONE = 'SET_CELLPHONE';
export const SET_NAME = 'SET_NAME';
export const CLOSE_REQUEST = 'CLOSE_REQUEST';
export const SET_SERVICE_FIELDS = 'SET_SERVICE_FIELDS';
export const SET_MAKE = 'SET_MAKE';
export const SET_MODEL = 'SET_MODEL';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';

/**
 * Fetchs car makes from server and dispatches
 * action to update reducer
 */
export function getMakesActionCreator(dispatch) {

    fetch(API_ENDPOINT + "/" + "makes")
    .then(res => res.json())
    .then(makes => {
            dispatch(getMakesAction(makes))
        })
}


function getMakesAction(makes)
{
    return {
        type: GET_MAKES,
        makes: makes
    }
}

export function saveCar(data, dispatch) {
    return postData('saveCar', data)
    .then(res => res.json())
    .then(res => {
        if (res.status != undefined) { 
            dispatch(closeRequest())
        }
      return res
    })
}

/**
 * Fetchs car models from server and dispatches
 * action to update reducer
 */
export function getModelActionCreator(make, dispatch) {

    fetch(API_ENDPOINT + "/" + "models/?make=" + make)
    .then(res => res.json())
    .then(models => {
            dispatch(getModelAction(models))
        })
}


export function updateUserDetails(id, dispatch) {

    fetch(API_ENDPOINT + "/" + "getUserDetails/" + id)
    .then(res => res.json())
    .then(res => {
            dispatch(setUserDetails(res))
        })
}

function setUserDetails(details) {
    return {
        type: SET_USER_DETAILS,
        details: details
    }
}

function getModelAction(models)
{
    return {
        type: GET_MODELS,
        models: models
    }
}

/**
 * Fetchs car models from server and dispatches
 * action to update reducer
 */
export function getVariantActionCreator(make, model, year, dispatch) {

    fetch(API_ENDPOINT + "/" + "variants/?make=" + make + "&model=" + model +"&year=" +year)
    .then(res => res.json())
    .then(variants => {
            dispatch(getVariantAction(variants))
        })
}


function getVariantAction(variants)
{
    return {
        type: GET_VARIANTS,
        variants: variants
    }
}

export function setMetaInfo(service_fields) {
    return {
        type: SET_SERVICE_FIELDS,
        service_fields
    }
}
export function setCarMmcode(mmcode)
{
    return {
        type: SET_CAR_MMCODE,
        mmcode: mmcode
    }
}

export function setCarServiceNeeded(service)
{
    return {
        type: SET_CAR_SERVICE_NEEDED,
        service: service
    }
}

export function moveRequestForm(step)
{
    window.scrollTo(0, 0); 

    return {
        type: MOVE_REQUEST_FORM,
        step: step
    }
}

export function loader(show = true)
{
    console.log("Called", show)
    if (show) {
        return {
            type: SHOW_LOADING
        }
    } else {
        return {
            type: HIDE_LOADING
        }
    }
}

/**
 * Dispatches query to find user matching email
 */
export function findUserActionCreator(email, dispatch)
{
    dispatch(loader(true))
    dispatch(setEmailAddress(email))
    fetch(API_ENDPOINT + "/" + "searchUser/?email=" + email)
    .then(user => user.json())
    .then(data => {
        if (data.user) {
            dispatch(setUser(data.user))
            console.log(data.user)
            dispatch(setCellphone(data.user.cellphone))
        } {
            console.log("No user :-(")
        }
        dispatch(loader(false))
    }).catch(err => {
        dispatch(loader(false))
    })
}

function setUser(user) {
    return {
        type: SET_USER,
        user: user
    }
}

/**
 * Complete user login
 */
export function UserAuthActionCreator(email, password, dispatch)
{
    return postData('auth', {
        email,
        password
    }).then(res => res.json())
    .then((user) => {
        if (user.user !== undefined && user.user.email !== undefined) {
            dispatch(LoginUser(user.user))
        } else {
            dispatch(loader(false))
        }

        return user.user;
    })
}

function LoginUser(user) {
    return {
        type: AUTH_USER,
        user
    }
}

/**
 * Create a quote for the user
 */

 export function createRequest(email, description, service, dispatch)
 {
    postData('saveRequest', {
        email,
        description,
        service
    }).then(res => res.json())
    .then(resp => {
        if (resp.hasOwnProperty('request_number')) {
            dispatch(setRequestNumber(resp.request_number))
        }
    })
 }

 function setRequestNumber(request_number) {
    return {
        type: SET_REQUEST_NUMBER,
        request_number
    }
}

// Location
export function setLatLng(lat, lng) {
    return {
        type: SET_LAT_LNG,
        lat,
        lng
    }
}

export function setAddress(address) {
    return {
        type: SET_ADDRESS,
        address
    }
}


// misc cars type

export function setFuelType(fuel) {
    return {
        type: SET_FUEL_TYPE,
        fuel_type: fuel
    }
}

export function setTransmission(transmission) {
    return {
        type: SET_TRANSMISSION_TYPE,
        transmission
    }
}

export function setColour(colour) {
    return {
        type: SET_COLOUR,
        colour
    }
}

export function setYear(year) {
    return {
        type: SET_YEAR,
        year
    }
}

export function setMileage(mileage) {
    return {
        type: SET_MILEAGE,
        mileage
    }
}

export function setCarId(car_id) {
    return {
        type: SET_CAR_ID,
        car_id
    }
}

export function setEmailAddress(email_address) {
    return {
        type: SET_EMAIL_ADDRESS,
        email_address
    }
}

export function setCellphone(cellphone) {
    return {
        type: SET_CELLPHONE,
        cellphone
    }
}
export function setName(name) {
    return {
        type: SET_NAME,
        name
    }
}

// Save request
export function finalizeRequest(data ,dispatch)
{
   postData('finalizeRequest', data).then(res => res.json())
   .then(resp => {
       dispatch(loader(false))
       dispatch(closeRequest())
       notifyUser(data['request_number'])
   }).catch(err => {
       dispatch(loader(false))
       dispatch(closeRequest())
       notifyUser(data['request_number'])
   })
}

function closeRequest()
{
    return {
        type: CLOSE_REQUEST
    }
}

export function logout(dispatch) {
    localStorage.removeItem('user')
    dispatch(clearUser)
    window.location.href = "/"
}

function clearUser() {
    return {
        type: CLEAR_AUTH_USER
    }
}

function notifyUser(request_number)
{
    const message = `
    Thank you. Your request number, ${request_number} is sent to approved suppliers nearby. You will be emailed their responses. Please update optional information on your vehicle & your profile so we can assist you effectively 
    `
    window.showMessage(message)
}

export function setMake(make) {
    return {
        type: SET_MAKE,
        make
    }
}

export function setModel(model) {
    return {
        type: SET_MODEL,
        model
    }
}