import React from 'react';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { getQuotePrice } from '../../lib/func';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Booking from '../partials/Booking';
import Thumbnail from '../partials/Thumbnail';
import {postDataAdmin} from '../../lib/api';

class RequestQuotes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMessageDialog: false,
            quoteNumber: null,
            message: '',
            showBookingForm: false,
            quote: null
        }
        this.showMessageDialog = this.showMessageDialog.bind(this);
        this.showBookingForm = this.showBookingForm.bind(this);
        this.closeBookingForm = this.closeBookingForm.bind(this);
    }


    closeBookingForm() {
        this.setState({showBookingForm: false, quote: null})
    }
    showBookingForm(quote) {
        this.setState({showBookingForm: true, quote})
    }

    showMessageDialog(quoteNumber) {
        this.setState({ showMessageDialog: true, quoteNumber })
    }

    sendMessage(e)
    {
        const {message, quoteNumber} = this.state;
        if (message.length < 5) {
            alert('Sorry the message entered is too short.');
            return;
        }

        this.setState({ showMessageDialog: false, quoteNumber: null, message: '' })
        postDataAdmin('sendMessage', {message, quoteNumber});
        alert('Thank you message. Sent successfully');
    }

    cancelMessage()
    {
        this.setState({ showMessageDialog: false, quoteNumber: null, message: '' })
    }

    setMessage(e) {
        this.setState({message: e.target.value})
    }

    render() {
        const quotes = this.props.requests[this.props.requestIndex].quotes;
        const messageSupplier = <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>Send supplier a message</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p><textarea className="form-input" placeholder="Please type message here" onChange={this.setMessage.bind(this)}></textarea></p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={this.sendMessage.bind(this)}>Send</Button>
                <Button variant="primary" onClick={this.cancelMessage.bind(this)}>Cancel</Button>
            </Modal.Footer>
        </Modal.Dialog>

        return (

            <div>{(quotes && this.props.forceSingle === false) && <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            {quotes.map(q => {
                                return <Nav.Item>
                                    <Nav.Link eventKey={q.quote_number}>{q.quote_number} - {getQuotePrice(q)}</Nav.Link>
                              <hr />
                                </Nav.Item>

                            })}


                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>

                        {this.state.showMessageDialog && messageSupplier}
                        {this.state.showBookingForm && 
                        <Booking
                            closeForm={() => this.closeBookingForm()}
                            quote={this.state.quote}
                        />
                        }

                            {quotes.map(q => {
                                return <Tab.Pane eventKey={q.quote_number}>
                                    <strong>Supplier Partner Name: </strong> {q.company} <br />
                                    {q.photo && q.photo.length > 3 && 
                                    <div><Thumbnail photo={q.photo} maxWidth="150px" /></div>}
                                    <strong>Supplier Partner Address:</strong> <p>
                                        {q.address}
                                    </p>
                                    <strong>RMI Registered: </strong> {q.rmi_registerd == "1" ? 'Yes' : 'No'}<br />
                                    <strong>Supplier Introduction:</strong> {q.bio} <br />
                                    <hr />
                                    <h5>Itemized Pricing</h5>
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Qty</th>
                                                <th>Price Incl VAT</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            {q.pricing.map(p => {
                                                let fullAmount = parseFloat(p.price) + parseFloat(p.admin_fee);
                                                return <tr>
                                                    <td>{p.description}</td>
                                                    <td>{p.qty}</td>
                                                    <td>R{parseFloat(fullAmount).toFixed(2)}</td>
                                                </tr>
                                            })}

                                            <tr>
                                                <td colspan="2">
                                                    <strong>Total Price Incl VAT</strong>
                                                </td>
                                                <td>
                                                   {getQuotePrice(q)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <strong>Estimated Timeframe:</strong> {q.estimated_timeframe} <br />
                                    <hr />
                                    <strong>Notes:</strong>
                                    <p>
                                        {q.additional_notes}
                                    </p>

                                    <hr />
                    
                              <button className="btn-primary" onClick={() => {this.showBookingForm(q)}}>Accept Quote</button>
        
                             
                             &nbsp;&nbsp;
                                <button className="btn-primary" onClick={() => {this.showMessageDialog(q.quote_number)}}>Send Message</button>
                                </Tab.Pane>
                            })}

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>}

            {(quotes && this.props.forceSingle === true) && <div>
                
                <h5>Itemized Pricing</h5>
                <p>
                    {quotes[0].description}
                </p>
                <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Qty</th>
                                                <th>Price Incl VAT</th>
                                                </tr>
                                        </thead>
                                        <tbody>
                                            {quotes[0].pricing.map(p => {
                                                let fullAmount = parseFloat(p.price) + parseFloat(p.admin_fee);
                                                return <tr>
                                                    <td>{p.description}</td>
                                                    <td>{p.qty}</td>
                                                    <td>R{parseFloat(fullAmount).toFixed(2)}</td>
                                                </tr>
                                            })}

                                            <tr>
                                                <td colspan="2">
                                                    <strong>Total Price Incl VAT</strong>
                                                </td>
                                                <td>
                                                   {getQuotePrice(quotes[0])}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                <hr />
                <strong>Notes:</strong>
                <p>
                    {quotes[0].additional_notes}
                </p>

                <hr />
                {this.state.showMessageDialog && messageSupplier}

                <button className="btn-primary" onClick={() => {this.showMessageDialog(quotes[0].quote_number)}}>Send Supplier A Message</button>

            </div>}

            
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        requests: state.userDashboard.requests
    }
}

const mapDispatchTopProps = (dispatch) => {

}


export default connect(mapStateToProps, mapDispatchTopProps)(RequestQuotes);