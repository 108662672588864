import React from 'react';
import {Icon} from 'react-icons-kit';
import {clipboard} from 'react-icons-kit/typicons/clipboard'
import {magnifying_glass} from 'react-icons-kit/ikons/magnifying_glass';
import {mail_incoming} from 'react-icons-kit/ikons/mail_incoming';
import {automobile} from 'react-icons-kit/fa/automobile';
import "../css/app.css";

export default class HowItWorks extends React.Component{
    render(){
        return (
          
            <section className="site-section">
            <div style={{position: "relative"}}>
              <a id="howourdealworks"
                name="howourdealworks"
                style={{position:"absolute", top: "-130px"}}
                ></a>
            </div>
                      <div className="row">
            <div className="col-md-12">
              <div style={{textAlign: "center"}}>
              
              <h2 className="text-black h1 site-section-heading text-center">How Our Deal Works</h2>
              <h3>4 easy steps</h3>

              <br /><br /></div>
            </div>
            </div>
            <div className="container">
              <div className="row">
         
                <div className="col-md-3">
                  <div className="box-with-humber">
                    <div className="number-behind">01.</div>
                    <div className="react-icons" style={{ color: '#cd0606', marginLeft: "-80px"}}><Icon icon={clipboard} size={45} /></div>

                    <h3 className="med-title">Request a quote</h3>
                    <p className="mb-4">Use the easy form above to provide as much detail as possible about the service you need for your vehicle.</p>

                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box-with-humber">
                    <div className="number-behind">02.</div>
                    <div className="react-icons" style={{ color: '#cd0606',marginLeft: "-90px" }}><Icon icon={magnifying_glass} size={45} /></div>

                    <h3 className="med-title"> We find you help</h3>

                    <p className="mb-4">Our Deal matches the best Supplier Partners to your needs and sends you their quotes.</p>
       
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box-with-humber">
                    <div className="number-behind">03.</div>
                    <div className="react-icons" style={{ color: '#cd0606', marginLeft: "-30px" }}>
                      <Icon icon={mail_incoming} size={45} />
                      </div>
                    <h3 className="med-title">You choose the supplier</h3>
                 
                    <p className="mb-4">Select the quote you prefer. Book the appointment and pay via Our Deal.</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="box-with-humber">
                    <div className="number-behind">04.</div>
                    <div className="react-icons" style={{ color: '#cd0606', marginLeft: "-100px" }}>
                    <Icon icon={automobile} size={45}  />

                    </div>
                    <h3 className="med-title">Take your car in</h3>
                
                    <p className="mb-4"> The Supplier Partner completes the job. Once you happy, we pay them.</p>
                  </div>
                </div>

              </div>
            </div>
            <div className="row">
                <div className="col-md-12 px-5" style={{textAlign:"center"}}>
                <div style={{marginTop:"50px"}}>

                <iframe width="70%" height="500" src="https://www.youtube.com/embed/WFld5HAwDP8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>                </div>

                </div>
            </div>

          </section>
        );
    }
}