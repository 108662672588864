import React from 'react';
import logo from "../assets/logo.png";
import { connect } from 'react-redux';
import {ADMIN_URL} from '../lib/api';

class Header extends React.Component{
    render() {
      const {isLoggedIn} = this.props;

        return (
            <header className="site-navbar bg-white js-sticky-header site-navbar-target" role="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-11 col-xl-1">
              <h1 className="mb-0 site-logo">
                <a href="/"><img src={logo} style={{maxHeight: "55px"}} /></a>
              </h1><br />
            </div>
            <div className="col-12 col-md-11 d-none d-xl-block">
              <h3>Our Deal is looking for high quality supplier partners like you.</h3>
            </div>
            <div className="d-inline-block d-xl-none ml-md-0 mr-auto py-3" style={{position: 'relative', top: '3px'}}><a href="#" className="site-menu-toggle js-menu-toggle text-black"><span className="icon-menu h3" /></a></div>
          </div>
        </div>
      </header>
      );
    }
}


const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.requestQuote.isLoggedIn,
  }
}
export default connect(mapStateToProps)(Header);