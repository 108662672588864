import React from 'react';
import { getUserRequests, saveRequestPhoto } from '../../../lib/actions/userDashboard';
import {ASSET_URL} from '../../../lib/api';
import { connect } from 'react-redux';
import $ from 'jquery';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CarsDashboard from './CarsDashboard';
import RequestQuotes from '../../partials/RequestQuotes';
import Messages from '../../partials/Messages';
import ImageUploader from "react-images-upload";
import LandingDashboard from './LandingDashboard';


class RequestsDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            requestIndex: 0,
            updatingRequest: false,
            messageCount: 0
        }

        this.saveChanges = this.saveChanges.bind(this);
        this.getField = this.getField.bind(this);
        this.setMessageCount = this.setMessageCount.bind(this);
    }
    
    componentDidMount() {
        this.props.getUserRequests().then(res => {
            if (this.props.requestNumber) {
                for(let i in this.props.requests) {
                    if (this.props.requests[i].request_number == this.props.requestNumber) {
                        this.setState({
                            requestIndex: parseInt(i)
                        })
                    }
                }
            }
        })
    }

    nextRequest() {
        const { requestIndex } = this.state;
        let nextIndex = this.state.requestIndex + 1;
        if (this.props.requests[nextIndex] === undefined) {
            alert('Sorry! - you have no more requests.')
            return;
        }

        this.setState({
            requestIndex: nextIndex
        })

    }

    prevRequest() {
        let prevIndex = this.state.requestIndex - 1;
        if (this.props.requests[prevIndex] === undefined) {
            return;
        }

        this.setState({ requestIndex: prevIndex })
    }

    onDrop(pictureFiles, pictureDataURLs) {
        const { requestIndex } = this.state;
        this.props.saveRequestPhoto({
            'photo': pictureFiles[0],
            'request_id': this.props.requests[requestIndex].id
        }, requestIndex)
    }

    saveChanges() {

    }

    getField(field) {
        return this.props.requests[this.state.requestIndex][field];
    }

    setMessageCount(messageCount) {
        this.setState({messageCount})
    }

    render() {
        const { requestIndex } = this.state;
        const request = this.props.requests[requestIndex];
        const cardHeadingStyle = { color: "red", fontWeight: "900" }
        const numQuotes = request && request.quotes.length || 0;

        const navigation = <div>
            <div style={{ textAlign: "right", paddingRight: "20px", color: "#32dbc6" }}><i><b>Request {parseFloat(requestIndex) + 1} of {this.props.requests.length} requests</b></i></div>
            <div style={{ textAlign: "right", padding: "20px" }}>
                <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={this.prevRequest.bind(this)}>Previous Request</button>

                <button className="btn btn-primary" onClick={this.nextRequest.bind(this)}>Next Request</button>
            </div></div>

        return (
            <div>
                {request &&
                    <div>
                        {navigation}
                        <hr />
                        <div style={{ color: "red", fontWeight: 500, padding: "10px" }}>
                        Please review & update your vehicle details below, as this helps Suppliers to quote you more accurately.
                         <br /><br />
                         <div style={{color: "#000", padding: "10px"}}>
                         <strong>Request No: </strong> {request.request_number} <br />
                     
                                        </div>
                         </div>
                        <hr />
                        <Accordion defaultActiveKey="0">
                        <Card>
                            {request.service.toLowerCase() != 'buy a vehicle' && request.service != 'Vehicle finance'  && <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        <span style={cardHeadingStyle}>
                                            Vehicle details
                            </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <CarsDashboard navigation={false} carId={request.car_id} key={request.car_id} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>}

                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        <span style={cardHeadingStyle}>
                                            Request Details
                                        </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                    <strong>Category: </strong> {request.service}<br />
                                    <strong>Description: </strong> {request.description}<br />
                                        {(request.service.toLowerCase() == 'buy a vehicle' && request.car != undefined) &&
                                            <div>
                                                <strong>Year: </strong> {request.car.year} <br />
                                                <strong>Make: </strong> {request.car.car_make} <br />
                                                <strong>Model: </strong> {request.car.car_model} <br />
                                                <strong>Transmission: </strong> {request.car.transmission} <br />
                                                <strong>Fuel Type: </strong> {request.car.fuel_type} <br />
                                                <strong>Prefered Colours: </strong> {request.car.colour} <br />
                                            </div>
                                        }
                                        {request.service_details && request.service_details.map(a => {
                                            return <div key={a.name}>
                                                <strong>{a.name.replace('_', ' ')} :</strong> <p>
                                                    {a.info}
                                                </p>
                                            </div>
                                        })}

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            {request.additional_items_or_information && 
                                     <Card>
                                     <Card.Header>
                                         <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                             <span style={cardHeadingStyle}>
                                               Additional Items
                                 </span>
                                         </Accordion.Toggle>
                                     </Card.Header>
                                     <Accordion.Collapse eventKey="3">
                                         <Card.Body>
                                             <p>{request.additional_items_or_information}</p>
                                         </Card.Body>
                                     </Accordion.Collapse>
                                 </Card>}


                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                        <span style={cardHeadingStyle}>
                                        Quotes from Supplier Partners {numQuotes > 0 && `(${numQuotes})`} 
                                            {numQuotes == 0 && <span style={{fontSize: "10px", color: "black"}}>Sorry no quotes yet. Check back soon!</span>}
                            </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <RequestQuotes  forceSingle={false} requestIndex={this.state.requestIndex} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                        <span style={cardHeadingStyle}>
                                            Photos ({(request && request.photos && request.photos.length) || 0})
                            </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
           

                                    <div className="row">
                                    <div className="col-lg-12">
                                    {
                                        request && request.photos && request.photos.map((p) => {
                                            return <img src={ASSET_URL + p.path} 
                                            className="rounded float-left"
                                            style={{maxWidth: "200px"}}
                                            key={p.id}
                                             />
                                        })
                      
                                    }

                                    </div>
                                    </div>
                                    <ImageUploader
                                withIcon={true}
                                buttonText="Add Photos"
                                onChange={this.onDrop.bind(this)}
                                withPreview={false}
                                singleImage={true}
                                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                                maxFileSize={5242880}
                            />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>


                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                        <span style={cardHeadingStyle}>
                                            Messages ({this.state.messageCount})
                            </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <Messages updateCounter={this.setMessageCount} request_id={request.id} key={request.id} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion>
                    </div>
                }

                {!request && <div className="alert alert-danger">Sorry you don't have any quote requests yet. In the meantime consider updating your profile &nbsp;
                   <a href="/dashboard/profile">here</a> OR make a new request <a href="/">here</a></div> }

                {!request && <LandingDashboard />}
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        requests: state.userDashboard.requests
    }
}

const mapDispatchTopProps = (dispatch) => {
    return {
        getUserRequests: () => getUserRequests(dispatch),
        saveRequestPhoto: (data) => saveRequestPhoto(data, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchTopProps)(RequestsDashboard);