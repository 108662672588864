import { createStore,compose, applyMiddleware } from "redux"
import { combineReducers } from 'redux'
import {requestQuote} from "../lib/reducers/requestQuote";
import {userDashboard} from '../lib/reducers/userDashboard';
import {supplierDashboard} from '../lib/reducers/supplierDashboard';

import thunk from 'redux-thunk';
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

 const store = createStore(
    combineReducers({requestQuote, userDashboard, supplierDashboard}),
    composeEnhancer( applyMiddleware( thunk ))
);

 export default store;