import React from 'react';
import {postDataSupplier} from '../../lib/api';
import Alert from 'react-bootstrap/Alert';

export default class SupplierMessages extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            messages: []
        }

        this.fetchMessages = this.fetchMessages.bind(this)
    }

    fetchMessages() {
        postDataSupplier('getMessages', {request_id: this.props.request_id}).then(res => {
            if (res.hasOwnProperty('messages')) {
                this.setState({messages: res.messages})
                this.props.updateCounter(res.messages.length)
            }
        })
    }

    componentDidMount() {
        this.fetchMessages();
    }

    render() {
        return (
            <div>
                {this.state.messages.map(m => {
                    return <div key={m.id}>
                          <Alert key={m.id} variant="primary">
                            <strong>{m.message}</strong> <br />
                            From: {m.fromUser} <br /> 
                            Quote Number: {m.quote_number} <br />
                            <i>{m.created_at}</i>
                          </Alert>
                        </div>
                })}
            </div>
        )
    }
}