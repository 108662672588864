import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { connect } from 'react-redux';
import AppCaptcha from './partials/AppCaptcha';
import {getUserQuoteNumbers} from '../lib/actions/userDashboard';

import "../css/search.css";
import { postData } from '../lib/api';

class EasyChat extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      quoteNumber: '',
      message: '',
      captcha: false
    }
  }

  componentDidMount()
  {
    if (this.props.user) {
      this.props.getUserQuoteNumbers();
    }

  }

  finish() {
    const {quoteNumber, message} = this.state;

    if (quoteNumber.length < 3) {
      window.showMessage("Sorry! the quote number entered does not seem correct. Please check the number and try again.")
      return;
    }

    if (message.length < 5) {
      window.showMessage('Sorry! the message entered is too short. Please try again.')
      return;
    }

    this.setState({loading: true})
    postData('sendEasyChat', {quoteNumber, message})
    .then(res => {
      this.setState({loading: false});
      if (res.hasOwnProperty('error')) {
        window.showMessage(res.error);
      } else {
        window.showMessage('Thank you! - message successfully sent.');
      }
    }).catch(err => {
      this.setState({loading: false});
      window.showMessage('Sorry an unknown error occurred. Please try again.');
    })
    
  }

  render() {
    const {loading, captcha} = this.state;
    const {quoteNumbers} = this.props;
    const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
 
    return (
      <div className="site-section" style={{backgroundImage: 'url(/atrader/images/hero_1.jpg)', backgroundSize: 'cover', minHeight: '500px'}}>
      <div className="container">
        <div className="row mt-5"> 
          <div className="col-md-5">
            <div className="SearchForm">
              <div style={{padding:"5px"}}><h5>Easy Chat</h5></div>
              <p>Simply enter your quote number below and a message to easily send a message to a supplier that's working on your car.</p>
              <label>Quote Number:</label>
              
              {(!quoteNumbers || quoteNumbers.length == 0) && <div><input type="text" className="form-input" name="quote_number"
               onChange={(e) => {this.setState({quoteNumber: e.target.value})}}
               value={this.state.quoteNumber}
               /><br /></div>
               }

               {(quoteNumbers && quoteNumbers.length > 0) && <div>
                <select className="form-input"
                 onChange={(e) => {this.setState({quoteNumber: e.target.value})}}
                 value={this.state.quoteNumber}
                 >
                   {quoteNumbers.map(q => {
                     if (q.accepted == 0) {
                       return '';
                     }
                     return <option key={q.quote_number} value={q.quote_number}>{q.quote_number} - {q.supplier}</option>
                   })}
                 </select>
               </div>}

              <label>Message:</label>
               <textarea className="form-input"
               value={this.state.message}
               onChange={(e) => {this.setState({message: e.target.value})}}

               ></textarea>

                <AppCaptcha verifyCallback={() => {
                  this.setState({captcha: true})
                }} />
                <div style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" onClick={this.finish.bind(this)} disabled={loading || captcha === false}>SEND</button>
                </div>
           
              <div style={{textAlign:"center"}}>
                <ScaleLoader
                  size={5}
                  override={override}
                  color={"#fff"}
                  loading={loading}
                />
              </div>
            </div>

          </div>
          <div className="col-md-7">
              <div style={{color: '#fff',Width: "150px", marginTop: "50px"}}>
                <h1>Quick &amp; easy quote finder for  <br />anything car related.</h1>
              </div>
            </div>
        </div>
      </div>
    </div> 
  )
  }
}

const mapStateToProps = (state) => {
  return {
      quoteNumbers: state.userDashboard.quoteNumbers,
      user: state.requestQuote.user
  }
}

const mapDispatchTopProps = (dispatch) => {
  return {
    getUserQuoteNumbers: () => getUserQuoteNumbers(dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchTopProps)(EasyChat);
