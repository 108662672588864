import React from 'react';
import {connect} from 'react-redux';
import { UserAuthActionCreator, loader } from '../lib/actions/requestQuote';
import AppCaptcha from './partials/AppCaptcha';

import ScaleLoader from "react-spinners/ScaleLoader";
import "../css/search.css";
import $ from 'jquery';
import { postData } from '../lib/api';

class SignupForm extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
      isLogin: true,
      isRegister: false,
      captcha: false
    }
  }

  finish()
  {
    const email = $('#email').val();
    const password = $('#password').val();
    const password2 = $('#password2').val();
    const cellphone = $("#cellphone").val();
  
    const name = $('#name').val();

    if (!($("#ticked").is(":checked"))) {
      window.showMessage('Sorry - please tick the checkbox to accept our terms and conditions.');
      return;
    }

    if (name.length < 3) {
      window.showMessage('Sorry - please enter your name (3 or more characters).');
      return;
    }

    if (email.length < 5) {
      window.showMessage('Sorry - the email entered is invalid.');
      return;
    }

    if (password.length < 5) {
      window.showMessage('Sorry - the password entered is too short. Please enter at least 5 characters.');
      return;
    }

    if (password != password2) {
      window.showMessage('Sorry - Passwords do not match, please double check the passwords entered.');
      return;
    }

    if (!cellphone || cellphone.length < 10) {
      window.showMessage('Please check the cellphone number entered. Cellphone not present or invalid.')
      return;
    }

    this.props.showLoader()
    postData('singup', {
      password,
      name,
      email,
      cellphone
    }).then(res => res.json())
    .then(res => {
      this.props.hideLoader();
      if (res.hasOwnProperty('error')) {
        window.showMessage(res.error);
      } else {
        window.showMessage('Welcome to Our Deal. Your Free Membership No is ' + res.membership_number + '. Once logged in click  "My Account" to complete your profile and vehicle details or click "Get Free Quotes" to start using our services.', '/login')
      }
    }).catch(err => {
      this.props.hideLoader();
      window.showMessage('Sorry an unkown error occured. Please try again.');
    })

  }

  componentDidMount()
  {
    this.setState({isLogin: this.props.isLogin, isRegister: this.props.isRegister})
  }

  render() {
    const { loading, isLoggedIn} = this.props;
    const {captcha} = this.state;
    const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
 
    if (isLoggedIn) {
      window.location.href = '/dashboard';
    }

    return (
      <div className="site-section" style={{backgroundImage: 'url(/atrader/images/hero_1.jpg)', backgroundSize: 'cover', minHeight: '500px'}}>
      <div className="container">
        <div className="row mt-5"> 
          <div className="col-md-5">
            <div className="SearchForm">
              <div style={{padding:"5px"}}><h5>Our Deal Member Registration </h5></div>
                <div>
                <label>Your Name</label>
                <input id="name" className="form-input" type="text" name="name" placeholder="Enter Your Name" />
                <br />
                <label>Your Cellphone</label>
                <input id="cellphone" className="form-input" type="text" name="cellphone" placeholder="Enter Your Cellphone Number" />
                <br />
                <label>Your Email</label>
                <input id="email" className="form-input" type="email" name="email" placeholder="Enter Email" />
                <br />
                <label>Password</label>
                <input id="password" className="form-input"  type="password" name="password" placeholder="Enter Password" />
                <label>Password Again</label>
                <input id="password2" className="form-input"  type="password" name="password2" placeholder="Enter Password Again" />
          <br />
          <AppCaptcha verifyCallback={() => {
                  this.setState({captcha: true})
                }} />
                </div>
                <div className="form-group" style={{paddingLeft: "8px"}}>
             <input type="checkbox" id="ticked" />
             <small>&nbsp;&nbsp;I agree to Our Deal's Terms of Use and Privacy Policy.</small>
           </div>


                <div style={{ textAlign: "right" }}>
                  <br />
                    <button className="btn btn-primary" onClick={this.finish.bind(this)} disabled={loading}>Sign Up</button>
                </div>
           
              <div style={{textAlign:"center"}}>
                <ScaleLoader
                  size={5}
                  override={override}
                  color={"#fff"}
                  loading={loading}
                />
              </div>
            </div>

          </div>
          <div className="col-md-7">
              <div style={{color: '#fff',Width: "150px", marginTop: "50px"}}>
                <h1>Quick &amp; easy quote finder for  <br />anything car related.</h1>
              </div>
            </div>
        </div>
      </div>
    </div> 
  )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.requestQuote.loading,
    isLoggedIn: state.requestQuote.isLoggedIn
  }
}

const mapDispatchTopProps = (dispatch) => {
    return {
      showLoader: () => dispatch(loader(true)),
      hideLoader: () => dispatch(loader(false)),
    }
}

export default connect(mapStateToProps, mapDispatchTopProps)(SignupForm);