import React from 'react';
import $ from 'jquery';
import {connect} from 'react-redux';
import { loader, setMetaInfo, moveRequestForm } from '../../lib/actions/requestQuote';
import FileUploader from './../partials/FileUploader';

class MetaFormEvaluations extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
        otherWindscreen: false
    }
    this.saveForm = this.saveForm.bind(this)
  }



   saveForm(e) {
       e.preventDefault();
    let flatData = {};
    let elements = $('#form').serializeArray()
    for(let i in elements) {
        flatData[elements[i].name] = elements[i].value;
    }
    this.props.setMetaInfo(flatData)
    this.props.moveRequestForm()
    return false;
   }

   upload(files) {
       console.log(files)
   }
  
  render() {
      return (
        <div>
          <span style={{fontWeight:"500"}}> Step 3/4:</span>
          <legend style={{color:"#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding:"5px"}}>Vehicle Evaluation Details</legend>
          <br />
        <form method="POST" id="form" onSubmit={this.saveForm}>
        <div className="form-group">
                <label>Select reason for evaluation?</label>
               <select name="reason_for_evaluation" id="reason_for_evaluation"
                 className="metafield form-control" onClick={this.selectProblem}>
                <option value="Not sure" selected>Click to select</option>
                        <option value="Selling my vehicle">Selling my vehicle</option>
                        <option value="I'm buying the vehicle">I'm buying the vehicle</option>
                        <option value="Unhappy with trade in price">Unhappy with trade in price</option>
                        <option value="Need a written valuation">Need a written valuation</option>
                        <option value="Other">Other</option>
                </select>

            </div>


            <div className="form-group">
            <label>
             Please provide any additional information or anything else you need assistance with?
            </label>
            <textarea id="additional_items_or_information" name="additional_items_or_information" className="form-input"></textarea>
            </div>
            <div style={{ textAlign: "right" }}>
                  <br />
                    <button className="btn btn-primary">Next</button>
            </div>
            </form>
            </div>
      );
  }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
}

  
  export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormEvaluations);