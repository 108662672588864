import React from 'react';
import HowItWorks from "./howItWorks";
import OdEdge from './odedge';
import Search from "./search";
import LoginForm from './LoginForm';

import {getUrlAction} from '../lib/func';
import EasyChat from './EasyChat';
import SignupForm from './SignupForm';
import SupplierSignupForm from './SupplierSignupForm';
import ForgotForm from './ForgotForm';
import Contact from './contact';
import MetaFormTyres from './forms/MetaFormTyres';
import BurgerMenu from './pages/BurgerMenu';
import Conditions from './pages/Conditions';
import Faqs from './pages/Faqs';

export default class HomePage extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            action: getUrlAction()
        }
    }
    componentDidMount() {
        this.setState({action: getUrlAction()})
    }
    componentWillReceiveProps(oldProps, newProps)
    {
        this.setState({action: getUrlAction()})
    }

    render(){
        const {action} = this.state;
        let renderedView = null

        switch(action) {
            case 'login':
                renderedView = <LoginForm isLogin={1} />
                break;
            case 'meta':
                renderedView = <MetaFormTyres />
                break;
            case 'reset-password':
                renderedView = <ForgotForm />
                break;
            case 'register':
                renderedView = <SignupForm />
                break;
            case 'partner-register':
                renderedView = <SupplierSignupForm />
                break;
            case 'easy-chat':
                renderedView = <EasyChat />
                break;
            case 'burger':
                renderedView = <BurgerMenu />
                break;
            case 'faqs':
                renderedView = <Faqs />
                break;
            case 'conditions':
                renderedView = <Conditions />
                break;
            default:
                renderedView = <SupplierSignupForm />
        }
        return (
                <div>
                    {renderedView}
                    <a id="howitworks"></a>
                    <br /><br /><br />

                    <HowItWorks />
                    <OdEdge />
                </div>
        );
    }
}