import React from 'react';
import cars from '../../../assets/icons/cars.png';
import quoteRequests from '../../../assets/icons/quote.png';
import profile from '../../../assets/icons/profile.png';
import help from '../../../assets/icons/help.png';

export default class LandingDashboard extends React.Component {

    render() {
        const override = `
        display: block;
        margin: 0 auto;
        border-color: red;
      `;

        return (

            <div className="bg-white" style={{ padding: "10px" }}>
                <div className="row">
                <div className="col">
                    <div className="card" style={{width: "15rem;"}}>
                        <div class="card-body">
                            <h5 className="card-title">My Cars</h5>
                            <p className="card-text">
                                <img src={cars} style={{width: "120px"}} />
                            </p>
                            <a href="/dashboard/cars" className="card-link">View cars</a>
                            <a href="/dashboard/add-cars" className="card-link">Add car</a>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card" style={{width: "15rem;"}}>
                        <div class="card-body">
                            <h5 className="card-title">Quotes</h5>
                            <p className="card-text">
                                <img src={quoteRequests} style={{width: "120px"}} />
                            </p>
                            <a href="/dashboard/requests" className="card-link">Open requests</a>
                            <a href="/dashboard/quotes" className="card-link">Accepted quotes</a>
                            <a href="/" className="card-link">Request quote</a>
                        </div>
                    </div>
                
                </div>
    
                </div>
                <div className="row">
                <div className="col">
                    <div className="card" style={{width: "15rem;"}}>
                        <div class="card-body">
                            <h5 className="card-title">My Profile</h5>
                            <p className="card-text">
                                <img src={profile} style={{width: "120px"}} />
                            </p>
                            <a href="/dashboard/profile" className="card-link">View / Update</a>
                            <a href="/dashboard/logout" className="card-link">Logout</a>
                        </div>
                    </div>
            
                </div>
                <div className="col">
                    <div className="card" style={{width: "15rem;"}}>
                        <div class="card-body">
                            <h5 className="card-title">Help</h5>
                            <p className="card-text">
                                <img src={help} style={{width: "120px"}} />
                            </p>
                            <a href="/contact" className="card-link">Contact Our Deal</a>
                            <a href="/easy-chat" className="card-link">Easy chat with supplier</a>
                        </div>
                    </div>
            
                </div>
                </div>

                </div>

        );
    }
}