import React from 'react';
import {getAcceptedSupplierRequests} from '../../../lib/actions/supplierDashboard';
import { connect } from 'react-redux';
import SelectSearch from 'react-select-search';
import { FUEL_TYPES, getUrlParts } from '../../../lib/func';
import $ from 'jquery';
import Thumbnail from '../../partials/Thumbnail';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import SupplierRequestQuotes from '../../partials/SupplierRequestQuotes';
import SupplierMessages from '../../partials/SupplierMessages';
import SupplierCarDetails from '../../partials/SupplierCarDetails';

class SupplierJobsDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            requestIndex: 0,
            updatingRequest: false,
            messageCount: 0,
        }

        this.saveChanges = this.saveChanges.bind(this);
        this.getField = this.getField.bind(this);
        this.setMessageCount = this.setMessageCount.bind(this);
    }

    componentDidMount() {
        this.props.getAcceptedSupplierRequests().then(resp => {
            if (this.props.quoteNumber) {
                for(let i in resp) {
                    for (let q in resp[i].quotes) {
                        if (resp[i].quotes[q].quote_number == this.props.quoteNumber) {
                            this.setState({requestIndex: q})
                            return;
                        }
                    }
                }
            }
        })
    }

    nextRequest() {
        const { requestIndex } = this.state;
        let nextIndex = this.state.requestIndex + 1;
        if (this.props.requests[nextIndex] === undefined) {
            alert('Sorry! - you have no more requests.')
            return;
        }

        this.setState({
            requestIndex: nextIndex
        })

    }

    prevRequest() {
        let prevIndex = this.state.requestIndex - 1;
        if (this.props.requests[prevIndex] === undefined) {
            return;
        }

        this.setState({ requestIndex: prevIndex })
    }

    onDrop(pictureFiles, pictureDataURLs) {
        const { requestIndex } = this.state;
        this.props.saveRequestPhoto({
            'photo': pictureFiles[0],
            'request_id': this.props.requests[requestIndex].id
        }, requestIndex)
    }

    saveChanges() {

    }

    getField(field) {
        return this.props.requests[this.state.requestIndex][field];
    }

    setMessageCount(messageCount) {
        this.setState({messageCount})
    }

    render() {
        const { requestIndex } = this.state;
        const request = this.props.requests[requestIndex];
        const cardHeadingStyle = { color: "red", fontWeight: "900" }
        const numQuotes = request && request.quotes.length || 0;

        const navigation = <div>
            <div style={{ textAlign: "right", paddingRight: "20px", color: "#32dbc6" }}><i><b>Request {parseFloat(requestIndex) + 1} of {this.props.requests.length} accepted quotes</b></i></div>
            <div style={{ textAlign: "right", padding: "20px" }}>
                <button className="btn btn-primary" style={{ marginRight: "10px" }} onClick={this.prevRequest.bind(this)}>Previous Accepted Quote</button>

                <button className="btn btn-primary" onClick={this.nextRequest.bind(this)}>Next Accepted Quote</button>
            </div></div>

        return (
            <div>
                {(request && request.quotes.length > 0 ) && 
                    <div>
                        {navigation}
                        <Accordion defaultActiveKey="0">
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                        <span style={cardHeadingStyle}>
                                            Quote Details - {request.quotes[0].quote_number}
                                        </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <strong>Category: </strong> {request.service}<br />
                                        {(request.service.toLowerCase() == 'buy a vehicle' && request.car != undefined) &&
                                            <div>
                                                <strong>Year: </strong> {request.car.year} <br />
                                                <strong>Make: </strong> {request.car.car_make} <br />
                                                <strong>Model: </strong> {request.car.car_model} <br />
                                                <strong>Transmission: </strong> {request.car.transmission} <br />
                                                <strong>Fuel Type: </strong> {request.car.fuel_type} <br />
                                                <strong>Prefered Colours: </strong> {request.car.colour} <br />
                                            </div>
                                        }
                                        {request.additional && request.additional.map(a => {
                                            return <div key={a.name}>
                                                <strong>{a.name.replace('_', ' ')} :</strong> <p>
                                                    {a.info}
                                                </p>
                                            </div>
                                        })}                   <strong> </strong><p> {request.description}</p> <br />
                
                <strong>Invoice Number:</strong> <p>{request.quotes[0].payment_reference}</p>
                <strong>Payment Status: </strong><p>{request.quotes[0].payment_reference && request.quotes[0].paid ==1 ? "Paid" : "Pending"}</p>
<hr />
                                        <SupplierRequestQuotes forceSingle={true} requestIndex={this.state.requestIndex} />

                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                        <span style={cardHeadingStyle}>
                                            Vehicle details
                            </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        {request.car && 
                                        <SupplierCarDetails car={request.car} /> }
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                        <span style={cardHeadingStyle}>
                                            Messages ({this.state.messageCount})
                            </span>
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <SupplierMessages updateCounter={this.setMessageCount} request_id={request.id} key={request.id} />
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                        </Accordion>
                    </div>
                }
                {!request && <div className="alert alert-danger">Sorry you have no accepted quotes.
                 You will be notified as soon as a request comes in. In the meantime consider updating your profile &nbsp;
                   <a href="/supplier/dashboard/profile">here</a></div>}
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        requests: state.supplierDashboard.requests
    }
}

const mapDispatchTopProps = (dispatch) => {
    return {
        getAcceptedSupplierRequests: () => getAcceptedSupplierRequests(dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchTopProps)(SupplierJobsDashboard);