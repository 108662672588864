import React from 'react';
import {getUserCars, saveCarPhoto, updateCar} from '../../../lib/actions/userDashboard';
import { connect } from 'react-redux';
import SelectSearch from 'react-select-search';
import {FUEL_TYPES} from '../../../lib/func';
import ImageUploader from "react-images-upload";
import $ from 'jquery';
import Thumbnail from '../../partials/Thumbnail';
import LandingDashboard from './LandingDashboard';

class CarsDashboard extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = {
            carIndex: 0,
            photo: null,
            updatingCar: false,
            transmission: null,
            fuel_type: null
        }
        this.saveChanges = this.saveChanges.bind(this);
    }

    componentDidMount()
    {

        const carId = this.props.carId
        this.props.getUserCars().then(resp => {
            console.log(resp)
            if (carId) {
                for(let i in this.props.cars) {
                    console.log(this.props.cars[i])
                    if ((parseFloat(this.props.cars[i].id)) == parseFloat(carId)) {
                        console.log(i)
                        this.setState({carIndex: i})
                        break;
                    }
                }
            }
        })

    }

    nextCar()
    {
        const {cars} = this.props;
        let nextIndex = this.state.carIndex + 1;
        if (cars[nextIndex] === undefined) {
            alert('Sorry! - you have no more cars.')
            return;
        }

        this.setState({
            carIndex: nextIndex
        })

    }

    prevCars()
    {
        let prevIndex = this.state.carIndex - 1;
        const {cars} = this.props;
        if (cars[prevIndex] === undefined) {
            return;
        }

        this.setState({carIndex: prevIndex})
    }

    onDrop(pictureFiles, pictureDataURLs) {
        const {carIndex} = this.state;
        this.props.saveCarPhoto({
            'photo': pictureFiles[0],
            'car_id': this.props.cars[carIndex].id
        }, carIndex)
      }
    
    

    saveChanges(val = null, type = null)
    {
        const carIndex = this.state.carIndex;
        const car = this.props.cars[carIndex];
        val !== null && this.setState({updatingCar: true})
        const colour = val && type == 'colour' ? val: $("#colour").val();
        const mileage =  val && type == 'mileage' ? val : parseFloat($("#mileage").val());
        const registration = val && type == 'registration' ? val : $('#registration').val();
        const transmission = val && type == 'transmission' ? val : car.transmission
        const fuel_type = val && type == 'fuel_type' ? val : car.fuel_type
        

        if (val == null && isNaN(mileage)) {
            alert('Please set your current mileage.');
            return;
        }

  
        this.props.updateCar(car.id, carIndex, {
            colour: colour,
            mileage: mileage,
            registration: registration,
            transmission: transmission,
            fuel_type: fuel_type,
        }, val === null ? true : false) // e = null, means this was not triggered by an onchange

        val === null && this.setState({updatingCar: false, carIndex})
        
    }

    render() {
        const {carIndex, updatingCar} = this.state;
        this.props.cars[carIndex] && console.log(this.props.cars[carIndex].photo)

        const navigation = <div>
        <div style={{textAlign:"right", paddingRight:"20px", color: "#32dbc6"}}><i><b>Car {parseFloat(carIndex)+1} of {this.props.cars.length} cars</b></i></div>
         <div style={{textAlign:"right", padding: "20px"}}>
         <a className="btn btn-primary" style={{marginRight:"10px"}} onClick={this.prevCars.bind(this)}>Previous Car</a>

            <a className="btn btn-primary" onClick={this.nextCar.bind(this)}>Next Car</a> <br />
        </div></div>

        return (
            <div>
                {this.props.cars && <div className="row">
                    <div className="col-md-12">
                    {this.props.cars[carIndex] != undefined && <div className="card">
                        <div className="card-body">
                   
                        <div className="row">
                            <div className="col-md-7">
                 
                                <h5 style={{fontSize: "16px", color: "red", fontWeight: "900"}}>
                                    {this.props.cars[carIndex].colour + " "}
                                    {this.props.cars[carIndex].year + " "}
                                    {this.props.cars[carIndex].make + " "} 
                                    {this.props.cars[carIndex].model + " "}  
                                    {this.props.cars[carIndex].variant} ({this.props.cars[carIndex].fuel_type} {this.props.cars[carIndex].transmission})</h5>
                                  <br />
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item"><strong>Colour:</strong>
                                        <input type="text" id="colour" name="colour" value={this.props.cars[carIndex].colour} onChange = {(e) => this.saveChanges(e.target.value, 'colour')} className="form-input" />
                                        </li>
                                        <li className="list-group-item"><strong>Mileage:</strong>
                                        <input type="text" id="mileage" name="mileage" value={this.props.cars[carIndex].mileage}  onChange = {(e) => this.saveChanges(e.target.value, 'mileage')} className="form-input" />
                                        </li>
                                        <li className="list-group-item"><strong>Registration:</strong>
                                        <input type="text" id="registration" name="registration" value={this.props.cars[carIndex].registration} onChange = {(e) => this.saveChanges(e.target.value, 'registration')} className="form-input" />
                                        </li>
                                        <li className="list-group-item"><strong>Transmission: </strong> 
                                        {this.props.cars[carIndex].transmission} <br />
                                        <SelectSearch
                                                options={
                                                    [
                                                        {name: 'Manual', value: 'Manual', key: 'Manual'},
                                                        {name: 'Automatic', value: 'Automatic', key: 'Automatic'},

                                                    ]} defaultValue=""
                                                name="transmission"
                                                id="transmission"
                                                placeholder="Click to change transmission"
                                                search={true}
                                                autoComplete="on"
                                                onChange={(val) => this.saveChanges(val, 'transmission')}
                                            />
                                        </li>
                                        <li className="list-group-item"><strong>Fuel Type: </strong> 
                                        {this.props.cars[carIndex].fuel_type} <br />
                                            <SelectSearch
                                                options={FUEL_TYPES} defaultValue=""
                                                name="fuel_type"
                                                id="fuel_type"
                                                placeholder="Click to change fuel type"
                                                search={true}
                                                autoComplete="on"
                                                onChange={(val) => this.saveChanges(val, 'fuel_type')}
                                            />

                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-5">
                                { updatingCar && <div style={{textAlign:"right", padding: "20px"}}>
                                <button className="btn btn-primary" style={{marginRight:"10px"}} onClick={() => this.saveChanges()}>Save Changes</button>

                            </div>}

                               
                               { this.props.hasNavigation && updatingCar == false && navigation}
                         
                            
                            
                            <div style={{textAlign: "center"}}>
                       { this.props.cars[carIndex].photo && 
                        <Thumbnail
                            photo = {this.props.cars[carIndex].photo}
                            maxWidth = "150px"
                            key={this.props.cars[carIndex].photo + Date.now()}
                        />
                       }
                       <ImageUploader
                                withIcon={true}
                                buttonText="Set / Replace car photo"
                                onChange={this.onDrop.bind(this)}
                                withPreview={false}
                                singleImage={true}
                                imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                                maxFileSize={5242880}
                            />
                         </div> 
                            </div>
                                </div>
                        </div>
    
 
                        { updatingCar && <div style={{textAlign:"right", padding: "20px"}}>
                                <button className="btn btn-primary" style={{marginRight:"10px"}} onClick={() => this.saveChanges()}>Save Changes</button>

                            </div>}

                            { this.props.hasNavigation && updatingCar == false && navigation}
                            </div>
                
                    }

                    {!this.props.cars && this.props.cars[carIndex].length < 1 && <span>Sorry you do not have any cars yet.</span>}
                </div>
            </div>}

{(!this.props.cars || this.props.cars.length === 0) && <div className="alert alert-danger">Sorry you don't have any cars yet. You can add cars  <a href="/dashboard/add-cars">here</a></div>}
{(!this.props.cars || this.props.cars.length === 0) && <LandingDashboard />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cars: state.userDashboard.cars
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        getUserCars: () => getUserCars(dispatch),
        updateCar: (id, carIndex, updates, sendToApi) => updateCar(id, carIndex, updates, sendToApi, dispatch),
        saveCarPhoto: (data, index) => saveCarPhoto(data, index, dispatch)
    }
}

  
  export default connect(mapStateToProps, mapDispatchTopProps)(CarsDashboard);