import React from 'react';
import {postData} from '../lib/api';
import ScaleLoader from "react-spinners/ScaleLoader";
import "../css/search.css";
import $ from 'jquery';
import AppCaptcha from './partials/AppCaptcha';

export default class ForgotForm extends React.Component
{
  constructor(props) {
    super(props)
    this.state = {
        loading: false,
        step: 1,
        email: '',
        captcha: false
    }
  }

  next() {
      const {email} = this.state;

    if (email.length > 3) {
        this.setState({ loading: true})
        postData('sendUserOtp', {email})
        .then(res => res.json())
        .then(res => {
            alert('Thank you, we have emailed you a one time pin - please check your email.');
            this.setState({loading: false, step: 2})
        }).catch(rej => {
            alert('Sorry! something went wrong, please try again.');
            this.setState({loading: false, step: 1})
        })
    } else {
      alert('Sorry! - please enter your email to continue.')
    }

  }

  reset() {
    const password = $("#password").val()
    const password2 = $("#password2").val();
    const otp = parseFloat($('#otp').val());
    const {email} = this.state;

    if (password.length < 5) {
        alert('Sorry! - password is too short. Please enter at least 5 characters');
        return;
    }

    if (password2 != password) {
        alert('Sorry! - passwords do not match. Please try again.');
        return;
    }

    if (isNaN(otp)) {
        alert('Sorry! - OTP code is invalid. Please double check the code entered.');
        return;
    }

    this.setState({loading: true})
    postData('changeUserPassword', {email, password, otp})
    .then(res => res.json())
    .then(res => {
        this.setState({loading: false})
        if (res.hasOwnProperty('success')) {
            alert('Successfully reset your password');
            window.location.href = '/login'
        } else {
            alert('Sorry! something went wrong, please try again.');
        }
  
    }).catch(rej => {
        alert('Sorry! something went wrong, please try again.');
        this.setState({loading: false, step: 1});
    })
  }

  setEmail(e) {
      this.setState({email: e.target.value})
  }

  render() {
    const {loading, step, captcha} = this.state;
    const override = `
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
 

    return (
      <div className="site-section" style={{backgroundImage: 'url(/atrader/images/hero_1.jpg)', backgroundSize: 'cover', minHeight: '500px'}}>
      <div className="container">
        <div className="row mt-5"> 
          <div className="col-md-5">
            <div className="SearchForm">
              <div style={{padding:"5px"}}><h5>Forgot Password</h5></div>
                {step == 1 && <div>
                <label>Email</label>
                <input id="email" value={this.state.email} onChange={this.setEmail.bind(this)} className="form-input" type="email" name="email" placeholder="Enter Email" />
                <br />
                <div>
                <AppCaptcha verifyCallback={() => {
                  this.setState({captcha: true})
                }} />
                </div>
                </div>}
{ step == 2 && <div>
                <label>Enter New Password</label>
                <input className="form-input" id="password" type="password" placeholder="Please enter a new password" /> <br />
                <label>Repeat New Password</label>
                <input className="form-input" id="password2" type="password" placeholder="Please enter a new password" /> <br />
                <label>Enter OTP</label>
                <input className="form-input" id="otp" type="password" placeholder="Please enter a new password" /> <br />

</div>}
               {step == 1 && <div style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" onClick={this.next.bind(this)} disabled={loading || captcha === false}>NEXT</button>
                </div>}

                {step == 2 && <div style={{ textAlign: "right" }}>
                    <button className="btn btn-primary" onClick={this.reset.bind(this)} disabled={loading}>RESET</button>
                </div>}
           
              <div style={{textAlign:"center"}}>
                <ScaleLoader
                  size={5}
                  override={override}
                  color={"#fff"}
                  loading={loading}
                />
              </div>
            </div>

          </div>
          <div className="col-md-7">
              <div style={{color: '#fff',Width: "150px", marginTop: "50px"}}>
                <h1>Quick &amp; easy quote finder for  <br />anything car related.</h1>
              </div>
            </div>
        </div>
      </div>
    </div> 
  )
  }
}