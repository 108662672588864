import  CryptoJs from 'crypto-js';

const hashKey = '&1233239232fdklfsdlfk320r23-kdfsdfjsfkld2302250980238958205wgkfmv,.emgo306405306930639fkgjf';

export function getSessionUser() {
    let user = localStorage.getItem('user')
    if (user) {
        user = JSON.parse(CryptoJs.AES.decrypt(user, hashKey).toString(CryptoJs.enc.Utf8))
    }
    return user; 
}

export function setSessionUser(user) {
    const hashed = CryptoJs.AES.encrypt(JSON.stringify(user), hashKey).toString()
    localStorage.setItem('user', hashed)
}

export function getUrlAction(flat = true)
{
    return flat == true ? window.location.href.split('/').pop() : window.location.href.split('/')
}

export const FUEL_TYPES = [
    {name: 'Petrol', value: 'Petrol', key: 'Petrol'},
    {name: 'Diesel', value: 'Diesel', key: 'Diesel'},
    {name: 'Hybrid', value: 'Hybrid', key: 'Hybrid'},
    {name: 'Electric', value: 'Electric', key: 'Electric'},
]

export const CAR_SERVICES = [
    'Vehicle Service',
    'Vehicle Repairs',
    'Tyres',
    'Batteries',
    'Exhausts',
    'Windscreens',
    'Shock absorbers',
    'Air conditioner repairs',
    'Sell your vehicle',
    'Buy a vehicle',
    'Vehicle finance',
    'Accessories',
    'Spare Parts',
    'Vehicle Transportation',
    'Accident Repairs',
    'Auto Trimming',
    'Signage & Branding',
    'License & fines',
    'Pre purchase check over',
    'Vehicle Evaluations',
    'Insurance',
    'Vehicle warranties',
    'Other Requirement'
]

export function getQuotePrice(quote, format = true)
{
   let fee = 0.00;
   for(let p in quote.pricing) {
        fee += (parseFloat(quote.pricing[p].price) + parseFloat(quote.pricing[p].admin_fee))
   }

   fee = fee.toFixed(2)
   if (format == false) {
       return fee
   }
   return `R${fee}`;
}

export function getUrlParts() {
    const params = window.location.pathname.replace('/dashboard/', '').split('/')
    return params
}