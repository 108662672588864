import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { loader, setMetaInfo, moveRequestForm } from '../../lib/actions/requestQuote';
import FileUploader from './../partials/FileUploader';

class MetaFormBuy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            otherWindscreen: false
        }
        this.saveForm = this.saveForm.bind(this)
    }

    saveForm(e) {
        e.preventDefault();
        let flatData = {};
        let elements = $('#form').serializeArray()
        for (let i in elements) {
            flatData[elements[i].name] = elements[i].value;
        }
        this.props.setMetaInfo(flatData)
        this.props.moveRequestForm()
        return false;
    }

    upload(files) {
        console.log(files)
    }

    render() {
        return (
            <div>
                <span style={{ fontWeight: "500" }}> Step 3/4:</span>
                <legend style={{ color: "#fff", fontWeight: 400, borderBottom: "1px solid #ccc", padding: "5px" }}>Buy A Vehicle Details</legend>
                <br />
                <form method="POST" id="form" onSubmit={this.saveForm}>
                    <div className="form-group">
                        <label>New Or Used? </label>
                        <select name="new_or_used" id="new_or_used" className="metafield form-control">
                            <option value="Used" selected>Click to select</option>
                            <option value="New">New</option>
                            <option value="Used">Used</option>
    
                        </select>
                        <br />
                    </div>
                    <div className="form-group">
                        <label>Type of Vehicle</label>
                        <select name="type_of_vehicle" id="type_of_vehicle" className="metafield form-control">
                            <option value="Hatchback" selected>Click to select</option>
                            <option value="Hatchback">Hatchback</option>
                            <option value="Sedan">Sedan</option>
                            <option value="SUV">SUV</option>
                            <option value="Bakkie">Bakkie</option>
                            <option value="MPV">MPV</option>
                            <option value="Sports Car">Sports Car</option>
                            <option value="Truck">Truck</option>
                            <option value="Mini bus">Mini bus</option>
                            <option value="Other">Other</option>
                        </select>
                        <br />
                    </div>
                    <div className="form-group">
                        <label>Approximate price range</label>
                        <input type="number" className="metafield form-control" id="minimum_price" name="minimum_price" 
                        placeholder="Minimum Price" /> <br />
                        <input type="number" className="metafield form-control" id="" name="maximum_price" 
                        placeholder="Maximum Price" />

                    </div>
                    <div className="form-group">
                        <label>Cash or finance</label>
                        <select name="cash_or_finance" id="cash_or_finance" className="metafield form-control">
                        <option value="Cash">Click to select</option>
                        <option value="Cash">Cash </option>
                        <option value="Finance">Finance</option>
                        </select>
                    </div>


                    <div className="form-group">
                        <label>
                            Any additional information about your request?
            </label>
                        <textarea id="additional_items_or_information" name="additional_items_or_information" className="form-input"></textarea>
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <br />
                        <button className="btn btn-primary">Next</button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        service: state.requestQuote.service,
        loading: state.requestQuote.loading,
        user: state.requestQuote.user,
        searchedForUser: state.requestQuote.searchedForUser,
        isLoggedIn: state.requestQuote.isLoggedIn
    }
}
const mapDispatchTopProps = (dispatch) => {
    return {
        setMetaInfo: (meta) => dispatch(setMetaInfo(meta)),
        moveRequestForm: () => dispatch(moveRequestForm(3)),
        showLoader: () => dispatch(loader(true)),
        hideLoader: () => dispatch(loader(false)),
    }
}


export default connect(mapStateToProps, mapDispatchTopProps)(MetaFormBuy);